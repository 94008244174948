import React from 'react';
import type { SaleBannerProps as SaleBannerUIProps } from '@wix/app-market-components';
import { SaleBanner as SaleBannerUI } from '@wix/app-market-components';
import { appMarketSaleBannerImpressionWebMobile } from '@wix/bi-logger-app-market-data/v2';
import { useEssentials, useRouterContext } from '../../contexts';
import { getPathForBi } from '../../bi-services/utils';

export interface SaleBannerProps {
  layout: SaleBannerUIProps['layout'];
}

export function SaleBanner({ layout }: SaleBannerProps) {
  const { i18n } = useEssentials();
  const { biLogger } = useEssentials();
  const { route } = useRouterContext();

  React.useEffect(() => {
    biLogger.report(
      appMarketSaleBannerImpressionWebMobile({
        tag_type: getPathForBi(route.path),
        sale_name: 'nov-24',
      }),
    );
  }, []);

  return <SaleBannerUI i18n={i18n} layout={layout} />;
}
