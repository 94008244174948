import React from 'react';
import { getPropsByRoute } from './seo-page.utils';
import type { IRoute } from '@wix/app-market-core';
import { RoutePath } from '@wix/app-market-core';
import { AppPageSEOPage } from './app-page-seo-page';
import { SEOPage } from './seo-page';
import type { TFunction } from 'react-i18next';

export interface SEOContainerProps {
  newRoute: IRoute;
  metaTagsAggregator: string[];
  getHrefByRouteAndLanguage: (r: IRoute, languageCode: string) => string;
  t: TFunction;
}

export function SEOContainer({
  newRoute,
  metaTagsAggregator,
  getHrefByRouteAndLanguage,
  t,
}: SEOContainerProps) {
  return newRoute.path === RoutePath.APP_PAGE ? (
    <AppPageSEOPage
      metaTagsAggregator={metaTagsAggregator}
      getHref={(languageCode: string) => {
        return getHrefByRouteAndLanguage(newRoute, languageCode);
      }}
    />
  ) : (
    <SEOPage
      metaTagsAggregator={metaTagsAggregator}
      {...getPropsByRoute(newRoute, t, getHrefByRouteAndLanguage)}
    />
  );
}
