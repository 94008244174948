import { config } from '../config';
import type { IRoute } from '..';
import { EditorType, Origin, OriginQueryParam } from '..';
import { biLogger } from '../bi';
import {
  appMarketAppInstallationSuccess,
  appMarketAppInstallationFailedClient,
} from '@wix/bi-logger-app-market-data/v2';
import type { IModalProps } from '../components/modals/modals';
import { ModalNames } from '../components/modals/modals';
import {
  getAppPages,
  Origin as OriginMI,
  shouldShowAddAgain,
} from '@wix/manage-installed-apps';
import type { IConfigContext } from '../config-context';
import { fetchNewInstalledApps } from '../api/api';
import { getPathForBi, getReferralInfo, getWidgetReferral } from './bi-events';
import type {
  BundleWebSolution,
  WebSolutionBase,
} from '@wix/ambassador-marketplace/types';
import { BundleAppOrigin, AppGroup } from '@wix/ambassador-marketplace/types';
import { IncompleteSetupReason } from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';
import type { WebSolutionExtended } from '@wix/app-market-collection-widget';
import type { MarketplaceStore } from '../component-decorators/with-marketplace-store';
import type { IBiData } from '../types/common/marketplace';

export const openOrAddApp = async (
  app: WebSolutionExtended,
  marketplaceStore: MarketplaceStore,
  biParams: IBiData,
  configContext: IConfigContext,
  experiments?,
  updateInstalledApps?: any,
  wixOfferingId?: any,
  wixOfferingToastText?: string,
  wixOfferingToastCtaText?: string,
) => {
  const appFromInstalled = getAppFromInstalled(marketplaceStore, app);
  const modalProps: IModalProps = getModalProps(app, marketplaceStore);
  const referredByAppId =
    marketplaceStore?.route?.referral?.includes('app-dependency');
  if (appFromInstalled) {
    await handleAppFromInstalled(appFromInstalled, configContext);
  } else if (configContext.isMobile) {
    marketplaceStore.openModal(
      ModalNames.INSTALLATION_NOT_SUPPORTED_MOBILE,
      modalProps,
    );
  } else if (appNotSupportedInAdi(app)) {
    marketplaceStore.openModal(ModalNames.SWITCH_TO_EDITOR, modalProps);
  } else if (siteNeedsUpgrade(app, marketplaceStore)) {
    marketplaceStore.openModal(ModalNames.UPGRADE_SITE, modalProps);
  } else if (siteNeedsDomain(app, marketplaceStore)) {
    marketplaceStore.openModal(ModalNames.CONNECT_DOMAIN, modalProps);
  } else if (wixOfferingId) {
    if (config.origin === Origin.STANDALONE) {
      const dest = `https://manage.wix.com/dashboard/{metaSiteId}/wix-offering/app/${app.id}`;
      const encodedDestination = encodeURIComponent(dest);
      window.open(
        `https://manage.wix.com/account/site-selector?actionUrl=${encodedDestination}`,
      );
    }
    const isPageComponent = 'businessManagerPage' in wixOfferingId;
    if (isPageComponent) {
      const pageComponentId =
        wixOfferingId.businessManagerPage?.componentId ?? '';
      const appStatus = wixOfferingId.businessManagerPage?.appState ?? '';
      if (config.origin === Origin.BIZMGR) {
        configContext.goToWixOffering(
          pageComponentId,
          appStatus,
          wixOfferingToastText,
          wixOfferingToastCtaText,
        );
      } else {
        const path = appStatus.length
          ? `wix-offering/compId/${pageComponentId}/appState/${appStatus}`
          : `wix-offering/compId/${pageComponentId}`;
        configContext.openBMInEditor(path);
      }
    }
  } else {
    const appInstallationData = {
      appDefId: app.id,
      openMethod: app.openConsentIn,
      referallId: '',
      version: marketplaceStore.route.version,
      ...(referredByAppId && {
        referredByAppId: marketplaceStore.route.referral.substring(15),
      }),
      onInstallationSuccess: async (params: any) => {
        updateInstalledApps && updateInstalledApps();
        await sendInstallationSuccessBi(
          app,
          params,
          biParams,
          marketplaceStore,
        );
      },
      onInstallationFail: async (params: any) => {
        await sendInstallationFailBi(app, params, biParams);
      },
      goToManageApps: config.goToManageApps,
    };
    config.installApp(appInstallationData);
  }
};

function siteNeedsUpgrade(app, marketplaceStore: MarketplaceStore) {
  return app?.pricing?.isRequiredWixPremium && !marketplaceStore.siteIsPremium;
}

function siteNeedsDomain(app, marketplaceStore: MarketplaceStore) {
  return app?.pricing?.isRequiredWixPremium && !marketplaceStore.siteHasDomain;
}

function getModalProps(app, marketplaceStore: MarketplaceStore) {
  return {
    appName: app.name,
    appId: app.id,
    appIcon: app.icon,
    msid: marketplaceStore.metaSiteId,
  };
}

export const finishInstallCTA = async (
  appDefId: string,
  appVersion: string,
  installedApps,
  configContext: IConfigContext,
  shouldInstall?: boolean,
) => {
  const isAppOnStage = configContext.isAppOnStage
    ? await configContext.isAppOnStage(appDefId)
    : false;

  const appFromInstalled =
    installedApps &&
    installedApps.find((installedApp) => installedApp.appId === appDefId);

  const isInstalledPageApp =
    isAppOnStage && getAppPages(appFromInstalled).length;
  const isInstalledAddOnlyOnceApp =
    isAppOnStage &&
    !shouldShowAddAgain(
      appFromInstalled,
      convertOriginToOriginMI(config.origin),
    );

  if (
    isInstalledPageApp ||
    (isInstalledAddOnlyOnceApp && configContext.closeMarket)
  ) {
    configContext.closeMarket();
    return;
  }
  if (shouldInstall && configContext.finishAppInstall) {
    configContext.finishAppInstall(appDefId, appVersion);
    configContext.closeMarket();
  }
};

export const convertOriginToOriginMI = (origin: Origin) => {
  if (origin === Origin.EDITOR) {
    return OriginMI.EDITOR;
  }

  return OriginMI.BIZMGR;
};

export const getInstalledApps = async (signedInstance, locale) => {
  let installedApps;
  installedApps = await fetchNewInstalledApps(signedInstance, locale);
  return installedApps || [];
};

export const mapOriginToQueryParam = (route: IRoute): string => {
  if (config.editorType === EditorType.ADI) {
    return OriginQueryParam.ADI_DASHBOARD;
  }
  const originMap = new Map([
    [Origin.BIZMGR, OriginQueryParam.BIZMGR],
    [Origin.EDITOR, OriginQueryParam.EDITOR],
    [Origin.STANDALONE, OriginQueryParam.STANDALONE],
  ]);
  const platform =
    route && route.platformName
      ? route.platformName
      : originMap.get(config.origin);
  return platform;
};

const cannotOpenEditorX = (app, experiments) => {
  if (experiments.enabled(`specs.marketplace.editorx-for-${app.id}`)) {
    return false;
  }
  return !isSolutionInTag(app, 'supported-by-editorx');
};

const cannotOpenStudio = (app, experiments) => {
  if (experiments.enabled(`specs.marketplace.studio-for-${app.id}`)) {
    return false;
  }
  return !isSolutionInTag(app, 'supported-by-wix-studio');
};

const isSolutionInTag = (app: WebSolutionBase, slug: string) => {
  if (app?.Tags?.length) {
    const res = app?.Tags.filter((tag) => tag.slug === slug);
    return res.length > 0;
  }
  return false;
};

function getAppFromInstalled(marketplaceStore: MarketplaceStore, app) {
  return marketplaceStore?.installedApps?.find(
    (installedApp) =>
      installedApp.appId === app.id &&
      installedApp.installationDetails.incompleteSetupReason !==
        IncompleteSetupReason.PENDING_REQUIRED_INSTALLATIONS,
  );
}

async function handleAppFromInstalled(
  appFromInstalled,
  configContext: IConfigContext,
) {
  if (
    !shouldShowAddAgain(
      appFromInstalled,
      convertOriginToOriginMI(config.origin),
    ) &&
    (await configContext.openApp)
  ) {
    await configContext.openApp(appFromInstalled);
  } else if (configContext.finishAppInstall) {
    configContext.finishAppInstall(
      appFromInstalled.appId,
      appFromInstalled.installationDetails.installedVersion,
    );
  }
}

export function appNotSupportedInEditorX(app, experiments) {
  return (
    config.editorType === EditorType.EDITORX &&
    cannotOpenEditorX(app, experiments)
  );
}

export function appNotSupportedInStudio(app, experiments) {
  return (
    config.editorType === EditorType.STUDIO &&
    cannotOpenStudio(app, experiments) &&
    (!experiments ||
      (experiments &&
        !experiments.enabled('specs.marketplace.studioShowAllApps')))
  );
}

export function appNotSupportedInAdi(app) {
  return (
    config.origin === Origin.BIZMGR &&
    config.editorType === EditorType.ADI &&
    !isSolutionInTag(app, 'adi-in-bm') &&
    !isSolutionInTag(app, 'adi-in-editor')
  );
}

async function sendInstallationSuccessBi(
  app,
  params: any,
  biParams,
  marketplaceStore: MarketplaceStore,
) {
  await biLogger.report(
    appMarketAppInstallationSuccess({
      app_id: app.id,
      esi: config.esi || '',
      instance_id: params?.instance_id,
      app_subcategory: biParams.subCategory,
      tag_name: biParams.tagName,
      tag_type: biParams.tagType,
      section: biParams.section,
      referral_info: getReferralInfo(
        marketplaceStore?.route?.referral,
        marketplaceStore?.prevRoute,
      ),
      search_term: biParams.searchTerm,
      widget_referral: getWidgetReferral(),
      msid: params.msid,
      referral_section_index: marketplaceStore?.route?.appIndex,
      referral_section_name: marketplaceStore?.route?.referralSectionName
        ? marketplaceStore?.route?.referralSectionName
        : marketplaceStore?.route?.referral,
      slug_id: marketplaceStore?.route?.referralSectionName,
      referral_tag_name: marketplaceStore?.prevRoute?.slug?.replace(/-/g, '_'),
      referral_tag_type: getPathForBi(marketplaceStore?.prevRoute?.path),
      referral_sub_tag_name:
        marketplaceStore?.prevRoute?.subCategories?.join(','),
      collimp_id: marketplaceStore?.route?.collimp_id,
      navigation_source: marketplaceStore?.route?.searchLocation,
      sum_one: 1,
    }),
  );
}

async function sendInstallationFailBi(app, params: any, biParams) {
  await biLogger.report(
    appMarketAppInstallationFailedClient({
      app_id: app.id,
      esi: config.esi || '',
      instance_id: params && params.instance_id,
      fail_reason: params && params.reason,
      app_subcategory: biParams.subCategory,
      tag_name: biParams.tagName,
      tag_type: biParams.tagType,
    }),
  );
}

export const shouldRenderAsLink = (): 'link' | undefined => {
  if (config.origin === Origin.STANDALONE) {
    return 'link';
  }
};

export const isBrowser = () => typeof window !== 'undefined';
export const isBundleAppAndInstalled = (
  appID: string,
  bundleApps: BundleWebSolution[],
): boolean => {
  const bundleApp =
    bundleApps && bundleApps.find((app) => app.webSolutionBase.id === appID);
  return (
    bundleApp &&
    bundleApp.group === AppGroup.BUNDLE &&
    bundleApp.upgradeSuggestion
  );
};

export const isBundleAppAndNotInstalled = (
  appID: string,
  bundleApps: BundleWebSolution[],
): boolean => {
  const bundleApp =
    bundleApps && bundleApps.find((app) => app.webSolutionBase.id === appID);
  return (
    bundleApp &&
    bundleApp.group === AppGroup.BUNDLE &&
    !bundleApp.upgradeSuggestion
  );
};

export const isBundleAppWithVoucher = (
  appID: string,
  bundleApps: BundleWebSolution[],
): boolean => {
  const bundleApp =
    bundleApps && bundleApps.find((app) => app.webSolutionBase.id === appID);
  return (
    bundleApp &&
    bundleApp.group === AppGroup.BUNDLE &&
    bundleApp.appOrigin === BundleAppOrigin.VOUCHER
  );
};

export const getBundleAppSavedPrice = (
  appID: string,
  bundleApps: BundleWebSolution[],
): number => {
  const bundleApp =
    bundleApps && bundleApps.find((app) => app.webSolutionBase.id === appID);
  return bundleApp && bundleApp.appSavedPrice;
};

export const isWixApp = (companyId) =>
  companyId === 'ad147a73-a40e-4e50-8344-2ad33592790d' ||
  companyId === 'a60ac714-5c5a-4c38-a285-573752f04876';

export const wixChatBotLink =
  'https://www.wix.com/support/conversations/category/contact';

export const isAppInstalled = (
  appId: string,
  marketplaceStore: MarketplaceStore,
) =>
  !!(
    marketplaceStore.installedApps &&
    marketplaceStore.installedApps?.findIndex(
      (installedApp) => installedApp.webSolutionBase.id === appId,
    ) > -1
  );

export const sortSolutionsByInstalled = (
  solutions: WebSolutionExtended[],
): WebSolutionExtended[] => {
  return solutions
    .filter((solution) => !solution?.isInstalled)
    .concat(solutions.filter((solution) => solution?.isInstalled));
};
