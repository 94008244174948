import { AppMarketPage } from '../enums';
import type { IRoute } from '../models/junk-yard';
import { Path } from '../models';
import type { AppBadge } from '@wix/app-market-services';
import { isSSR } from '@wix/app-market-services';

export const convertStringToBi = (value: string) => {
  return value.replace(/-/g, '_');
};

export const getPathForBi = (path?: string) => {
  return path === Path.HOME || ''
    ? AppMarketPage.HOMEPAGE
    : path === Path.WEB_SOLUTION
    ? AppMarketPage.APP_PAGE
    : path ?? '';
};

export const getReferralInfo = (
  referral: string | undefined,
  route: IRoute | undefined,
  referrerNameForBI: string | undefined,
): string => {
  let result = '';
  if (referral) {
    result = referral;
  } else if (route && route.path) {
    result = getPathForBi(route.path);
  } else if (referrerNameForBI) {
    result = referrerNameForBI;
  } else if (route) {
    result = AppMarketPage.HOMEPAGE;
  }
  return result;
};

export const getRouteBiData = (
  route: IRoute | undefined,
  prevRoute: IRoute | undefined,
  referrerNameForBI: string | undefined,
): { referralInfo: string; subCategory: string; searchTerm: string } => {
  const subCategory =
    (route?.subCategories && route.subCategories.join(',')) || '';
  const referralInfo = getReferralInfo(
    route?.referral,
    prevRoute,
    referrerNameForBI,
  );
  const searchTerm = route?.query ? route?.query : prevRoute?.query || '';

  return { referralInfo, subCategory, searchTerm };
};

export const limitNumberDigits = (
  number: number | undefined,
): number | undefined => {
  let result;
  if (number || number === 0) {
    result = Number(number.toFixed(1));
  }

  return result;
};

export const appBadgesToString = (appBadges: AppBadge[] = []): string => {
  const appBadgesString = appBadges
    .map(({ badge }) => badge?.toLowerCase())
    .join(', ');
  return appBadgesString;
};

export const saleDiscountAmount = (
  appBadges: AppBadge[] = [],
): number | undefined => {
  const saleBadge = appBadges.find(
    ({ badge }) => badge === 'DEV_SALE' || badge === 'WIX_SALE',
  );
  return saleBadge?.saleBadgePayload?.amount
    ? Number(saleBadge.saleBadgePayload.amount)
    : undefined;
};

export const parseNumberToBi = (number?: number): number => {
  return number ? Math.round(Number(number.toFixed(2)) * 100) : -1000;
};

export const getReferralInfoFromCurrentUrl = (): string => {
  let referralInfo = '';
  if (!isSSR()) {
    referralInfo =
      new URLSearchParams(location?.search)?.get('referralInfo') ?? '';
  }
  return referralInfo;
};

export const parseStringToBi = (path: string): string => {
  return path.toLocaleLowerCase().replace(/-/g, '_');
};
