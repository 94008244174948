import React from 'react';
import { Box } from '@wix/design-system';
import { PageHeader } from '@wix/app-market-components';
import {
  useEssentials,
  useRouterContext,
  useServices,
  useSiteContext,
} from '../../contexts';
import { QueryDecorator } from '../../decorators';
import { getCollectionPageHeaderData } from './collection-page-data';
import type { CollectionPageHeaderProps } from './collection-page-data';
import { AppMarketPage } from '../../enums';
import { appMarketBackButtonClick } from '@wix/bi-logger-app-market-data/v2';
import { RoutePath } from '../../models';
import { BackButton } from '../../components/back-button';
import { CollectionHeaderSkeleton } from './collection-header-skeleton';

function CollectionPageHeaderComponent({
  primaryTitle,
  secondaryTitle,
  description,
  slug,
}: CollectionPageHeaderProps) {
  const { biLogger } = useEssentials();
  const { router } = useRouterContext();
  const page = AppMarketPage.COLLECTION;

  function onBack() {
    biLogger.report(
      appMarketBackButtonClick({
        tag_type: page,
        tag_name: slug,
        location: 'collection_page',
      }),
    );
    router.navigateTo({
      path: RoutePath.BACK,
      payload: undefined,
    });
  }
  return (
    <>
      {router.hasHistory() && (
        <Box marginTop="18px">
          <BackButton onBack={onBack} size="small" weight="thin" skin="dark" />
        </Box>
      )}
      <Box
        dataHook="collection-page-header"
        marginTop={router.hasHistory() ? '6px' : '18px'}
      >
        <PageHeader
          primaryTitle={secondaryTitle}
          secondaryTitle={primaryTitle}
          description={description}
        />
      </Box>
    </>
  );
}

export function CollectionHeader({ slug }: { slug: string }) {
  const { languageCode } = useSiteContext();
  const services = useServices();

  return (
    <QueryDecorator
      queryFn={() =>
        getCollectionPageHeaderData({
          services,
          slug,
          languageCode,
        })
      }
      queryKey={`collection-header-${languageCode}-${slug}`}
    >
      {({ data: collectionPageData, isLoading }) =>
        isLoading ? (
          <CollectionHeaderSkeleton />
        ) : (
          <CollectionPageHeaderComponent
            primaryTitle={collectionPageData.primaryTitle}
            secondaryTitle={collectionPageData.secondaryTitle}
            description={collectionPageData.description}
            slug={slug}
          />
        )
      }
    </QueryDecorator>
  );
}
