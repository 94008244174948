import type { TaxDetails, CurrencySettings } from './types';
import { EDisplayTaxType } from './types';

export const mockTaxSettings: TaxDetails = {
  showPriceWithTax: true,
  displayTaxType: EDisplayTaxType.VAT,
};

export const mockCurrencySettings: CurrencySettings = {
  code: 'USD',
  symbol: '$',
};
