import { sdk, STATIC_MEDIA_URL } from '@wix/image-kit';

export interface ResizeImageParams {
  imageUrl: string;
  originalWidth: number;
  originalHeight: number;
  toWidth: number;
  toHeight: number;
}

export const useResizeImage = (): (({
  imageUrl,
  originalWidth,
  originalHeight,
  toWidth,
  toHeight,
}: ResizeImageParams) => string) => {
  return ({ imageUrl, originalWidth, originalHeight, toWidth, toHeight }) => {
    const relativeUrl = imageUrl.replace(STATIC_MEDIA_URL, '');
    return sdk.getScaleToFillImageURL(
      relativeUrl,
      originalWidth,
      originalHeight,
      toWidth,
      toHeight,
      { isSEOBot: true }, // workaround to fix mismatch between server and client
    );
  };
};
