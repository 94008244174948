import * as React from 'react';
import {
  Box,
  CustomModalLayout,
  ModalMobileLayout,
  Button,
} from '@wix/design-system';
import { ReviewAppHeader } from './review-app-header';
import { ReviewAppForm } from './review-app-form';
import { useReviewForm } from '../../hooks/useReviewForm';
import type { IReviewData } from '../../api/review';
import { biLogger } from '../../bi';
import { ReviewAppHeaderMobile } from './review-app-header-mobile';
import {
  appMarketPostAppReview,
  appMarketCancelAppReviewClick,
} from '@wix/bi-logger-app-market-data/v2';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';

interface IReviewAppModalLayoutProps extends Partial<WithTranslationProps> {
  appDefId: string;
  appName: string;
  appIconSrc: string;
  previousReview?: IReviewData;
  closeModal(): void;
  afterClose?(): void;
  showSuccessToast?(message: string): void;
  additionalBiData: {
    msid: string;
    tagType: string;
    platform: string;
  };
  isMobile: boolean;
}

function ReviewAppModalLayout({
  appDefId,
  appName,
  appIconSrc,
  previousReview,
  closeModal,
  afterClose,
  showSuccessToast,
  additionalBiData,
  t,
  isMobile,
}: IReviewAppModalLayoutProps) {
  const submitReviewButtonText = t('review-app.submit-review');
  const cancelButtonText = t('review-app.cancel-review');

  function onSuccessfulSubmission(reviewData: IReviewData) {
    closeModal();
    showSuccessToast?.(t('review-app.successful-submission-toast'));
    afterClose?.();
    biLogger
      .report(
        appMarketPostAppReview({
          appId: appDefId,
          msid: additionalBiData.msid,
          appScore: reviewData.rating.toString(),
          review_title: reviewData.title,
          has_description: Boolean(reviewData.description),
          review_body: reviewData.description,
          actionType: previousReview?.reviewId ? 'edit_review' : 'add_review',
          post_type: 'new_review',
          tag_type: additionalBiData.tagType,
          market: additionalBiData.platform,
        }),
      )
      .catch((error) => console.log(error));
  }

  const reviewForm = useReviewForm(
    appDefId,
    previousReview,
    onSuccessfulSubmission,
  );

  function closeModalAndSendBi() {
    closeModal();
    biLogger
      .report(
        appMarketCancelAppReviewClick({
          appId: appDefId,
          msid: additionalBiData.msid,
          tag_type: additionalBiData.tagType,
          market: additionalBiData.platform,
        }),
      )
      .catch((error) => console.log(error));
  }

  return isMobile ? (
    <ModalMobileLayout
      fullscreen
      title={
        <ReviewAppHeaderMobile
          appName={appName}
          appIconSrc={appIconSrc}
          t={t}
        />
      }
      content={
        <ReviewAppForm appName={appName} reviewForm={reviewForm} t={t} />
      }
      footer={
        <Box align="right">
          <Box marginRight="12px">
            <Button priority="secondary" onClick={closeModalAndSendBi}>
              {cancelButtonText}
            </Button>
          </Box>
          <Button onClick={reviewForm.submit}>{submitReviewButtonText}</Button>
        </Box>
      }
      onCloseButtonClick={closeModalAndSendBi}
    />
  ) : (
    <CustomModalLayout
      width="600px"
      height="544px"
      title={
        <ReviewAppHeader appName={appName} appIconSrc={appIconSrc} t={t} />
      }
      primaryButtonText={submitReviewButtonText}
      primaryButtonOnClick={reviewForm.submit}
      secondaryButtonText={cancelButtonText}
      secondaryButtonOnClick={closeModalAndSendBi}
      onCloseButtonClick={closeModalAndSendBi}
    >
      <ReviewAppForm appName={appName} reviewForm={reviewForm} t={t} />
    </CustomModalLayout>
  );
}

export { IReviewAppModalLayoutProps, ReviewAppModalLayout };
