import type { Plan as PlanServerType } from '@wix/ambassador-devcenter-pricing-v1-pricing-entity/types';
import {
  PlanDescription as PlanDescriptionTypes,
  PlanStatus,
} from '@wix/ambassador-devcenter-pricing-v1-pricing-entity/types';
import type { PricingPlan, PlanDescription } from '../plans';
import { PlanDescriptionType } from '../plans';
import type { MeterdBilling } from '../apps-plans';

/**
 * Represents a facade for Plan type on the pricing-proxy service.
 *
 * @class PricingModelPlan
 */

export class PricingModelPlan {
  constructor(private readonly plan: PlanServerType) {}

  get vendorId(): string {
    return this.plan?.vendorId ?? '';
  }
  get name(): string {
    return this.plan?.name ?? '';
  }

  get customTitle(): string | undefined {
    return this.plan?.billing?.customTitle ?? undefined;
  }

  get isVisible(): boolean {
    return this.plan?.status === PlanStatus.VISIBLE;
  }

  get isFree(): boolean {
    return !!this.plan?.isFree;
  }

  get description(): PlanDescription {
    let descriptionType: PlanDescriptionType | null = null;
    if (this.plan.planDescription === PlanDescriptionTypes.BENEFITS) {
      descriptionType = PlanDescriptionType.BENEFITS;
    } else if (this.plan.planDescription === PlanDescriptionTypes.CUSTOM_TEXT) {
      descriptionType = PlanDescriptionType.CUSTOM_TEXT;
    }
    return {
      descriptionType,
      costumeText: this.plan.customText ?? undefined,
      benefits: this.plan.benefits ?? [],
    };
  }

  get monthlyPrice(): string | undefined {
    return String(this.plan?.billing?.monthlyPrice);
  }
  get yearlyPrice(): string | undefined {
    return String(this.plan?.billing?.yearlyPrice);
  }
  get oneTimePrice(): string | undefined {
    return String(this.plan?.billing?.oneTimePrice);
  }

  get meterdBilling(): MeterdBilling | undefined {
    const meterdBilling = this.plan?.billing?.meteredBilling;

    return meterdBilling
      ? {
          baseFee: String(meterdBilling?.monthlyBaseFee) ?? '',
          outline: meterdBilling?.checkoutAdditionalInfo ?? '',
        }
      : undefined;
  }

  toJSON(): PricingPlan {
    return {
      vendorId: this.vendorId,
      name: this.name,
      customTitle: this.customTitle,
      isVisible: this.isVisible,
      isFree: this.isFree,
      description: this.description,
      monthlyPrice: this.monthlyPrice,
      yearlyPrice: this.yearlyPrice,
      oneTimePrice: this.oneTimePrice,
      meterdBilling: this.meterdBilling,
    };
  }
}
