import type { FunctionComponent, ReactNode } from 'react';
import React, { createContext, useContext } from 'react';
import { useTranslationsLoader } from '../hooks/translations';
import type { i18n } from '@wix/wix-i18n-config';
import type { TFunction } from 'react-i18next';

export interface ITranslationContext {
  t: TFunction;
}

const TranslationContext = createContext<ITranslationContext | null>(null);

interface TranslationContextProviderProps {
  i18n: i18n;
  children: ReactNode;
}

export const TranslationContextProvider: FunctionComponent<
  TranslationContextProviderProps
> = ({ i18n, children }) => {
  const { t, ready } = useTranslationsLoader(i18n);

  if (!ready) {
    return null;
  }

  return (
    <TranslationContext.Provider value={{ t }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = (): { t: TFunction } => {
  const context = useContext(TranslationContext);

  if (context === null) {
    throw new Error(
      'useTranslation must be used within a TranslationContextProvider',
    );
  }

  return context;
};
