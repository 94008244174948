import React, { createContext, useContext } from 'react';
import type { RateAndReviewsSectionProps } from './rate-and-reviews-section';

export type RateAndReviewsSectionContext = Omit<
  RateAndReviewsSectionProps,
  'reviewsSummary' | 'userReview' | 'reviews' | 'i18n'
> & { setReviewModalOpen: (isReviewModalOpen: boolean) => void };

export const RateAndReviewSectionContext = createContext<
  RateAndReviewsSectionContext | undefined
>(undefined);

export const useRateAndReviewSectionContext = () => {
  const context = useContext(RateAndReviewSectionContext);
  if (!context) {
    throw new Error(
      'useRateAndReviewSectionContext must be used within a RateAndReviewSectionContextProvider',
    );
  }
  return context;
};

export const RateAndReviewSectionContextProvider: React.FC<
  React.PropsWithChildren<RateAndReviewsSectionContext>
> = ({ children, ...props }) => (
  <RateAndReviewSectionContext.Provider value={props}>
    {children}
  </RateAndReviewSectionContext.Provider>
);
