import * as React from 'react';
import { HeaderUserImage } from '../../standalone-header/header-user-details/header-user-image';
import { composer } from '../../../component-decorators/composer';
import { Box, Button, Text, TextButton } from '@wix/design-system';
import type { TFunction } from 'react-i18next';
import { useConfigContext } from '../../../config-context';

export const MobileMenuUserDetails = composer()
  .withMarketplaceStore()
  .withExperiments()
  .compose(({ marketplaceStore }) => {
    const base = marketplaceStore.route.currentUrl
      ? marketplaceStore.route.currentUrl
      : 'www.wix.com/app-market';
    const { locale } = useConfigContext();
    return marketplaceStore.userName ? (
      <Box align="space-between" verticalAlign="middle">
        <Box verticalAlign="middle">
          <HeaderUserImage marginLeft="0" />
          <Text dataHook="username-mobile-menu">
            {marketplaceStore.userName}
          </Text>
        </Box>
        <LogoutLink
          signoutLink={`https://www.wix.com/signout?redirectTo=${base
            .split('/app-market')[0]
            .concat('/app-market')}`}
        />
      </Box>
    ) : (
      <LoginButton
        signinLink={`https://users.wix.com/signin?loginDialogContext=login&referralInfo=HEADER&overrideLocale=${locale}&postLogin=${base
          .split('/app-market')[0]
          .concat('/app-market')}&postSignUp=${base
          .split('/app-market')[0]
          .concat('/app-market')}`}
      />
    );
  });

const LogoutLink = composer()
  .withTranslation()
  .compose(({ t, signoutLink }: { t?: TFunction; signoutLink: string }) => (
    <TextButton as="a" href={signoutLink} dataHook="logout">
      {t('standalone.header.logout')}
    </TextButton>
  ));

const LoginButton = composer()
  .withTranslation()
  .compose(({ t, signinLink }: { t?: TFunction; signinLink: string }) => (
    <Button
      priority="secondary"
      onClick={() => (window.location.href = signinLink)}
    >
      {t('standalone.header.login')}
    </Button>
  ));
