import type { Plan as PlanV2Type } from '@wix/ambassador-devcenter-plans-v1-plan/types';
import type { PricingPlans } from './types';
import { PlansByAppId } from './plansByAppId';

export class Plans {
  constructor(private readonly plansV2: PlanV2Type[]) {}

  findByAppId = (appId: string): PricingPlans => {
    return new PlansByAppId(
      this.plansV2.filter((plan) => plan.appId === appId),
    ).toJSON();
  };
}
