import React from 'react';
import { TextButton } from '@wix/design-system';
import { ArrowRightSmall } from '@wix/wix-ui-icons-common';
import { appsSectionDataHooks } from './data-hooks';
import { useEssentials } from '../../contexts/essentials-context';

export interface ViewAllAppsButtonProps {
  href?: string;
  onClick: () => void;
  appsCount: number;
}

export function ViewAllAppsButton({
  href,
  onClick,
  appsCount,
}: ViewAllAppsButtonProps) {
  const { t } = useEssentials();

  return (
    <TextButton
      href={href}
      as="a"
      size="small"
      weight="normal"
      dataHook={appsSectionDataHooks.button}
      onClick={(e) => {
        onClick();
        e.preventDefault();
      }}
      suffixIcon={<ArrowRightSmall />}
    >
      {t('apps.section.view.all.apps', {
        count: appsCount,
      })}
    </TextButton>
  );
}
