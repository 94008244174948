import * as React from 'react';
import { MarketplaceRouter } from '../marketplace-router';
import s from './marketplace-layout.scss';
import { composer } from '../../component-decorators/composer';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { config } from '../../config';
import { Origin } from '../..';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { Sidebar } from '@wix/app-market-core';

export interface IMarketplaceLayoutProps
  extends Partial<InjectedExperimentsProps> {
  marketplaceStore?: MarketplaceStore;
}

export const MarketplaceLayout = composer()
  .withMarketplaceStore()
  .compose(({ marketplaceStore }: IMarketplaceLayoutProps) => {
    const isInStandaloneMarket = config.origin === Origin.STANDALONE;
    const minHeight = isInStandaloneMarket ? 'calc(100vh - 60px)' : '100%';
    const shouldShowSidebar =
      !marketplaceStore.route.hideMenu &&
      marketplaceStore.route.path !== 'share-app';

    return (
      <div
        data-testid="marketplace-layout"
        className={s.marketplaceLayout}
        style={{ minHeight }}
      >
        {shouldShowSidebar && <Sidebar />}
        <MarketplaceRouter />
      </div>
    );
  });
