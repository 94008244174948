import type { AmbassadorBootstrap } from '@wix/ambassador-bootstrap-plugin';
import type { IHttpClient } from '@wix/http-client';
import { isSSR } from '../utils';

export abstract class RequestService<RpcModule, HttpModule> {
  private network: 'rpc' | 'http';

  constructor(
    private readonly rpcClient?: AmbassadorBootstrap,
    private readonly httpClient?: IHttpClient,
  ) {
    this.network = isSSR() ? 'rpc' : 'http';
  }

  protected abstract rpcModule(): Promise<RpcModule>;
  protected abstract httpModule(): Promise<HttpModule>;

  protected async request<TPayload, TResponse>({
    methodName,
    payload,
  }: {
    methodName: keyof (RpcModule & HttpModule);
    payload: TPayload;
  }): Promise<TResponse> {
    if (this.network === 'rpc') {
      if (!this.rpcClient) {
        throw new Error('Error: rpcClient is undefined');
      }
      const rpcModule = await this.rpcModule();
      const method = rpcModule[methodName as keyof RpcModule];
      if (typeof method !== 'function') {
        throw new Error(
          `Method ${String(methodName)} not found in RpcModule for ${
            this.constructor.name
          } service.`,
        );
      }
      return this.rpcClient.request(method(payload)) as Promise<TResponse>;
    } else {
      if (!this.httpClient) {
        throw new Error('Error: httpClient is undefined');
      }
      const httpModule = await this.httpModule();
      const method = httpModule[methodName as keyof HttpModule];
      if (typeof method !== 'function') {
        throw new Error(
          `Method ${String(methodName)} not found in HttpModule for ${
            this.constructor.name
          } service.`,
        );
      }
      const response = await this.httpClient.request(method(payload));
      return response.data as Promise<TResponse>;
    }
  }
}
