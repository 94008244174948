import React from 'react';
import style from './countdown.module.scss';
import { Box, Text } from '@wix/design-system';
import { useEssentials } from '../../contexts/essentials-context';

export interface CountdownProps {
  size: 'small' | 'large';
  days: number;
  hours: number;
  minutes: number;
}

export function Countdown({ size, days, hours, minutes }: CountdownProps) {
  const { t } = useEssentials();

  return (
    <Box className={style[`${size}Countdown`]} gap="12px">
      <TimeUnit
        label={
          days === 1
            ? t('sale.banner.countdown.day.label')
            : t('sale.banner.countdown.days.label')
        }
        value={days}
      />
      <Separator />
      <TimeUnit
        label={
          hours === 1
            ? t('sale.banner.countdown.hour.label')
            : t('sale.banner.countdown.hours.label')
        }
        value={hours}
      />
      <Separator />
      <TimeUnit
        label={
          minutes === 1
            ? t('sale.banner.countdown.minute.label')
            : t('sale.banner.countdown.minutes.label')
        }
        value={minutes}
      />
    </Box>
  );
}

function Separator() {
  return <span className={style.separator}>:</span>;
}
function TimeUnit({ value, label }: { value: number; label: string }) {
  return (
    <Box className={style.timeUnit} direction="vertical" align="center">
      <span className={style.timeUnitValue}>
        {value.toString().padStart(2, '0')}
      </span>
      <Text className={style.timeUnitLabel} light size="tiny" weight="normal">
        {label}
      </Text>
    </Box>
  );
}
