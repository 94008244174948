import React from 'react';
import { Box } from '@wix/design-system';
import { Placement } from '@wix/app-market-services';
import { useServices, useSiteContext } from '../../contexts';
import { QueryDecorator } from '../../decorators';
import { AppsSectionsSkeleton } from '../../components/apps-sections-skeleton';
import { HomePageSection } from './home-page-section';

export function HomePageSections() {
  const { metaSiteId } = useSiteContext();
  const services = useServices();

  return (
    <QueryDecorator
      queryFn={async () => {
        const { sections } = await services.getDynamicSections({
          placement: Placement.HOME_PAGE,
          isMetaSiteId: !!metaSiteId,
        });

        return sections;
      }}
      queryKey="home-page"
    >
      {({ data: sections, isLoading }) =>
        isLoading ? (
          <AppsSectionsSkeleton />
        ) : (
          <>
            {sections.map((section, index) => (
              <Box direction="vertical" marginBottom="30px">
                <HomePageSection section={section} index={index} />
              </Box>
            ))}
          </>
        )
      }
    </QueryDecorator>
  );
}
