import React from 'react';
import { Badge } from '.';
import { useEssentials } from '../../contexts/essentials-context';
import { Box, Text } from '@wix/design-system';

interface WixChoiceBadgeProps {
  showBorder?: boolean;
  size?: 'tiny' | 'small' | 'medium';
}

export function WixChoiceBadge({
  showBorder,
  size = 'tiny',
}: WixChoiceBadgeProps) {
  const { t } = useEssentials();

  return (
    <Badge
      skin="neutralStandard"
      dataHook="wix-choice-badge"
      uppercase={false}
      showBorder={showBorder}
      size={size}
      tooltipContent={
        <Box direction="vertical" gap="6px">
          <Text size="small" weight="bold" light>
            {t('wix.choice.badge')}
          </Text>
          <Text size="small" weight="thin" light>
            {t('wix.choice.badge.tooltip')}
          </Text>
        </Box>
      }
    >
      <Box fontSize={size === 'medium' ? 'small' : 'tiny'}>
        {t('wix.choice.badge')}
      </Box>
    </Badge>
  );
}
