import React, { useState } from 'react';
import { Box, Text, TextButton } from '@wix/design-system';
import { VisibilityDetector } from '../../exported-components/visibility-detector';
import s from './expanded-text.module.scss';
import { useEssentials } from '../../contexts/essentials-context';

interface ExpandedDescriptionProps {
  text: string;
  onExpandToggleClick?(): void;
}

export const ExpandedText = ({
  text,
  onExpandToggleClick,
}: ExpandedDescriptionProps) => {
  const { t } = useEssentials();

  const [isBodyExpanded, setIsBodyExpanded] = useState<Boolean>(false);
  const [showReadMore, setShowReadMore] = useState<Boolean>(false);
  const expandToggle = () => {
    setIsBodyExpanded(!isBodyExpanded);
    onExpandToggleClick?.();
  };

  return (
    <Box gap="12px" direction="vertical">
      <Box
        marginTop="24px"
        maxHeight={!isBodyExpanded ? '250px' : '100%'}
        marginBottom={!showReadMore ? '24px' : '0px'}
        overflow={!isBodyExpanded ? 'hidden' : 'none'}
      >
        <div
          className={
            (showReadMore && !isBodyExpanded && s.collapsedDesciption) || ''
          }
        >
          <Text
            weight="thin"
            dataHook="overview-description"
            className="text-description"
          >
            {text}
          </Text>
          {!isBodyExpanded && (
            <VisibilityDetector
              onHidden={() => setShowReadMore(true)}
              onVisible={() => {
                setShowReadMore(false);
              }}
            >
              <div />
            </VisibilityDetector>
          )}
        </div>
      </Box>
      {showReadMore && (
        <TextButton size="medium" onClick={expandToggle}>
          {isBodyExpanded
            ? t('app.page.overview.readLess')
            : t('app.page.overview.readMore')}
        </TextButton>
      )}
    </Box>
  );
};
