import type { ListAppPlansByAppIdResponse as AppsPlansType } from '@wix/ambassador-appmarket-v1-app-plans/types';
import { TaxType } from '@wix/ambassador-appmarket-v1-app-plans/types';
import { AppPlan } from './plan';
import type {
  CurrencySettings,
  TaxDetails,
  IAppPlans,
  IAppPlan,
} from './types';
import { EDisplayTaxType, inSale, wixSales } from './types';
import { getDecodedSign } from './decode-sign';
import { EAppBadges, type AppBadgeType } from '../apps-badges';

/**
 * Represents a facade for ListAppPlansByAppIdResponse type on the app-plans service.
 *
 * @class AppPlans
 */
export class AppPlans {
  constructor(private readonly appPlans: AppsPlansType | undefined) {}
  get taxSettings(): TaxDetails {
    const taxType = this.appPlans?.taxSettings?.taxType;
    const displayTaxType =
      taxType === TaxType.GST
        ? EDisplayTaxType.GST
        : taxType === TaxType.VAT
        ? EDisplayTaxType.VAT
        : EDisplayTaxType.NOT_APPLICABLE;
    return {
      showPriceWithTax: this.appPlans?.taxSettings?.showPriceWithTax ?? true,
      displayTaxType,
    };
  }

  get currency(): CurrencySettings {
    return {
      code: this.appPlans?.currency ?? 'USD',
      symbol: getDecodedSign(this.appPlans?.currencySymbol),
    };
  }

  get appId(): string {
    const appId = this.appPlans?.appPlans?.[0]?.id;
    if (!appId) {
      console.error(`appId is missing in path: appPlans.appPlans`);
    }
    return appId ?? '';
  }

  get plans(): IAppPlan[] {
    return (
      this.appPlans?.appPlans?.[0]?.plans?.map((plan) =>
        new AppPlan(plan, this.taxSettings.showPriceWithTax).toJSON(),
      ) || []
    );
  }

  get saleBadge(): AppBadgeType | undefined {
    const discount = this.plans.find((plan) => plan.discount)?.discount;

    if (discount && inSale.includes(discount.saleType)) {
      return {
        badge: wixSales.includes(discount.saleType)
          ? EAppBadges.WIX_SALE
          : EAppBadges.DEV_SALE,
        payload: {
          priority: 3,
          discountData: {
            type: discount.type,
            amount: discount.amount,
          },
        },
      };
    }
    return undefined;
  }

  get isExternalBilling(): boolean {
    return this.plans.some((plan) => plan.billingSource === 'EXTERNAL');
  }

  toJSON(): IAppPlans {
    return {
      taxSettings: this.taxSettings,
      currency: this.currency,
      appId: this.appId,
      plans: this.plans,
      saleBadge: this.saleBadge,
      isExternalBilling: this.isExternalBilling,
    };
  }
}
