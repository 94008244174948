import React from 'react';
import type { AppGroupSection, Section } from '@wix/app-market-services';
import { useAppsSectionSharedPropsMapper } from '../../hooks/apps-section-props-mapper';
import { AppsSection, CategoryCarousel } from '@wix/app-market-components';
import { useCategoryCarouselSharedPropsMapper } from '../../hooks/category-carousel-props-mapper';
import { useRouterContext } from '../../contexts';
import { RoutePath } from '../../models';
import { AppMarketPage } from '../../enums';

export function HomePageSection({
  section,
  index,
}: {
  section: Section;
  index: number;
}) {
  const page = AppMarketPage.HOMEPAGE;
  const { router } = useRouterContext();
  const appsSectionSharedPropsMapper = useAppsSectionSharedPropsMapper();
  const categoryCarouselSharedPropsMapper =
    useCategoryCarouselSharedPropsMapper();

  if (section.appGroup) {
    return (
      <AppsSection
        {...appsSectionSharedPropsMapper(
          section?.appGroup as AppGroupSection,
          page,
          page,
          index,
          undefined,
          undefined,
          {
            path: RoutePath.COLLECTION,
            payload: {
              slug: section?.appGroup?.tagSlug,
              referral: 'view_more_apps',
            },
          },
        )}
        onButtonClick={() => {
          router.navigateTo({
            path: RoutePath.COLLECTION,
            payload: {
              slug: section?.appGroup?.tagSlug ?? '',
            },
          });
        }}
      />
    );
  }
  if (section.categoryCarousel) {
    return (
      <CategoryCarousel
        {...categoryCarouselSharedPropsMapper(section.categoryCarousel, page)}
      />
    );
  }
  return null;
}
