import { RoutePath, type IRoute } from '@wix/app-market-core';
import type { TFunction } from 'react-i18next';

export const languages = [
  'cs',
  'da',
  'de',
  'en',
  'es',
  'fr',
  'hi',
  'it',
  'ja',
  'ko',
  'nl',
  'no',
  'pt',
  'ru',
  'sv',
  'th',
  'tr',
  'uk',
  'zh',
];

export function slugToCapitalName(slug: string) {
  let formattedSlug = slug.replace(/--/g, ' and ');
  formattedSlug = formattedSlug.replace(/-/g, ' ');

  return formattedSlug
    .split(' ')
    .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
    .join(' ');
}
export function getPropsByRoute(
  route: IRoute,
  t: TFunction,
  getHrefByRouteAndLanguage: (r: IRoute, languageCode: string) => string,
) {
  switch (route.path) {
    case RoutePath.CATEGORY:
      return {
        title: t('category.seo.title', {
          name: slugToCapitalName(route.payload.slug),
        }),
        description: t('category.seo.description', {
          name: slugToCapitalName(route.payload.slug),
        }),
        getHref: (languageCode: string) => {
          return getHrefByRouteAndLanguage(route, languageCode);
        },
      };
    case RoutePath.SUB_CATEGORY:
      return {
        title: t('sub.category.seo.title', {
          name: slugToCapitalName(route.payload.slug),
        }),
        description: t('sub.category.seo.description', {
          name: slugToCapitalName(route.payload.slug),
        }),
        getHref: (languageCode: string) => {
          return getHrefByRouteAndLanguage(route, languageCode);
        },
      };
    case RoutePath.COLLECTION:
      return {
        title: t('collection.seo.title', {
          name: slugToCapitalName(route.payload.slug),
        }),
        description: t('collection.seo.description', {
          name: slugToCapitalName(route.payload.slug),
        }),
        getHref: (languageCode: string) => {
          return getHrefByRouteAndLanguage(route, languageCode);
        },
      };
    case RoutePath.SEARCH_RESULTS:
      return {
        title: t('search.results.seo.title', {
          searchTerm: route.payload.query,
        }),
        description: t('search.results.seo.description', {
          searchTerm: route.payload.query,
        }),
        getHref: (languageCode: string) => {
          return getHrefByRouteAndLanguage(route, languageCode);
        },
      };
    case RoutePath.HOME:
      return {
        title: t('home-page.seo.title'),
        description: t('home-page.seo.desc'),
        getHref: (languageCode: string) => {
          return getHrefByRouteAndLanguage(route, languageCode);
        },
      };
    default:
      return {
        title: '',
        description: '',
        getHref: (languageCode: string) => {
          return '';
        },
      };
  }
}
