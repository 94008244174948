import { httpClient } from '../http-client';
import { config } from '../config';
import { mapOriginToQueryParam } from '../common/utils';
import type {
  ContainerResponse,
  WebSolution,
} from '@wix/ambassador-marketplace/types';
import type { IRoute, SearchResponse } from '../types/common/marketplace';
import type { AppMarketRpcClient } from '../types/app-market-rpc-client';
import { biLogger } from '../bi';
import { appMarketAutocompleteFailed } from '@wix/bi-logger-app-market-data/v2';
import type {
  SearchResponse as SearchResponseinVespa,
  AutocompleteResponse,
  VersionStatus,
} from '@wix/ambassador-devcenter-ams-v1-app-market-search/types';
import type { GetPrivateAppsAccountResponse } from '@wix/ambassador-devcenter-v1-private-apps-account/types';
import { convertCurrency } from '@wix/ambassador-ecommerce-currency-converter-v1-currency-rate/http';

interface IUserDetails {
  userImage: string;
  username: string;
}

interface IUserDetailsApiResponse {
  user: {
    first: string | null;
    last: string | null;
    userName: string;
    photo?: string;
  };
}

const defaultUserDetails = {
  photo:
    'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iMzYiIGhlaWdodD0iMzYiIHZpZXdCb3g9IjAgMCAzNiAzNiI+CiAgICA8ZGVmcz4KICAgICAgICA8cmVjdCBpZD0iYSIgd2lkdGg9IjM2IiBoZWlnaHQ9IjM2IiByeD0iMTgiLz4KICAgIDwvZGVmcz4KICAgIDxnIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPG1hc2sgaWQ9ImIiIGZpbGw9IiNmZmYiPgogICAgICAgICAgICA8dXNlIHhsaW5rOmhyZWY9IiNhIi8+CiAgICAgICAgPC9tYXNrPgogICAgICAgIDx1c2UgZmlsbD0iI0RBRUZGRSIgZmlsbC1ydWxlPSJub256ZXJvIiB4bGluazpocmVmPSIjYSIvPgogICAgICAgIDxwYXRoIGZpbGw9IiNCMUQ5RjciIGZpbGwtcnVsZT0ibm9uemVybyIgZD0iTTMwIDM1YzAtNS4xMy0zLjIxOC05LjUwNy03Ljc0NS0xMS4yMjRhOCA4IDAgMSAwLTguNTEgMEM5LjIxOCAyNS40OTMgNiAyOS44NzEgNiAzNXYxaDI0di0xeiIgbWFzaz0idXJsKCNiKSIvPgogICAgPC9nPgo8L3N2Zz4K',
};

export const getCurrencyConvert = (
  price: string,
  currency: string,
): Promise<string> => {
  const valueWithoutDecimal = price.replace(/\./g, '');
  const decimalIndex = price.indexOf('.') >= 0 ? price.indexOf('.') : 0;

  return httpClient
    .request(
      convertCurrency({
        amounts: [{ value: valueWithoutDecimal, decimalPlaces: decimalIndex }],
        from: 'USD',
        to: currency,
      }),
    )
    .then(({ data }) => {
      const priceResponse = data.amounts?.[0]?.value;
      const decimalPlacesResponse = data.amounts?.[0].decimalPlaces;
      if (priceResponse && decimalPlacesResponse) {
        const firstIndex = priceResponse.length - decimalPlacesResponse;
        const number = parseFloat(
          `${priceResponse.slice(0, firstIndex)}.${priceResponse.slice(
            firstIndex,
          )}`,
        ).toFixed(2);
        return number;
      }
      return price;
    });
};

const getPlansWithAdditionalChargesWithCurrency = async (
  webSolution: WebSolution,
) => {
  const currencySettings = webSolution.baseInfo?.pricing?.currencySettings;
  const updatedPlans = await Promise.all(
    webSolution.baseInfo?.pricing?.plans?.map(async (plan) => {
      const additionalChargesAmount =
        plan.meteredBilling?.checkoutAdditionalInfo
          ?.split('{')[1]
          ?.split('}')?.[0];
      if (additionalChargesAmount) {
        const additionalChargesWithCurrency = await getCurrencyConvert(
          additionalChargesAmount,
          currencySettings?.code ?? 'USD',
        );
        return {
          ...plan,
          additionalChargesWithCurrency,
        };
      } else {
        return plan;
      }
    }),
  );
  return updatedPlans;
};

export const getSolutionPreviewBySlugOrId = async (
  isPreview: boolean,
  slugOrId: string,
  locale: string,
  experiments: any,
  rpcClient?: AppMarketRpcClient,
): Promise<any> => {
  const isSSR = typeof window === 'undefined';
  const enableMeteredBilling = experiments.enabled(
    'spec.appMarket.meteredBilling',
  );
  if (!enableMeteredBilling) {
    if (isSSR && rpcClient !== undefined) {
      return rpcClient
        .getWebSolutionBySlug({
          idsOrSlugs: slugOrId,
          isPreview,
          lang: locale,
          isApproved: true,
        })
        .then((response) => response.webSolutions?.[0]);
    }

    const newApiURL = 'marketplace-api/v1';
    return httpClient
      .get(
        `${config.baseApiUrl}/_api/${newApiURL}/web-solutions/${slugOrId}?isPreview=${isPreview}&isApproved=true&lang=${locale}`,
      )
      .then(
        ({ data }) =>
          data.webSolutions &&
          data.webSolutions.length > 0 &&
          data.webSolutions[0],
      );
  } else {
    let webSolutionsResponse;
    if (isSSR && rpcClient !== undefined) {
      const { webSolutions } = await rpcClient.getWebSolutionBySlug({
        idsOrSlugs: slugOrId,
        isPreview,
        lang: locale,
        isApproved: true,
      });
      webSolutionsResponse = webSolutions;
    } else {
      const newApiURL = 'marketplace-api/v1';
      const {
        data: { webSolutions },
      } = await httpClient.get(
        `${config.baseApiUrl}/_api/${newApiURL}/web-solutions/${slugOrId}?isPreview=${isPreview}&isApproved=true&lang=${locale}`,
      );
      webSolutionsResponse = webSolutions;
    }
    if (webSolutionsResponse?.[0]) {
      const updatedPlans = await getPlansWithAdditionalChargesWithCurrency(
        webSolutionsResponse[0],
      );

      return {
        ...webSolutionsResponse[0],
        baseInfo: {
          ...webSolutionsResponse[0].baseInfo,
          pricing: {
            ...webSolutionsResponse[0].baseInfo.pricing,
            plans: updatedPlans,
          },
        },
      };
    } else {
      return null;
    }
  }
};

export const getSolutionsBaseBySearchQueryInVespa = (
  query: string,
  locale: string,
  apiLimit: number = 36,
  apiOffset: number = 0,
  rpcClient?: AppMarketRpcClient,
) => {
  const isSSR = typeof window === 'undefined';
  if (isSSR && rpcClient !== undefined) {
    return rpcClient
      .getAppsBySearchQueryInVespa({
        searchTerm: query,
        paging: { limit: apiLimit, offset: apiOffset },
      })
      .then((searchResponse) => ({
        suggestions: searchResponse.webSolutions,
        total: searchResponse.paging.total,
      }))
      .catch((error) => {
        return {
          suggestions: [],
          total: 0,
        };
      });
  }

  return httpClient
    .post<SearchResponseinVespa>(
      `${config.baseApiUrl}/app-market-search/v1/search`,
      {
        withCredentials: true,
        searchTerm: query,
        paging: { limit: apiLimit, offset: apiOffset },
      },
    )
    .then((searchResponse) => ({
      suggestions: searchResponse?.data?.webSolutions,
      total: searchResponse?.data.paging.total,
    }))
    .catch((error) => {
      console.error('cannot fetch apps from vespa', error);
      config.sentryClient.captureException(error);
      return {
        suggestions: [],
        total: 0,
      };
    });
};

export const getWixOfferingBySearchQueryInVespa = (
  query: string,
  locale: string,
  apiLimit: number = 36,
  apiOffset: number = 0,
  rpcClient?: AppMarketRpcClient,
): Promise<any> => {
  const isSSR = typeof window === 'undefined';
  if (isSSR && rpcClient !== undefined) {
    return rpcClient
      .getAppsBySearchQueryInVespa({
        searchTerm: query,
        paging: { limit: apiLimit, offset: apiOffset },
        filter: {
          $and: [
            {
              solution_type: {
                $eq: 'WIX_OFFERING',
              },
            },
            {
              status: {
                $eq: 'PUBLISHED',
              },
            },
          ],
        },
      })
      .then((searchResponse) => ({
        suggestions: searchResponse.webSolutions,
        total: searchResponse.paging.total,
      }))
      .catch(() => ({ suggestions: [], total: 0 }));
  }

  return httpClient
    .post<SearchResponseinVespa>(
      `${config.baseApiUrl}/app-market-search/v1/search`,
      {
        withCredentials: true,
        searchTerm: query,
        filter: {
          $and: [
            {
              solution_type: {
                $eq: 'WIX_OFFERING',
              },
            },
            {
              status: {
                $eq: 'PUBLISHED' as VersionStatus,
              },
            },
          ],
        },
        paging: { limit: apiLimit, offset: apiOffset },
      },
    )
    .then((searchResponse) => ({
      suggestions: searchResponse?.data?.webSolutions,
      total: searchResponse?.data.paging.total,
    }))
    .catch((error) => {
      console.error('cannot fetch Wix Offering from vespa', error);
      config.sentryClient.captureException(error);
      return {
        suggestions: [],
        total: 0,
      };
    });
};

export const getWixOfferingId = (appId: string) => {
  return httpClient
    .post(
      `${config.baseApiUrl}/_api/app-components-reader/v1/app-components-by-ids`,
      {
        withCredentials: true,
        componentTypes: ['WIX_OFFERING'],
        apps: [
          {
            appId,
          },
        ],
      },
    )
    .then((data) => data);
};

export const getAutocompleteQueryInVespa = (
  query: string,
  apiLimit: number = 3,
  apiOffset: number = 0,
  rpcClient?: AppMarketRpcClient,
) => {
  const isSSR = typeof window === 'undefined';
  if (isSSR && rpcClient !== undefined) {
    return rpcClient
      .getAutocompleteQueryInVespa({
        searchTerm: query,
        paging: { limit: apiLimit, offset: apiOffset },
      })
      .then((autocompleteResponse) => ({
        autocomplete: autocompleteResponse.suggestions,
      }))
      .catch((error) => {
        console.error('cannot fetch autocomplete from vespa', error);
        config.sentryClient.captureException(error);
        biLogger.report(
          appMarketAutocompleteFailed({
            autocomplete_query: query,
          }),
        );

        return {
          suggestions: [],
          total: 0,
        };
      });
  }

  return httpClient
    .post<AutocompleteResponse>(
      `${config.baseApiUrl}/app-market-search/v1/autocomplete`,
      {
        withCredentials: true,
        searchTerm: query,
        paging: { limit: apiLimit, offset: apiOffset },
      },
    )
    .then((autocompleteResponse) => ({
      suggestions: autocompleteResponse?.data?.suggestions,
    }))
    .catch((error) => {
      console.error('cannot fetch autocomplete from vespa', error);
      config.sentryClient.captureException(error);

      biLogger.report(
        appMarketAutocompleteFailed({
          autocomplete_query: query,
        }),
      );
      return {
        suggestions: [],
        total: 0,
      };
    });
};

export const getAutocompleteAppsByQuery = (
  term: string,
  locale: string,
): Promise<SearchResponse> => {
  return httpClient
    .get(
      `${config.baseApiUrl}/_serverless/app-market-search/autocomplete?term=${term}&lang=${locale}`,
    )
    .then(({ data }) => data);
};

export const getMenuDataByPlatform = (
  route: IRoute,
  locale: string,
  rpcClient?: AppMarketRpcClient,
): Promise<ContainerResponse> => {
  const platform = mapOriginToQueryParam(route);

  const isSSR = typeof window === 'undefined';
  if (isSSR && rpcClient !== undefined) {
    return rpcClient.getContainer({
      platformName: platform,
      containerName: 'menu',
      lang: locale,
    });
  }

  const baseURl = 'marketplace-api/v1';
  return httpClient
    .get(
      `${config.baseApiUrl}/_api/${baseURl}/container?platformName=${platform}&containerName=menu&lang=${locale}`,
    )
    .then(({ data }) => {
      return data;
    });
};

export const fetchNewInstalledApps = (signedInstance, local: string) => {
  if (signedInstance) {
    return httpClient
      .get(
        `${config.baseApiUrl}/_api/managed-apps/v1/managed-apps?lang=${local}`,
        {
          headers: { Authorization: signedInstance },
          withCredentials: true,
        },
      )
      .then((res) => res?.data?.managedApps)
      .catch((error) => console.log('cannot fetch installed apps', error));
  }
  return Promise.resolve([]);
};

export const fetchInstalledApps = async (signedInstance) => {
  if (signedInstance) {
    return httpClient
      .get(`${config.baseApiUrl}/_api/marketplace-api/v1/managed-apps`, {
        headers: { Authorization: signedInstance },
      })
      .then((res) => res && res.data && res.data.managedWebSolutions)
      .catch((error) => {
        console.error('cannot fetch installed apps', error);
        config.sentryClient.captureException(error);
      });
  }
  return Promise.resolve([]);
};
export const fetchSiteData = (msid: string) => {
  if (msid) {
    return httpClient
      .get(
        `${config.baseApiUrl}/_serverless/app-market-rpc-proxy/premium-features/${msid}`,
      )
      .then((res) => {
        const result = res.data.body || {};
        const premiumFeatures = (result && result.premiumFeatures) || [];
        const domains = (result && result.domains) || [];

        return {
          siteIsPremium: !!premiumFeatures.length,
          siteHasDomain: !!domains.length,
        };
      })
      .catch((error) => {
        config.sentryClient.captureException(error);
        return {
          siteIsPremium: true,
          siteHasDomain: true,
        };
      });
  }
  return {
    siteIsPremium: true,
    siteHasDomain: true,
  };
};

const getUserDetailsFromResponse = (
  response: Partial<IUserDetailsApiResponse>,
): Partial<IUserDetails> => {
  if (response && response.user) {
    const { user } = response;
    const username =
      user.first !== null && user.last !== null
        ? `${user.first} ${user.last}`
        : user.userName;
    return {
      userImage: user.photo ? user.photo : defaultUserDetails.photo,
      username,
    };
  }
  return {};
};

export const getUserDetails =
  async (): Promise<Partial<IUserDetails> | void> => {
    const getUserDetailsEndpoint = `${config.baseApiUrl}/_api/dev-center/user-details`;
    console.log('getUserDetailsEndpoint');
    console.log(getUserDetailsEndpoint);
    return httpClient
      .get(getUserDetailsEndpoint)
      .then(({ data }: { data: IUserDetailsApiResponse }) => data)
      .then(getUserDetailsFromResponse)
      .catch((error) => config.sentryClient.captureException(error));
  };

export const fetchBundleApps = (signedInstance: string) => {
  if (signedInstance) {
    return httpClient
      .get(
        `${config.baseApiUrl}/_api/marketplace-api/v1/bundle-apps?lang=en&includeDeleted=true`,
        {
          headers: { Authorization: signedInstance },
          withCredentials: true,
        },
      )
      .then((res) => res && res.data && res.data.bundleWebSolutions)
      .catch((error) => config.sentryClient.captureException(error));
  }
  return Promise.resolve([]);
};

export const fetchCurrentBundleApps = (signedInstance: string) => {
  if (signedInstance) {
    return httpClient
      .get(
        `${config.baseApiUrl}/_api/marketplace-api/v1/bundle-apps?lang=en&number_of_days=30`,
        {
          headers: { Authorization: signedInstance },
          withCredentials: true,
        },
      )
      .then((res) => res && res.data && res.data.bundleWebSolutions)
      .catch((error) => config.sentryClient.captureException(error));
  }
  return Promise.resolve([]);
};

export const getRandomRecommendations = (
  limit: string,
  lang: string,
  signedInstance: string,
) => {
  if (signedInstance) {
    return httpClient
      .get(
        `${config.baseApiUrl}/_api/marketplace-api/v1/web-solutions/random-recommendations?limit=${limit}&lang=${lang}`,
        { headers: { Authorization: signedInstance } },
      )
      .then(({ data }) => data?.webSolutions || [])
      .catch((error) => config.sentryClient.captureException(error));
  }
  return Promise.resolve([]);
};

export const postDeepLinkEditorCache = (metaSiteId: string, data: any) => {
  if (metaSiteId) {
    return httpClient
      .post(
        `${config.baseApiUrl}/_serverless/deep-link-editor-cache/data`,
        data,
      )
      .catch((error) => config.sentryClient.captureException(error));
  }
  return Promise.resolve([]);
};

export const getPrivateAppsAccount = (
  rpcClient?: AppMarketRpcClient,
): Promise<GetPrivateAppsAccountResponse> => {
  const isSSR = typeof window === 'undefined';
  if (isSSR && rpcClient !== undefined) {
    return rpcClient.getPrivateAppsAccount({}).catch((error) => {
      console.error('cannot fetch account', error);
      return {};
    });
  }

  return httpClient
    .get<GetPrivateAppsAccountResponse>(
      `${config.baseApiUrl}/_api/private-apps/v1/account`,
      { withCredentials: true },
    )
    .then(({ data }) => data)
    .catch((error) => {
      console.error('cannot fetch private apps', error);
      return {};
    });
};

export const getPrivateAppsTagId = async (rpcClient?: AppMarketRpcClient) => {
  const account = await getPrivateAppsAccount(rpcClient);
  return account?.account?.tagId;
};
