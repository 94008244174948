import React from 'react';
import { Box, Text, Heading } from '@wix/design-system';
import s from './styles/pricing-plans.module.scss';

interface IPlanPriceProps {
  decodedSign: string;
  price: string;
  discountPrice?: string;
  period?: string;
  isMobile: boolean;
  index: number;
  showStrikethrough: boolean;
}

const getPriceParts = (
  price: string,
): { amountPart1: string; amountPart2: string } => {
  const divider = price?.includes('.') ? '.' : ',';
  const priceParts = price?.split(divider);
  const part1 = priceParts?.[0] || '0';
  const part2 = priceParts?.[1] || '00';
  return { amountPart1: part1, amountPart2: part2 };
};

export function PlanPrice({
  decodedSign,
  price,
  discountPrice,
  period,
  isMobile,
  index,
  showStrikethrough,
}: IPlanPriceProps) {
  const { amountPart1, amountPart2 } = getPriceParts(price);
  const { amountPart1: discountAmountPart1, amountPart2: discountAmountPart2 } =
    getPriceParts(discountPrice ?? '');

  return (
    <Box>
      <Box marginTop={isMobile ? '2px' : '6px'} height="23px">
        <Box marginRight="4px">
          <Text size="medium">{decodedSign}</Text>
        </Box>
        <Heading
          size="large"
          className={s.planPricingBoxSmallDynamicNew}
          dataHook={`plan-${index}-price-number-part-1`}
        >
          {discountPrice ? discountAmountPart1 : amountPart1}
        </Heading>
        <Box marginTop={isMobile ? '3px' : '1px'} marginLeft="2px">
          <Text
            className={s.planPricingBoxBigDynamicSecondPart}
            weight={isMobile ? 'bold' : 'normal'}
            size="tiny"
            dataHook={`plan-${index}-price-number-part-2`}
          >
            {discountPrice ? discountAmountPart2 : amountPart2}
          </Text>
        </Box>
        <Box
          marginBottom="3px"
          marginTop={isMobile ? '0' : '6px'}
          marginRight="10px"
          marginLeft="2px"
          align="center"
        >
          {period && (
            <Text
              weight={isMobile ? 'bold' : 'normal'}
              size={isMobile ? 'small' : 'medium'}
            >
              {` /${period}`}
            </Text>
          )}
        </Box>
        {discountPrice && showStrikethrough && (
          <Box marginTop={isMobile ? '0' : '2px'}>
            <Text
              skin="standard"
              secondary
              size="medium"
              weight="normal"
              className={s.planPricingBoxStrikethrough}
            >
              {decodedSign}
              {amountPart1}
            </Text>
            <Text
              skin="standard"
              secondary
              className={s.planPricingBoxStrikethroughSecondPart}
              weight={isMobile ? 'bold' : 'normal'}
              size="tiny"
            >
              {amountPart2}
            </Text>
          </Box>
        )}
      </Box>
    </Box>
  );
}
