import React from 'react';
import { SectionHelper, Text } from '@wix/design-system';
import {
  appMarketAppPageOneDependencyImpression,
  appMarketAppPageOneDependencyClick,
} from '@wix/bi-logger-app-market-data/v2';
import { useTranslation } from '../../../../../contexts/translations-context';
import { useEssentials, useRouterContext } from '../../../../../contexts';
import { OneDependencyNotInstalledNotificationDataHooks } from './data-hooks';
import { RoutePath } from '../../../../../models';

export interface OneDependencyNotInstalledNotificationProps {
  app: {
    id: string;
    name: string;
  };
  appDependency: {
    id: string;
    name: string;
    slug: string;
  };
}

export function OneDependencyNotInstalledNotification({
  app,
  appDependency,
}: OneDependencyNotInstalledNotificationProps) {
  const { biLogger } = useEssentials();
  const { t } = useTranslation();
  const { router } = useRouterContext();

  const navigateToDependencyAppPage = () => {
    router.navigateTo({
      path: RoutePath.APP_PAGE,
      payload: {
        slug: appDependency.slug,
        referral: `app-dependency-${appDependency.id}`,
      },
    });
  };

  React.useEffect(() => {
    biLogger.report(
      appMarketAppPageOneDependencyImpression({
        app_id: app.id,
        dependency_type: 'app',
        app_dependency: appDependency.id,
        type: 'one',
      }),
    );
  }, []);

  return (
    <SectionHelper
      dataHook={OneDependencyNotInstalledNotificationDataHooks.container}
      appearance="danger"
      fullWidth={true}
      actionText={t('solution.page.appDependencyNotInstalled.cta', {
        appRequirement: appDependency.name,
      })}
      onAction={() => {
        biLogger.report(
          appMarketAppPageOneDependencyClick({
            app_id: app.id,
            dependency_type: 'app',
            click_type: 'app',
            app_dependency: appDependency.id,
            type: 'one',
          }),
        );
        navigateToDependencyAppPage();
      }}
      title={t('solution.page.appDependencyNotInstalled.title', {
        appDependency: app.name,
        appRequirement: appDependency.name,
        interpolation: { escapeValue: false },
      })}
    >
      <Text
        dataHook={OneDependencyNotInstalledNotificationDataHooks.description}
        size="small"
        weight="thin"
      >
        {t('solution.page.appDependencyNotInstalled.subtitle', {
          appRequirement: appDependency.name,
        })}
      </Text>
    </SectionHelper>
  );
}
