import { RequestService } from '../request-service';
import { MarketListings } from './market-listings';
import type {
  QueryMarketListingRequest,
  QueryMarketListingResponse,
} from '@wix/ambassador-devcenter-app-market-listing-v1-market-listing/types';
import type RpcModule from '@wix/ambassador-devcenter-app-market-listing-v1-market-listing/rpc';
import type HttpModule from '@wix/ambassador-devcenter-app-market-listing-v1-market-listing/http';

export type QueryMarketListing = {
  appIds: string[];
  languageCodes: string[];
  status?: string;
};

type RpcModuleType = typeof RpcModule;
type HttpModuleType = typeof HttpModule;

export class MarketListingService extends RequestService<
  RpcModuleType,
  HttpModuleType
> {
  protected async rpcModule(): Promise<RpcModuleType> {
    return import(
      '@wix/ambassador-devcenter-app-market-listing-v1-market-listing/rpc'
    );
  }

  protected async httpModule(): Promise<HttpModuleType> {
    return import(
      '@wix/ambassador-devcenter-app-market-listing-v1-market-listing/http'
    );
  }

  public async query(payload: QueryMarketListing) {
    if (!payload.appIds.length) {
      throw new Error('appIds empty in queryMarketListing');
    }
    const response = await this.request<
      QueryMarketListingRequest,
      QueryMarketListingResponse
    >({
      methodName: 'queryMarketListing',
      payload: {
        query: {
          cursorPaging: { limit: 100 },
          filter: {
            appId: payload.appIds,
            languageCode: [...new Set(['en', ...payload.languageCodes])],
            ...(payload.status ? { status: payload.status } : {}),
          },
        },
      },
    });
    return new MarketListings(response.marketListing || []);
  }
}
