import React from 'react';
import type {
  Plan,
  PricingPlansSectionOptions,
} from '@wix/app-market-components';
import type { CurrencySettings, TaxDetails } from '@wix/app-market-services';
import { PricingPlansSection as PricingPlansSectionComponent } from '@wix/app-market-components';
import { useEssentials, useUserContext } from '../../../../contexts';
import {
  appMarketAppPageClick,
  appmarketPackagePickerToggleCycleClick,
} from '@wix/bi-logger-app-market-data/v2';
import {
  getAppMarketAppPageClickBiData,
  getAppMarketPackagePickerToggleCycleClickBiData,
} from '../../../../bi-services/events-data-parser';

export interface AppPricingPlansSectionProps {
  externalPricingPageUrl?: string;
  isExternalPricing?: boolean;
  trialDays?: number;
  tax: TaxDetails;
  currencySettings: CurrencySettings;
  companyName: string;
  appName: string;
  plans: Plan[];
  appId: string;
  options?: PricingPlansSectionOptions;
}
export function AppPricingPlansSection({
  externalPricingPageUrl,
  isExternalPricing,
  trialDays,
  tax,
  currencySettings,
  companyName,
  appName,
  plans,
  appId,
  options,
}: AppPricingPlansSectionProps) {
  const { biLogger } = useEssentials();
  const userContext = useUserContext();
  const { i18n, experiments } = useEssentials();
  const isMobile = userContext?.isMobile ?? false;
  const sectionName = 'pricing';

  const onToggleClick = (toggleValue: string) => {
    biLogger.report(
      appmarketPackagePickerToggleCycleClick(
        getAppMarketPackagePickerToggleCycleClickBiData({
          appId,
          cycleClick: toggleValue,
          numberOfPlans: plans.length,
        }),
      ),
    );
  };

  const onLinkClick = () => {
    biLogger.report(
      appMarketAppPageClick(
        getAppMarketAppPageClickBiData({
          appId,
          location: sectionName,
          cta: 'compare_all_plans',
          url: externalPricingPageUrl ?? '',
        }),
      ),
    );
  };

  return (
    <PricingPlansSectionComponent
      externalPricingPageUrl={externalPricingPageUrl}
      isExternalPricing={isExternalPricing}
      trialDays={trialDays}
      tax={tax}
      currencySettings={currencySettings}
      companyName={companyName}
      appName={appName}
      plans={plans}
      isMobile={isMobile}
      i18n={i18n}
      experiments={experiments}
      onToggleClick={onToggleClick}
      onLinkClick={onLinkClick}
      options={options}
    />
  );
}
