import type { AppGroupSection, Services } from '@wix/app-market-services';

export async function getRecommendedForYouSection({
  services,
}: {
  services: Services;
}): Promise<AppGroupSection | null> {
  const recommendedForYouTag = 'b561bac9-1dda-491f-ba14-8e0f5fd89f2c';
  try {
    const sections = await services.getTagSections({
      tagIds: [recommendedForYouTag],
    });
    return sections.getAppGroupSections()?.[0] ?? null;
  } catch (e) {
    console.error(
      `[recommended-for-you-section.api] (getRecommendedForYouSection) failed for recommendedForYouTag:${recommendedForYouTag}, error:`,
      e,
    );
    return null;
  }
}
