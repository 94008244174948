import React, { useEffect, useState } from 'react';
import { Box, EmptyState, Heading } from '@wix/design-system';
import { useTranslation } from '../../contexts/translations-context';
import { useEssentials, useServices, useSiteContext } from '../../contexts';
import { queryApps } from '../collection/collection-page-data';
import { getRecommendedForYouSection } from '../app-page/app-page-sections/recommended-for-you';
import { AppsSection } from '@wix/app-market-components';
import { useAppsSectionSharedPropsMapper } from '../../hooks/apps-section-props-mapper';
import { AppMarketPage } from '../../enums';
import type { AppGroupSection, AppDataType } from '@wix/app-market-services';
import { CenteredLoader } from '../../components/centered-loader';
import { appMarketSearchResultsPageEmptyStateIsShownSrc24Evid166 } from '@wix/bi-logger-app-market-data/v2';

export function SearchResultsEmptyState({
  searchTerm,
  showRecommendedApps,
}: {
  searchTerm: string;
  showRecommendedApps?: boolean;
}) {
  const appsSectionSharedPropsMapper = useAppsSectionSharedPropsMapper();
  const { t } = useTranslation();
  const { metaSiteId, languageCode } = useSiteContext();
  const { biLogger, experiments } = useEssentials();
  const services = useServices();
  const pageName = AppMarketPage.SEARCH_RESULT;
  const [appGroupData, setAppGroupData] = useState<AppGroupSection>();
  const [apps, setApps] = useState<Omit<AppDataType, 'freeTrialDays'>[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function fetchTeamPicksApps() {
    setIsLoading(true);
    const { appGroup } = await queryApps({
      services,
      experiments,
      slug: 'staff-picks',
      languageCode,
    });
    setIsLoading(false);

    return appGroup;
  }

  async function fetchRecommendedForYouApps() {
    setIsLoading(true);
    const appGroup = await getRecommendedForYouSection({ services });
    setIsLoading(false);

    return appGroup;
  }

  useEffect(() => {
    metaSiteId
      ? fetchRecommendedForYouApps().then((appGroup) => {
          setApps(appGroup!.apps);
          setAppGroupData(appGroup!);
        })
      : fetchTeamPicksApps().then((appGroup) => {
          setApps(appGroup.apps);
          setAppGroupData(appGroup);
        });
  }, [metaSiteId]);

  useEffect(() => {
    biLogger.report(
      appMarketSearchResultsPageEmptyStateIsShownSrc24Evid166({}),
    );
  }, []);

  return (
    <>
      <EmptyState
        theme="page"
        title={t('searchResultHeaderEmptyState.noResult', {
          query: searchTerm,
        })}
        subtitle={t('searchResultHeaderEmptyState.tryAgain')}
      />
      {showRecommendedApps &&
        (isLoading ? (
          <Box marginTop="24px" direction="vertical">
            <CenteredLoader />
          </Box>
        ) : (
          <Box
            dataHook="apps-section-box"
            direction="vertical"
            marginTop="24px"
          >
            {metaSiteId ? (
              <>
                <AppsSection
                  showBorder={false}
                  {...appsSectionSharedPropsMapper(
                    {
                      ...appGroupData!,
                      apps: apps as AppGroupSection['apps'],
                    },
                    pageName,
                    'recommended-for-you',
                    0,
                    '',
                    'medium',
                  )}
                ></AppsSection>
              </>
            ) : (
              <>
                <Heading size="medium" dataHook="apps-section-header">
                  {t('search.results.page.team.picks.title')}
                </Heading>
                <AppsSection
                  showBorder={false}
                  {...appsSectionSharedPropsMapper(
                    {
                      ...appGroupData!,
                      apps: apps as AppGroupSection['apps'],
                    },
                    pageName,
                    'staff-picks',
                    0,
                  )}
                ></AppsSection>
              </>
            )}
          </Box>
        ))}
    </>
  );
}
