import type { ComponentType, FC } from 'react';
import React from 'react';
import type { i18n } from '@wix/wix-i18n-config';
import type { TFunction } from 'react-i18next';
import { useInitEssentials } from '../hooks/essentials';
import type { ExternalComponentProps } from '../exported-components/types';
import type Experiments from '@wix/wix-experiments';

export interface Essentials {
  t: TFunction;
  i18n: i18n;
  experiments?: Experiments;
}

export const EssentialsContext = React.createContext<Essentials | undefined>(
  undefined,
);

interface EssentialsContextProps {
  essentials: Essentials;
  children: React.ReactNode;
}

export const EssentialsContextProvider: FC<EssentialsContextProps> = ({
  essentials,
  children,
}) => (
  <EssentialsContext.Provider value={essentials}>
    {children}
  </EssentialsContext.Provider>
);

export function withEssentialsContext<Props extends ExternalComponentProps>(
  Component: ComponentType<Props>,
) {
  return (props: Props) => {
    const { essentials, ready } = useInitEssentials({
      i18n: props?.i18n,
      experiments: props?.experiments,
      languageCode: props?.languageCode,
    });
    if (!ready) {
      return null;
    }

    return (
      <EssentialsContextProvider essentials={essentials}>
        <Component {...props} />
      </EssentialsContextProvider>
    );
  };
}

export const useEssentials = () => {
  const context = React.useContext(EssentialsContext);
  if (context === undefined) {
    throw new Error(
      'useEssentials must be used within a EssentialsContextProvider',
    );
  }
  return context;
};
