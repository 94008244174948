import { appMarketParamsParser } from './parse-params';
import { RoutePath } from './routes';
import type { QueryMap } from '../query';
import { Route } from './route';

export function paramsRoute({
  payload,
  baseURL,
  slug,
}: {
  payload: Partial<QueryMap>;
  baseURL?: string;
  slug?: string;
}): Route {
  let route: string | undefined,
    shareId: string | undefined,
    appDefId: string | undefined,
    appId: string | undefined,
    version: string | undefined,
    modal: string | undefined,
    id: string | undefined,
    customParams: any;

  if (payload.appMarketParams) {
    const appMarketParams = appMarketParamsParser(payload.appMarketParams);
    if (appMarketParams) {
      ({ route, appDefId, shareId, version, appId, modal, customParams } =
        appMarketParams);
      id = appDefId || appId;
    }
  } else if (payload.openAppDefId) {
    id = payload.openAppDefId;
    version = '';
  }

  if (customParams || customParams === null) {
    return new Route({
      path: RoutePath.OPEN_APP_WITH_PARAMS,
      payload: { appId, version, openAppOptions: customParams },
      baseURL,
    });
  }
  if (id && !route) {
    return new Route({
      path: RoutePath.FINISH_SETUP,
      payload: { version, appId: id },
      baseURL,
    });
  }

  switch (route) {
    case 'manageApps':
      return new Route({
        path: RoutePath.MANAGE_APPS,
        payload: {
          manageAppsDeepLinkData: {
            appId: appId || '',
            modal: modal || '',
          },
        },
        baseURL,
      });

    case 'preview':
      return new Route({
        path: RoutePath.APP_PAGE,
        payload: { appId: appDefId, slug, status: 'DRAFT' },
        baseURL,
      });

    case 'testApp':
      return new Route({
        path: RoutePath.APP_PAGE,
        payload: {
          appId: appDefId,
          slug: slug ?? appDefId,
          status: 'DRAFT',
          version,
        },
        baseURL,
      });

    case 'shareApp':
      return new Route({
        path: RoutePath.SHARE,
        payload: { appId: appDefId, shareId, version },
        baseURL,
      });

    case 'updateApp':
      return new Route({
        path: RoutePath.FINISH_UPDATE,
        payload: { version, appId },
        baseURL,
      });
    default:
      return new Route({
        path: RoutePath.UNSUPPORTED,
        payload: undefined,
        baseURL,
      });
  }
}
