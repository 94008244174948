import React from 'react';
import { createRoot } from 'react-dom/client';
import { StandaloneNotification } from '@wix/marketplace-component';

function showToast(toastOptions: any): void {
  const container = document.getElementById('notifications-root')!;
  const root = createRoot(container);
  root.render(<StandaloneNotification {...toastOptions} />);
}

export { showToast };
