import { useState } from 'react';
import { initI18nWithoutICU, type i18n } from '@wix/wix-i18n-config';
import type { Essentials } from '../contexts/essentials-context';
import { useTranslationsLoader } from './translations';

type Props = {
  languageCode?: string;
  i18n?: i18n;
  experiments?: Essentials['experiments'];
};

export const useInitEssentials = (
  props: Props,
): { essentials: Essentials; ready: boolean } => {
  const { languageCode, experiments } = props ?? {};
  const [i18n] = useState(
    initI18nWithoutICU({
      locale: languageCode || 'en',
      messages: {},
    }),
  );
  const i18nInstance = props?.i18n || i18n;
  const { t, ready } = useTranslationsLoader(i18nInstance);
  return { essentials: { t, i18n: i18nInstance, experiments }, ready };
};
