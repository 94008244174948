import * as React from 'react';
import s from './app-gallery.module.scss';
import { Box, Image, Carousel, Proportion } from '@wix/design-system';
import type { Media } from '../../../exported-components/quick-info';

export interface AppGalleryProps {
  media: Media[];
  onGalleryChange: (oldIndex: number, newIndex: number) => void;
}

export function AppGallery({ media, onGalleryChange }: AppGalleryProps) {
  function getAppImages() {
    return media.flatMap((mediaItem) =>
      mediaItem.type === 'IMAGE' ? mediaItem.url : [],
    );
  }
  function getAppVideo() {
    return media.find((mediaItem) => mediaItem.type === 'YOUTUBE')?.url;
  }
  const appImages = getAppImages();
  const appVideo = getAppVideo();

  return (
    <Box className={s.appGallery} direction="vertical" marginTop="12px">
      {appImages.length === 1 && !appVideo ? (
        <Proportion aspectRatio={4 / 3}>
          <Image src={appImages[0]} height="100%" fit="cover" />
        </Proportion>
      ) : (
        <Carousel
          controlsPosition="bottom"
          controlsSize="small"
          beforeChange={onGalleryChange}
          dataHook="app-gallery-carousel"
        >
          {appVideo && (
            <Proportion key={0} aspectRatio={4 / 3}>
              <iframe
                title="app-video"
                width="100%"
                height="100%"
                style={{ borderRadius: '8px' }}
                src={appVideo}
                allow="fullscreen;accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              ></iframe>
            </Proportion>
          )}

          {appImages.map((imgUrl, index) => {
            return (
              <Proportion key={index} aspectRatio={4 / 3}>
                <Image src={imgUrl} height="100%" fit="cover" />
              </Proportion>
            );
          })}
        </Carousel>
      )}
    </Box>
  );
}
