import * as React from 'react';
import s from './search-input.scss';
import { throttle, debounce } from 'throttle-debounce';
import { Search, Loader, Box } from '@wix/design-system';
import type { TFunction } from 'react-i18next';
import { composer } from '../../../component-decorators/composer';

export interface ISearchInputProps {
  onSearchValueChange(value: string): Promise<void>;
  isLoading: boolean;
  isInMenu: boolean;
  isInHeader?: boolean;
  searchValue: string;
  isExpandable?: boolean;
  isInSearchPage?: boolean;
  isInSearchPageWide?: boolean;
  onSearchInputEnter?(value: string): any;
  t?: TFunction;
  locale: string;
}

export const SearchInput = composer()
  .withTranslation()
  .compose(({ t, ...props }: ISearchInputProps) => (
    <SearchInputComponent t={t} {...props} />
  ));

export class SearchInputComponent extends React.Component<
  ISearchInputProps,
  any
> {
  autocompleteSearchDebounced: any;
  autocompleteSearchThrottled: any;
  constructor(props) {
    super(props);
    this.autocompleteSearchDebounced = debounce(
      300,
      this.props.onSearchValueChange,
    );
    this.autocompleteSearchThrottled = throttle(
      300,
      this.props.onSearchValueChange,
    );
    this.state = {
      query: props.searchValue || '',
    };
  }

  clearSearchInput = () => {
    this.setState({
      query: '',
    });
    this.props.onSearchValueChange('');
  };

  handleChange = (event) => {
    this.setState({ query: event.target.value }, () => {
      const query = this.state.query;
      if (query.length < 5) {
        this.autocompleteSearchThrottled(query);
      } else {
        this.autocompleteSearchDebounced(query);
      }
    });
  };
  handleKeyDown = (event) => {
    if (event.key === 'Enter' && this.state.query) {
      this.props.onSearchInputEnter(this.state.query);
    }
  };
  render() {
    const {
      isLoading,
      isInMenu,
      isExpandable,
      isInHeader,
      isInSearchPage,
      isInSearchPageWide,
    } = this.props;
    return (
      <div
        data-testid="search-input"
        className={`${s.searchInput}
        ${isInHeader ? s.isInHeader : ''}
        ${isInMenu ? s.isInMenu : ''}
        ${isExpandable ? s.isExpandable : ''}
        ${isInSearchPageWide ? s.isInSearchPageWide : ''}
        ${isInSearchPage ? s.isInSearchPage : ''}`}
        onKeyDown={this.handleKeyDown}
      >
        {isExpandable ? (
          <Box align="right" verticalAlign="middle">
            <Search
              itemHeight="big"
              dataHook="search-input-expandable"
              onChange={(e) => this.handleChange(e)}
              placeholder={this.props.t('search.box.place.holder')}
              size="medium"
              expandable={isExpandable}
              value={this.state.query}
              onClear={this.clearSearchInput}
              suffix={
                isLoading ? (
                  <div className={s.iconsWrapper}>
                    <div className={s.loaderWrapper}>
                      <Loader size="tiny" />
                    </div>{' '}
                  </div>
                ) : null
              }
            />
          </Box>
        ) : (
          <Search
            itemHeight="big"
            dataHook="search-input"
            onChange={(e) => this.handleChange(e)}
            placeholder={this.props.t('search.box.place.holder')}
            size={isInMenu || isInHeader ? 'small' : 'large'}
            value={this.state.query}
            onClear={this.clearSearchInput}
            suffix={
              isLoading ? (
                <div className={s.iconsWrapper}>
                  <div className={s.loaderWrapper}>
                    <Loader size="tiny" />
                  </div>{' '}
                </div>
              ) : null
            }
          />
        )}
      </div>
    );
  }
}
