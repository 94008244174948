import type Experiments from '@wix/wix-experiments';
import type { Services } from '@wix/app-market-services';
import {
  NOT_SUPPORTED_IN_EDITOR_X_TAG_ID,
  NOT_SUPPORTED_IN_STUDIO_TAG_ID,
} from './constants';

export async function isAppSupportedInResponsiveEditors({
  services,
  appId,
  experiments,
}: {
  services: Services;
  appId: string;
  experiments: Experiments;
}) {
  const isEnableInstallationInEditorX = experiments?.enabled(
    `specs.marketplace.editorx-for-${appId}`,
  );
  const isEnableInstallationInStudio = experiments?.enabled(
    `specs.marketplace.studio-for-${appId}`,
  );

  const tagApps = await services.queryTagApp({
    appIds: [appId],
    tagIds: {
      $in: [NOT_SUPPORTED_IN_STUDIO_TAG_ID, NOT_SUPPORTED_IN_EDITOR_X_TAG_ID],
    },
  });

  return {
    supportedInEditorX:
      isEnableInstallationInEditorX ||
      !tagApps.tagIds.some(
        (tagId: string) => tagId === NOT_SUPPORTED_IN_EDITOR_X_TAG_ID,
      ),
    supportedInStudio:
      isEnableInstallationInStudio ||
      !tagApps.tagIds.some(
        (tagId: string) => tagId === NOT_SUPPORTED_IN_STUDIO_TAG_ID,
      ),
  };
}
