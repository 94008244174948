import { Box, SkeletonGroup, SkeletonLine } from '@wix/design-system';
import React from 'react';
import { AppGridSkeleton } from '@wix/app-market-components';
import { useEssentials, useUserContext } from '../../contexts';

export function AppsSkeleton() {
  const { isMobile } = useUserContext();
  return (
    <SkeletonGroup>
      <Box marginTop="18px">
        <AppGridSkeleton boxCount={24} minColumns={isMobile ? 1 : 3} />
      </Box>
    </SkeletonGroup>
  );
}

export function LoadMoreAppsSkeletons() {
  const { isMobile } = useUserContext();
  return (
    <SkeletonGroup>
      <Box marginTop="18px">
        <AppGridSkeleton boxCount={12} minColumns={isMobile ? 1 : 3} />
      </Box>
    </SkeletonGroup>
  );
}

export function AppsSectionSkeleton({
  showSaleToggle = true,
}: {
  showSaleToggle?: boolean;
}) {
  const { experiments } = useEssentials();
  const shouldShowSaleToggle =
    experiments.enabled('specs.app-market.SaleToggle') && showSaleToggle;

  return (
    <SkeletonGroup>
      <Box
        dataHook="skeleton-apps-count"
        verticalAlign="space-between"
        marginTop="22px"
      >
        <SkeletonLine width="48px" />
        {shouldShowSaleToggle && <SkeletonLine width="48px" />}
      </Box>
      <AppsSkeleton />
    </SkeletonGroup>
  );
}
