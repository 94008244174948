import { useMemo } from 'react';
import { useEssentials } from '../../../contexts/essentials-context';

type DateFormatter = (date: Date) => string;

export const useDateFormatter = (): DateFormatter => {
  const { i18n } = useEssentials();
  const { format } = useMemo(
    () =>
      new Intl.DateTimeFormat(i18n.language || 'en', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC',
        timeZoneName: 'short',
        hour12: false,
      }),
    [i18n.language],
  );

  return (date: Date) => format(date);
};
