import { EAppBadges, type AppBadgeType } from '../apps-badges';
import { AppBundleType, BundleAppOrigin } from './types';
import type { BundleWebSolution, IBundleApp } from './types';

export class BundleApp {
  constructor(private readonly bundleApp: BundleWebSolution | undefined) {}

  get appId(): string | undefined {
    return this.bundleApp?.appId;
  }

  get badge(): AppBadgeType | undefined {
    if (!this.bundleApp) {
      return undefined;
    }
    return {
      badge: EAppBadges.PREMIUM_BENEFIT,
      payload: {
        priority: 2,
        appBundleType:
          this.bundleApp.appOrigin === BundleAppOrigin.COUPON
            ? AppBundleType.COUPON
            : AppBundleType.DEFAULT,
      },
    };
  }

  toJSON(): IBundleApp {
    return {
      appId: this.appId,
      badge: this.badge,
    };
  }
}
