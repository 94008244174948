import React from 'react';
import { AppSectionSkeleton } from '@wix/app-market-components';
import { useUserContext } from '../../contexts';

export const AppsSectionsSkeleton: React.FunctionComponent = () => {
  const { isMobile } = useUserContext();

  return (
    <>
      {Array.from({ length: 8 }).map(() => (
        <AppSectionSkeleton boxCount={8} minColumns={isMobile ? 1 : 3} />
      ))}
    </>
  );
};
