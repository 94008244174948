import React from 'react';
import { Box, Text, Heading } from '@wix/design-system';
import { WriteReviewButton } from '../write-review-button';
import { useRateAndReviewSectionContext } from '../../../exported-components/rate-and-reviews-section/rate-and-reviews-section-context';
import { useEssentials } from '../../../contexts/essentials-context';
import s from './reviews-empty-state.module.scss';

export function ReviewsEmptyState() {
  const { shouldShowUserActionButton } = useRateAndReviewSectionContext();
  const { t } = useEssentials();

  return (
    <Box
      className={s.reviewsEmptyState}
      align="space-between"
      dataHook="reviews-empty-state"
    >
      <Box direction="vertical">
        <Heading size="large">{t('solution.page.noReviewsYetTitle')}</Heading>
        <Text>{t('app.page.reviews.empty.state')}</Text>
      </Box>
      {shouldShowUserActionButton && (
        <Box direction="vertical" marginTop="6px">
          <WriteReviewButton />
        </Box>
      )}
    </Box>
  );
}
