import { Popover, TextButton, Text, Divider } from '@wix/design-system';
import * as React from 'react';
import s from './header-desktop-user-details.scss';
import { composer } from '../../../../component-decorators/composer';
import type { TFunction } from 'react-i18next';
import { HeaderUserImage } from '../header-user-image';
import { ChevronDown } from '@wix/wix-ui-icons-common';
import { HeaderDesktopLanguagesPopover } from './header-desktop-languages-popover/header-desktop-languages-popover';
import type { InjectedUserDetailsProps } from '../../../../component-decorators/with-user-details';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import type { MarketplaceStore } from '../../../../component-decorators/with-marketplace-store';

interface IHeaderDesktopUserDetailsProps
  extends Partial<InjectedUserDetailsProps> {
  logoutLink: string;
}

export const HeaderDesktopUserDetails = ({
  username,
  logoutLink,
}: IHeaderDesktopUserDetailsProps) => {
  const [popoverOpen, setPopoverOpen] = React.useState(false);
  const flipPopoverOpen = () => {
    setPopoverOpen(!popoverOpen);
  };
  return (
    <Popover
      animate
      appendTo="window"
      flip
      onClick={flipPopoverOpen}
      onClickOutside={flipPopoverOpen}
      placement="bottom-end"
      showArrow
      shown={popoverOpen}
      theme="light"
      className={s.popover}
    >
      <Popover.Element>
        <div className={s.popoverElement}>
          <TextButton
            as="button"
            dataHook="user-image"
            skin="dark"
            prefixIcon={<HeaderUserImage />}
            suffixIcon={<ChevronDown />}
          >
            {username}
          </TextButton>
        </div>
      </Popover.Element>
      <Popover.Content>
        <DesktopUserDetailsMenuContent logoutLink={logoutLink} />
      </Popover.Content>
    </Popover>
  );
};

interface IDesktopUserDetailsMenuContent
  extends Partial<InjectedUserDetailsProps>,
    Partial<InjectedExperimentsProps> {
  marketplaceStore?: MarketplaceStore;
  logoutLink: string;
}

const DesktopUserDetailsMenuContent = composer()
  .withMarketplaceStore()
  .withExperiments()
  .compose(
    ({
      marketplaceStore,
      logoutLink,
      experiments,
    }: IDesktopUserDetailsMenuContent) => {
      return (
        <div className={s.popoverContentContainer}>
          <div className={s.popoverContent}>
            <div className={s.userDetails}>
              <HeaderUserImage size="size48" marginLeft="0" />
              <div className={s.userDetailsText}>
                <Text weight="bold" ellipsis showTooltip={false}>
                  {marketplaceStore.userName}
                </Text>
                <LogoutLink signoutLink={logoutLink} />
              </div>
            </div>
            <>
              <div className={s.divider}>
                <Divider />
              </div>
              <HeaderDesktopLanguagesPopover />
            </>
          </div>
        </div>
      );
    },
  );

const LogoutLink = composer()
  .withTranslation()
  .compose(({ t, signoutLink }: { t?: TFunction; signoutLink: string }) => (
    <TextButton as="a" href={signoutLink} dataHook="logout">
      {t('standalone.header.logout')}
    </TextButton>
  ));
