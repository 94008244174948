import s from '../pages.scss';
import {
  SkeletonLine,
  SkeletonRectangle,
  SkeletonCircle,
  Box,
  SkeletonGroup,
  Page,
} from '@wix/design-system';
import React from 'react';
import { useUserContext } from '../../contexts';
import { AppsSectionsSkeleton } from '../../components/apps-sections-skeleton';

export function CategorySkeleton() {
  const { isMobile } = useUserContext();

  return (
    <Page className={`${s.appMarketPage} ${s.appMarketPageBackgroundColor}`}>
      <Page.Content>
        <SkeletonGroup skin="light">
          <Box
            dataHook="skeleton-header"
            marginTop="22px"
            align="space-between"
          >
            <Box dataHook="skeleton-header-text" direction="vertical">
              <Box dataHook="back-button-skeleton" marginLeft="4px">
                <SkeletonLine width="48px" />
              </Box>
              <Box
                dataHook="skeleton-titles"
                marginTop="20px"
                direction="vertical"
              >
                <SkeletonLine width={isMobile ? '112px' : '132px'} />
                <SkeletonRectangle
                  width={isMobile ? '222px' : '638px'}
                  height="30px"
                  marginTop="6px"
                />
              </Box>
            </Box>
            <Box
              dataHook="skeleton-header-image"
              align="right"
              marginTop="10px"
            >
              <SkeletonCircle diameter={isMobile ? '78px' : '96px'} />
            </Box>
          </Box>
          <AppsSectionsSkeleton />
        </SkeletonGroup>
      </Page.Content>
    </Page>
  );
}
