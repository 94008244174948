import { Box, SidebarNext } from '@wix/design-system';
import s from './sidebar.scss';
import React from 'react';

export function SidebarComponentLoader() {
  return (
    <Box zIndex={10} className={s.sidebar}>
      <SidebarNext skin="light" isLoading={true} />
    </Box>
  );
}
