import * as React from 'react';
import style from './solution-badge.scss';
import {
  PremiumSmall,
  Premium,
  StatusWarningFilledSmall,
  StatusCompleteFilledSmall,
} from '@wix/wix-ui-icons-common';
import { Box, Popover, Text } from '@wix/design-system';
import type { TFunction } from 'react-i18next';
import { composer } from '../../component-decorators/composer';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { usePremiumCurrencyFormatter } from '@wix/premium-react-text';
import type { Pricing } from '@wix/ambassador-marketplace/types';
import { getLowestPrice, getPricingLabel } from '../common';

export interface ISolutionBadgeProps extends Partial<InjectedExperimentsProps> {
  isInstalled: boolean;
  showPremiumLabel?: boolean;
  premiumTheme?: 'premium';
  pricing?: Pricing;
  t: TFunction;
  isBundleAppInstalled?: boolean;
  isBundleAppNotInstalled?: boolean;
  isAppSetupIncomplete?: boolean;
  shouldShowIncompleteSetupPopover?: boolean;
  savedPrice?: number;
  companyId?: string;
  textSize?: 'medium' | 'small';
  textWeight?: 'normal' | 'thin';
  isPreInstalled?: boolean;
}

export const SolutionBadge = composer()
  .withExperiments()
  .compose(
    ({
      t,
      isInstalled,
      showPremiumLabel,
      pricing,
      isBundleAppInstalled,
      isBundleAppNotInstalled,
      savedPrice,
      isAppSetupIncomplete,
      shouldShowIncompleteSetupPopover,
      experiments,
      companyId,
      textSize,
      textWeight,
      premiumTheme,
      isPreInstalled,
    }: ISolutionBadgeProps) => {
      const shouldShowFullPrices = experiments.enabled(
        'specs.marketplace.fullPrices',
      );
      const shouldShowNewBundleBadge = experiments.enabled(
        'solution.page.freeBundleNotInstalled',
      );
      const shouldMergeFreeBadges = false;
      const [isPopoverShown, setIsPopoverShown] = React.useState(false);
      const [seeFullPrices, setSeeFullPrices] = React.useState<boolean>(false);

      React.useEffect(() => {
        if (
          shouldShowFullPrices &&
          pricing?.businessModel === 'PREMIUM' &&
          pricing?.freeTrialDays <= 0 &&
          pricing?.plans?.[0]?.premiumPrices?.fullPrices
        ) {
          setSeeFullPrices(true);
        }
      }, [pricing?.plans?.[0]?.premiumPrices?.fullPrices]);

      const priceLabel = getPricingLabel(pricing, companyId);

      const [currencyToString] = usePremiumCurrencyFormatter({
        currencySettings: {
          [pricing?.currencySettings?.code]: {
            symbol: pricing?.currencySettings?.symbol,
            decimalSep: pricing?.currencySettings?.decimalSeparator,
            groupSep: pricing?.currencySettings?.groupSeparator,
            negPrefix: pricing?.currencySettings?.negativePrefix,
            negSuffix: pricing?.currencySettings?.negativeSuffix,
            posPrefix: '¤',
            posSuffix: '',
            fractionSize: 2,
          },
        },
        currencyCode: pricing?.currencySettings?.code,
      });
      const fixedPrice =
        savedPrice !== undefined && savedPrice !== 0
          ? Number(savedPrice).toFixed(2)
          : '';
      const fixedPriceWithTaxAndCurrency = shouldShowFullPrices
        ? currencyToString(Number(fixedPrice))
        : `$${fixedPrice}`;
      if (isPreInstalled) {
        return (
          <span className={style.centeredView}>
            <StatusCompleteFilledSmall className={style.installedIcon} />
            <Text size="tiny" skin="success" dataHook="built-in-text">
              {t('solutionBox.solutionBottomActionBar.builtInSolution')}
            </Text>
          </span>
        );
      }
      if (isBundleAppNotInstalled && !shouldShowNewBundleBadge) {
        return (
          <span className={style.flexView}>
            {fixedPrice !== '' && (
              <span style={{ textDecoration: 'line-through' }}>
                <Text size={textSize || 'tiny'} weight={textWeight || 'thin'}>
                  {fixedPriceWithTaxAndCurrency}
                </Text>
              </span>
            )}
            <span style={{ marginLeft: '6px' }}>
              <Text
                size={textSize || 'tiny'}
                weight={textWeight || 'thin'}
                skin="success"
              >
                {t('solutionBox.solutionBottomActionBar.BundleNotInstalled')}
              </Text>
            </span>
          </span>
        );
      }
      if (isBundleAppNotInstalled && shouldShowNewBundleBadge) {
        return (
          <span>
            <Text size="tiny" weight="thin" skin="success">
              {t('solution.page.freeBundleNotInstalled')}
            </Text>
          </span>
        );
      }
      if (isBundleAppInstalled) {
        return (
          <span className={style.bundleBadge}>
            <StatusCompleteFilledSmall className={style.installedIcon} />
            <Text
              size={textSize || 'tiny'}
              weight={textWeight || 'thin'}
              skin="success"
              dataHook="installed-text"
            >
              {t('solutionBox.solutionBottomActionBar.BundleInstalled')}
            </Text>
          </span>
        );
      }
      if (showPremiumLabel) {
        return (
          <div className={style.premiumBadge}>
            {premiumTheme ? (
              <Box color="P10">{textSize ? <Premium /> : <PremiumSmall />}</Box>
            ) : (
              <>{textSize ? <Premium /> : <PremiumSmall />}</>
            )}
            <Text
              size={textSize || 'tiny'}
              weight={textWeight || 'thin'}
              skin={premiumTheme || 'standard'}
              secondary
              className={style.premiumText}
            >
              {t('app.pricing.Premium')}
            </Text>
          </div>
        );
      }
      if (isAppSetupIncomplete) {
        return (
          <Popover
            showArrow={true}
            appendTo="window"
            placement="right"
            theme="dark"
            shown={isPopoverShown}
            onMouseEnter={() => {
              if (shouldShowIncompleteSetupPopover) {
                setIsPopoverShown(true);
              }
            }}
            onMouseLeave={() => {
              if (shouldShowIncompleteSetupPopover) {
                setIsPopoverShown(false);
              }
            }}
          >
            <Popover.Element>
              <Box verticalAlign="middle">
                <StatusWarningFilledSmall color="#fdb10c" />
                <Box marginLeft="4px">
                  <Text
                    skin="standard"
                    secondary={true}
                    weight={textWeight || 'thin'}
                    size={textSize || 'tiny'}
                  >
                    {t('solutionBox.solutionBottomActionBar.IncompleteSetup')}
                  </Text>
                </Box>
              </Box>
            </Popover.Element>
            <Popover.Content>
              <Box padding="12px 24px" maxWidth="102px">
                <Text
                  skin="standard"
                  light
                  weight={textWeight || 'thin'}
                  size={textSize || 'small'}
                >
                  {t(
                    'solutionBox.solutionBottomActionBar.IncompleteSetupTooltip',
                  )}
                </Text>
              </Box>
            </Popover.Content>
          </Popover>
        );
      }
      if (isInstalled) {
        return (
          <span className={style.centeredView}>
            <StatusCompleteFilledSmall className={style.installedIcon} />
            <Text
              size={textSize || 'tiny'}
              weight={textWeight || 'thin'}
              skin="success"
              dataHook="installed-text"
            >
              {t('solutionBox.solutionBottomActionBar.installedSolution')}
            </Text>
          </span>
        );
      }

      return (
        <Text
          size={textSize || 'tiny'}
          weight={textWeight || 'thin'}
          skin="standard"
          secondary
          dataHook="business-model-text"
          className={style.centeredView}
        >
          {t(priceLabel, {
            trialDays: pricing?.freeTrialDays,
            price: pricing?.lowestMonthlyPrice,
            fullPrice: seeFullPrices
              ? currencyToString(getLowestPrice(pricing))
              : pricing?.lowestMonthlyPrice
              ? currencyToString(pricing?.lowestMonthlyPrice)
              : '$0',
          })}
        </Text>
      );
    },
  );
