import s from '../pages.scss';
import React, { useEffect } from 'react';
import { Page } from '@wix/design-system';
import { SubCategoryAppsSection } from './sub-category-apps-section';
import { SubCategoryPageHeader } from './sub-category-header';
import { appMarketPageStartLoadedSrc24Evid163 } from '@wix/bi-logger-app-market-data/v2';
import { AppMarketPage } from '../../enums';
import { useEssentials } from '../../contexts';
import { withSaleBanner } from '../../components/sale-banner';

export interface SubCategoryPageProps {
  slug: string;
  parentSlug: string;
}

export const SubCategoryPage = withSaleBanner(function ({
  slug,
  parentSlug,
}: SubCategoryPageProps) {
  const { biLogger } = useEssentials();

  useEffect(() => {
    biLogger.report(
      appMarketPageStartLoadedSrc24Evid163({
        tag_type: AppMarketPage.SUB_CATEGORY,
      }),
    );
  }, [slug, parentSlug]);

  return (
    <Page className={`${s.appMarketPage} ${s.appMarketPageBackgroundColor}`}>
      <Page.Content>
        <SubCategoryPageHeader slug={slug} parentSlug={parentSlug} />
        <SubCategoryAppsSection slug={slug} />
      </Page.Content>
    </Page>
  );
});
