import type { Plan as PlanV2Type } from '@wix/ambassador-devcenter-plans-v1-plan/types';
import type { PricingPlan, PricingPlans } from './types';
import { Plan } from './plan';

export class PlansByAppId {
  constructor(private readonly plansV2: PlanV2Type[]) {}

  get appId(): string {
    const appId = this.plansV2?.[0]?.appId ?? '';
    return appId;
  }

  get hasDynamicPlan(): boolean {
    return (
      this.plansV2?.some((plan) =>
        plan.payment?.some((payment) => Boolean(payment.customPaymentTitle)),
      ) ?? false
    );
  }

  get plans(): PricingPlan[] {
    return (
      this.plansV2
        ?.filter((_plan) => new Plan(_plan).isVisible)
        .map((_plan) => new Plan(_plan).toJSON()) ?? []
    );
  }

  toJSON(): PricingPlans {
    return {
      appId: this.appId,
      plans: this.plans,
      hasDynamicPlan: this.hasDynamicPlan,
    };
  }
}
