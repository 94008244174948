import React from 'react';
import s from './reviews-section-header.module.scss';
import { Text, TextButton, Box } from '@wix/design-system';
import { useEssentials } from '../../../../contexts/essentials-context';

export function DeleteReviewMessage() {
  const { t } = useEssentials();

  return (
    <Box direction="vertical">
      <Box className={s.reviewRemoved} align="right">
        <Text>{t('solution.page.review.deletedText')}</Text>
      </Box>
      <Box gap="2px">
        <Text>{t('solution.page.review.please')}</Text>
        <TextButton
          as="a"
          href="https://support.wix.com/en/article/reasons-an-app-review-was-removed"
          target="_blank"
        >
          {t('solution.page.reviews.block-helper-cta2')}
        </TextButton>
      </Box>
    </Box>
  );
}
