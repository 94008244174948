import React from 'react';
import { AppIcon } from '../../../app-icon';
import style from './app-promotion-image.module.scss';
import { Box, Proportion, Image } from '@wix/design-system';
import type { AppBadge } from '@wix/app-market-services';
import AppBadges from '../../../badges/app-badges';

export function AppPromotionImage({
  iconUrl,
  appName,
  promotionalImage,
  isInstalled,
  appBadges = [],
}: {
  iconUrl: string;
  appName: string;
  promotionalImage: string;
  isInstalled: boolean;
  appBadges?: AppBadge[];
}) {
  return (
    <div className={style.imagesStack}>
      <Proportion aspectRatio="1.5">
        <Box position="absolute" top="10px" right="12px" gap="6px">
          <AppBadges
            isInstalled={isInstalled}
            appName={appName}
            showBorder={true}
            appBadges={appBadges}
            size="medium"
          />
        </Box>
        <Image
          alt={`${appName} icon`}
          src={promotionalImage}
          borderRadius="8px 8px 0px 0px"
          style={{ height: 'inherit' }}
          className={style.img}
        />
      </Proportion>
      <Box
        height="42px"
        width="42px"
        position="absolute"
        bottom="-15px"
        left="15px"
        borderRadius="6px"
        background="#ffffff"
        dataHook="boxi"
      >
        <AppIcon appName={appName} iconUrl={iconUrl} className={style.icon} />
      </Box>
    </div>
  );
}
