import * as React from 'react';
import s from './mobile-menu.scss';
import { Divider, Text, Collapse } from '@wix/design-system';
import { X, ChevronRightLarge } from '@wix/wix-ui-icons-common';
import { httpClient } from '../../http-client';
import { AppMarketLink } from '../app-market-link';
import { config } from '../../config';
import { OriginQueryParam, Path } from '../../enums/marketplace-enums';
import { MobileMenuUserDetails } from './mobile-menu-user-details/mobile-menu-user-details';
import { MobileMenuLanguages } from './MobileMenuLanguages/mobile-menu-languages';
import { composer } from '../../component-decorators/composer';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';
import { useConfigContext } from '../../config-context';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';

export interface IMobileMenuProps
  extends Partial<InjectedExperimentsProps>,
    Partial<WithTranslationProps> {
  toggleMenu(): void;
  isMenuOpen: boolean;
}

export const MobileMenu = composer()
  .withTranslation()
  .compose(({ toggleMenu, isMenuOpen, t }: IMobileMenuProps) => {
    const { locale } = useConfigContext();

    return (
      <>
        <div
          className={`${s.mobileMenu} ${isMenuOpen ? s.open : ''}`}
          data-testid="mobile-menu-container"
        >
          <div className={s.innerContainer}>
            <div className={s.topSection}>
              <a href="/app-market">
                <img
                  data-testid="wix-logo"
                  alt="wix logo"
                  src={`${config.marketplaceStaticsUrl}assets/images/wix-app-market-new-logo.svg`}
                />
              </a>
              <X size="30px" onClick={toggleMenu} />
            </div>
            <MobileMenuSections closeMenu={toggleMenu} locale={locale} t={t} />
            <MobileMenuLanguages />
            <Divider />
            <div className={s.userDetailsContainer}>
              <MobileMenuUserDetails />
            </div>
          </div>
        </div>
      </>
    );
  });

class MobileMenuSections extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      openIndex: -1,
      sections: [],
    };
  }

  componentDidMount() {
    const baseURl = 'marketplace-api/v1';

    httpClient
      .get(
        `${config.baseApiUrl}/_api/${baseURl}/container?platformName=${OriginQueryParam.STANDALONE}&containerName=menu&lang=${this.props.locale}`,
      )
      .then(({ data }) => {
        this.setState({ sections: data?.container?.sections, openIndex: 0 });
      });
  }

  toggleSection = (i) => () => {
    this.setState((prevState) => ({
      openIndex: prevState.openIndex === i ? -1 : i,
    }));
  };

  isSectionOpen = (i) => i === this.state.openIndex;

  render() {
    return this.state.sections.map(
      ({ id, type, sectionData: { tagData } }, i) => (
        <React.Fragment key={`fragment-${tagData.displayName}`}>
          <Divider key={`divider-${tagData.displayName}`} />
          <div
            key={tagData.displayName}
            className={`${s.sectionContainer} ${
              this.isSectionOpen(i) ? s.openSection : ''
            }`}
          >
            <span
              className={s.mobileMenuSectionTop}
              onClick={this.toggleSection(i)}
            >
              <Text weight="bold" size="small">
                {tagData.displayName}
              </Text>
              <ChevronRightLarge className={s.arrow} />
            </span>
            <SectionLinks
              closeMenu={this.props.closeMenu}
              type={type}
              links={tagData.tagsToWebSolutions}
              isOpen={this.isSectionOpen(i)}
            />
          </div>
        </React.Fragment>
      ),
    );
  }
}

const SectionLinks = ({ isOpen, links, type, closeMenu }) => {
  const getPath = () => {
    if (type === 'categoriesList') {
      return Path.CATEGORY;
    }
    return Path.COLLECTION;
  };

  return (
    <Collapse open={isOpen}>
      <ul className={s.links}>
        {links.map(({ tag }) => (
          <li key={tag.name}>
            <AppMarketLink
              to={`${getPath()}/${tag.slug}`}
              onClick={() => {
                config.goto({
                  path: getPath(),
                  slug: tag.slug,
                  query: 'limit=200',
                });
                closeMenu();
              }}
            >
              <Text weight="normal" size="small">
                {tag.name}
              </Text>
            </AppMarketLink>
          </li>
        ))}
      </ul>
    </Collapse>
  );
};
