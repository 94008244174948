import React from 'react';
import { TextButton } from '@wix/design-system';
import style from '../../app-box.module.scss';

export interface CTAButtonProps {
  onClick?(): void;
  children?: React.ReactNode;
}

export function CTAButton({ onClick, children }: CTAButtonProps) {
  return (
    <TextButton
      className={style.ctaButton}
      size="tiny"
      weight="normal"
      onClick={(e) => {
        e.stopPropagation();
        onClick?.();
      }}
    >
      {children}
    </TextButton>
  );
}
