export function deepCompare<T1, T2>(
  obj1: T1,
  obj2: T2,
  excludedFields: (keyof T1 | keyof T2 | any)[] = [],
): boolean {
  // @ts-expect-error
  if (obj1 === obj2) {
    return true;
  }

  if (
    obj1 === null ||
    obj2 === null ||
    typeof obj1 !== 'object' ||
    typeof obj2 !== 'object' ||
    Array.isArray(obj1) !== Array.isArray(obj2)
  ) {
    return false;
  }

  // Handle Date objects
  if (obj1 instanceof Date && obj2 instanceof Date) {
    return obj1.getTime() === obj2.getTime();
  }

  // Handle Arrays
  if (Array.isArray(obj1) && Array.isArray(obj2)) {
    if (obj1.length !== obj2.length) {
      return false;
    }
    for (let i = 0; i < obj1.length; i++) {
      if (!deepCompare(obj1[i], obj2[i], excludedFields)) {
        return false;
      }
    }
    return true;
  }

  // Get keys and filter out excluded fields
  const keys1 = Object.keys(obj1).filter(
    (key) => !excludedFields.includes(key as keyof T1),
  ) as (keyof T1)[];
  const keys2 = Object.keys(obj2).filter(
    (key) => !excludedFields.includes(key as keyof T2),
  ) as (keyof T2)[];

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (!(key in obj2)) {
      return false;
    }
    // Perform deep comparison for non-excluded fields
    // @ts-expect-error
    if (!deepCompare(obj1[key], obj2[key], excludedFields)) {
      return false;
    }
  }

  return true;
}
