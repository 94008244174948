import React from 'react';
import type { GridProps } from '../../components/grid';
import { Grid } from '../../components/grid';
import { Box, SkeletonGroup } from '@wix/design-system';
import { AppBoxSkeleton } from '../../components/app-box/app-box-skeleton';

export interface AppGridSkeletonProps {
  boxCount: number;
  columns?: GridProps['columns'];
  minColumns?: GridProps['minColumns'];
  dataHook?: string;
}

export function AppGridSkeleton({
  boxCount,
  columns = 4,
  minColumns,
  dataHook,
}: AppGridSkeletonProps) {
  return (
    <Box width="100%" direction="vertical">
      <Grid
        dataHook={dataHook ?? 'app-grid-skeleton'}
        columns={columns}
        minColumns={minColumns}
      >
        {Array.from({ length: boxCount }).map((_value, index) => (
          <SkeletonGroup>
            <AppBoxSkeleton key={index} />
          </SkeletonGroup>
        ))}
      </Grid>
    </Box>
  );
}
