import React from 'react';
import { Box, Loader } from '@wix/design-system';
import type { Options } from '../../components/app-box';
import { Grid } from '../../components/grid';
import { AppBox } from '../../components/app-box';
import { withEssentialsContext } from '../../contexts/essentials-context';
import type {
  ExternalComponentProps,
  ComponentsWithSiteContextProps,
} from '../types';
import { VisibilityDetector } from '../visibility-detector';
import { v4 as generateUuidV4 } from 'uuid';
import { withSiteContext } from '../../contexts/site-context';
import type { BaseApp } from '../../types';

export interface AppGridProps
  extends ExternalComponentProps,
    ComponentsWithSiteContextProps {
  dataHook?: string;
  apps: BaseApp[];
  columns: 1 | 2 | 3 | 4;
  minColumns?: 1 | 2 | 3 | 4;
  rows?: number;
  isLoading?: boolean;
  options?: Options;
  onAppClick?(app: BaseApp, appIndex: number, impressionId: string): void;
  onAppLoad?(app: BaseApp, appIndex: number, impressionId: string): void;
  getHref?(appSlug: string, appIndex: number): string | undefined;
  showBorder?: boolean;
  finalColumnsCount?: (finalColumns: number) => void;
}

function AppGridComponent({
  dataHook,
  apps,
  columns,
  minColumns,
  rows,
  isLoading,
  onAppClick,
  options,
  onAppLoad,
  getHref,
  showBorder,
  finalColumnsCount,
}: AppGridProps) {
  if (isLoading) {
    return (
      <Box width="100%" align="center" padding="48px">
        <Loader dataHook="loader" />
      </Box>
    );
  }

  return (
    <Grid
      dataHook={dataHook}
      columns={columns}
      rows={rows}
      minColumns={minColumns}
      finalColumnsCount={finalColumnsCount}
    >
      {apps.map((app, index) => {
        const impressionId = generateUuidV4();
        return (
          <VisibilityDetector
            onFirstVisible={() => {
              app && onAppLoad?.(app, index, impressionId);
            }}
            key={index}
          >
            <AppBox
              appData={app}
              options={{
                showBadgesVertical: columns !== 1,
                showBorder,
                ...options,
              }}
              onClick={() => onAppClick?.(app, index, impressionId)}
              href={getHref?.(app.slug!, index)}
            />
          </VisibilityDetector>
        );
      })}
    </Grid>
  );
}

export const AppGrid = withSiteContext(withEssentialsContext(AppGridComponent));
