import React from 'react';
import type { FC, ComponentType } from 'react';
import type { ComponentsWithSiteContextProps } from '../exported-components/types';

export interface Site {
  isPremiumSite: boolean;
}

const SiteContext = React.createContext<Site>({ isPremiumSite: false });

interface SiteContextProps {
  site: Site;
  children: React.ReactNode;
}

export const SiteContextProvider: FC<SiteContextProps> = ({
  site,
  children,
}) => <SiteContext.Provider value={site}>{children}</SiteContext.Provider>;

export function withSiteContext<Props extends ComponentsWithSiteContextProps>(
  Component: ComponentType<Props>,
) {
  return (props: Props) => {
    const { isPremiumSite } = props;

    return (
      <SiteContextProvider site={{ isPremiumSite }}>
        <Component {...props} />
      </SiteContextProvider>
    );
  };
}

export const useSiteContext = () => {
  const siteContext = React.useContext(SiteContext);
  if (!siteContext) {
    throw new Error('useSiteContext must be used within a SiteContextProvider');
  }
  return siteContext;
};
