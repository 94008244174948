import { type IRoute, RoutePath } from '../../models';

export const sidebarReferral = 'menu_navigation';

interface ConstantSidebarKeys {
  [RoutePath.HOME]: string;
  [RoutePath.SEARCH_RESULTS]: string;
  [RoutePath.MANAGE_APPS]: string;
  [RoutePath.UNSUPPORTED]: string;
}

export const constantSidebarKeys: ConstantSidebarKeys = {
  [RoutePath.HOME]: 'home',
  [RoutePath.SEARCH_RESULTS]: 'search-result',
  [RoutePath.MANAGE_APPS]: 'manage-apps',
  [RoutePath.UNSUPPORTED]: 'unsupported',
};

export enum ESidebarItemTypes {
  DIVIDER = 'divider',
  TITLE = 'title',
  LINK = 'link',
  SUB_MENU = 'sub-menu',
}

export interface ConstantSidebarItemData {
  key: string;
  labelTransKey: string;
  route: IRoute;
}

export const constantSidebarItemData: ConstantSidebarItemData[] = [
  {
    key: constantSidebarKeys[RoutePath.HOME],
    labelTransKey: 'sidebar.home',
    route: {
      path: RoutePath.HOME,
      payload: {
        referral: sidebarReferral,
      },
    } as IRoute,
  },
];

export const manageAppsItemData: ConstantSidebarItemData = {
  key: constantSidebarKeys[RoutePath.MANAGE_APPS],
  labelTransKey: 'sidebar.manage-apps',
  route: {
    path: RoutePath.MANAGE_APPS,
    payload: undefined,
  } as IRoute,
};
