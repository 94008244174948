import type {
  AppGroupSection,
  Services,
  QueryAppsFilter,
} from '@wix/app-market-services';
import { APPS_LIMIT, AppGroupType } from '@wix/app-market-services';
import type { AppMarketPage } from '../../enums';
import type { QueryAppsResponse } from '../../components/apps-section';

export interface SearchResultsPageData {
  searchTerm: string;
  appGroup: AppGroupSection;
  paging: {
    total: number;
    hasNext: boolean;
  };
}

export async function queryApps({
  searchTerm,
  filter,
  services,
  offset = 0,
  pageName,
}: {
  services: Services;
  searchTerm: string;
  pageName: AppMarketPage;
  offset?: number;
  filter?: QueryAppsFilter;
}): Promise<QueryAppsResponse> {
  const { appIds, hasMoreApps, totalApps } = await services.searchApps({
    searchTerm,
    paging: {
      limit: APPS_LIMIT,
      offset,
    },
    filter,
  });
  const appsData = await services.getAppsByAppIds({
    appIds,
    status: 'PUBLISHED',
  });
  const apps = appsData.toJSON();

  return {
    appGroup: {
      type: AppGroupType.COLLECTION,
      tagSlug: pageName,
      apps: apps as AppGroupSection['apps'],
      title: '',
      totalAppsCount: 0,
    },
    paging: {
      total: totalApps,
      hasNext: hasMoreApps,
    },
  };
}

export async function getSearchResultsPageData({
  searchTerm,
  services,
  isSale,
}: {
  services: Services;
  searchTerm: string;
  isSale: boolean;
}): Promise<SearchResultsPageData> {
  const { appIds, hasMoreApps, totalApps } = await services.searchApps({
    searchTerm,
    paging: {
      limit: APPS_LIMIT,
      offset: 0,
    },
    filter: { isSale },
  });
  const appsData = await services.getAppsByAppIds({
    appIds,
    status: 'PUBLISHED',
  });

  const apps = appsData.toJSON();

  return {
    searchTerm,
    appGroup: {
      type: AppGroupType.COLLECTION,
      tagSlug: searchTerm,
      apps: apps as AppGroupSection['apps'],
      title: '',
      totalAppsCount: 0,
    },
    paging: {
      total: totalApps,
      hasNext: hasMoreApps,
    },
  };
}
