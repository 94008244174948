import React from 'react';
import { SectionHelper, Text } from '@wix/design-system';
import {
  appMarketAppPageOneDependencyImpression,
  appMarketAppPageOneDependencyClick,
} from '@wix/bi-logger-app-market-data/v2';

import { useTranslation } from '../../../../../contexts/translations-context';
import { useEssentials, useSiteContext } from '../../../../../contexts';
import { PremiumSiteRequiredNotificationDataHooks } from './data-hooks';

export interface PremiumSiteRequiredNotificationProps {
  app: {
    id: string;
    name: string;
  };
}

export function PremiumSiteRequiredNotification({
  app,
}: PremiumSiteRequiredNotificationProps) {
  const { t } = useTranslation();
  const siteContext = useSiteContext();
  const { biLogger } = useEssentials();

  const openWixPackagePicker = () => {
    window.open(
      `https://premium.wix.com/wix/api/premiumStart?siteGuid=${siteContext.metaSiteId}&referralAdditionalInfo=marketplace`,
    );
  };

  React.useEffect(() => {
    biLogger.report(
      appMarketAppPageOneDependencyImpression({
        app_id: app.id,
        type: 'upgrade',
      }),
    );
  }, []);

  return (
    <SectionHelper
      dataHook={PremiumSiteRequiredNotificationDataHooks.container}
      appearance="premium"
      fullWidth={true}
      actionText={t('app.page.premium.required.notification.cta')}
      onAction={() => {
        biLogger.report(
          appMarketAppPageOneDependencyClick({
            app_id: app.id,
            click_type: 'upgrade_site',
            type: 'upgrade',
          }),
        );
        openWixPackagePicker();
      }}
      title={t('app.page.premium.required.notification.title', {
        appName: app.name,
        interpolation: { escapeValue: false },
      })}
      size="small"
    >
      <Text
        dataHook={PremiumSiteRequiredNotificationDataHooks.description}
        size="small"
        weight="thin"
      >
        {t('app.page.premium.required.notification.subtitle')}
      </Text>
    </SectionHelper>
  );
}
