import type {
  appMarketAppCollectionImpressionsParams,
  appMarketPageViewParams,
  appModalOpenAppModalClickParams,
  categoryClickParams,
  appMarketAppImpressionParams,
  appMarketWidgetsNavigationClickParams,
  appMarketWidgetsClickActionParams,
  addAppClickParams,
  appMarketAppInstallationSuccessParams,
  appMarketAppInstallationFailedClientParams,
  openAppClickParams,
  appmarketPackagePickerToggleCycleClickParams,
  appMarketAppPageClickParams,
} from '@wix/bi-logger-app-market-data/v2/types';

import {
  appBadgesToString,
  convertStringToBi,
  getPathForBi,
  getRouteBiData,
  parseNumberToBi,
  saleDiscountAmount,
} from './utils';
import type {
  getAppCollectionImpressionsBiDataParams,
  getAppMarketAppImpressionBiDataParams,
  getAddAppClickBiDataParams,
  getAppMarketAppInstallationSuccessBiDataParams,
  getAppMarketAppInstallationFailedClientBiDataParams,
  getAppMarketWidgetsNavigationClickBiDataParams,
  getAppModalOpenAppModalClickBiDataParams,
  getCategoryClickBiDataParams,
  getPageViewBiDataParams,
  getWidgetClickBiDataParams,
  getOpenAppClickBiDataParams,
  getAppMarketPackagePickerToggleCycleClickBiDataParams,
  getAppMarketAppPageClickBiDataParams,
  getAppPageViewBiDataParams,
} from './types';
import { isBundleApp } from '@wix/app-market-services';

export const getAppCollectionImpressionsBiData = ({
  appGroup,
  sectionIndex,
  collimpId,
  pageType,
  pageName,
}: getAppCollectionImpressionsBiDataParams): appMarketAppCollectionImpressionsParams => {
  const appIds = appGroup?.apps.map((app) => app.id);
  const appIdsList = appIds?.join(',');
  return {
    apps_list: appIdsList,
    number_of_apps: appIds?.length,
    collimp_id: collimpId,
    tag_name: appGroup?.tagSlug,
    tag_type: pageType,
    collection_index: sectionIndex,
    page_name: pageName,
  };
};

export const getPageViewBiData = ({
  routerData,
  pageType,
  pageName,
  subCategories,
}: getPageViewBiDataParams): appMarketPageViewParams => {
  return {
    navigation_source: routerData?.route?.referral || 'first_load',
    tag_type: pageType,
    tag_name: pageName,
    page_name: pageName,
    slug_index_list: '',
    section: '',
    referral_sub_tag_name: routerData?.prevRoute?.subCategories?.join(','),
    sub_tag_name: subCategories?.join(','),
  };
};

export const getAppPageViewBiData = ({
  routerData,
  urlParams,
  pageType,
  appId,
  appSlug,
  appReviewCount,
  appReviewScore,
  numberOfDependedApps,
  searchTerm,
  appBadges,
  referrerNameForBI,
}: getAppPageViewBiDataParams): appMarketPageViewParams => {
  const { route, prevRoute } = routerData;
  const { referralInfo } = getRouteBiData(route, prevRoute, referrerNameForBI);
  return {
    ...getPageViewBiData({
      routerData,
      urlParams,
      pageType,
    }),
    ...{
      app_id: appId,
      slug_id: appSlug,
      app_review_count: appReviewCount,
      app_review_score: parseNumberToBi(appReviewScore),
      is_app_dependency: numberOfDependedApps > 0,
      number_of_depended_apps: numberOfDependedApps,
      search_term: searchTerm,
      badge_name: appBadgesToString(appBadges),
      sale_discount: saleDiscountAmount(appBadges),
      referral_info: referralInfo,
      referral_section_name:
        routerData.route?.referralSectionName ?? routerData.route?.referral,
    },
  };
};

export const getAppMarketAppImpressionBiData = ({
  impressionType,
  tagSlug,
  sectionIndex,
  collimpId,
  pageType,
  pageName,
  appId,
  appIndex,
  appReviewCount,
  appReviewScore,
  impressionId,
  appBadges,
}: getAppMarketAppImpressionBiDataParams): appMarketAppImpressionParams => {
  return {
    badge_name: appBadgesToString(appBadges),
    sale_discount: saleDiscountAmount(appBadges),
    impression_id: impressionId,
    collimp_id: collimpId,
    impression_type: impressionType === 'PROMO' ? 'promo' : 'box',
    section_index: sectionIndex,
    section: tagSlug,
    tag_type: pageType,
    tag_name: tagSlug,
    app_id: appId,
    page_name: pageName,
    index: appIndex,
    app_review_count: appReviewCount,
    app_review_score: parseNumberToBi(appReviewScore),
  };
};

export const getAppModalOpenAppModalClickBiData = ({
  tagSlug,
  tagName,
  appId,
  appIndex,
  collimpId,
  pageType,
  pageName,
  routerData,
  referrerNameForBI,
  impressionId,
  appBadges,
}: getAppModalOpenAppModalClickBiDataParams): appModalOpenAppModalClickParams => {
  const { route, prevRoute } = routerData;
  const { referralInfo, searchTerm } = getRouteBiData(
    route,
    prevRoute,
    referrerNameForBI,
  );
  const tagSlugBi = convertStringToBi(tagSlug);
  const pageTypeBi = convertStringToBi(pageType);
  return {
    app_id: appId,
    index: appIndex,
    tag_name: tagName,
    sub_tag_name: tagSlugBi,
    tag_type: pageTypeBi,
    page_name: pageName,
    section: tagSlugBi,
    referral_info: referralInfo,
    search_term: searchTerm,
    collimp_id: collimpId,
    slug_id: tagSlugBi,
    impression_id: impressionId,
    badge_name: appBadgesToString(appBadges),
    sale_discount: saleDiscountAmount(appBadges),
  };
};

export const getWidgetClickBiData = ({
  pageName,
  tagName,
  tagType,
  tagTypeClicked,
  tagSlag,
}: getWidgetClickBiDataParams): appMarketWidgetsClickActionParams => {
  return {
    tag_name: tagName,
    tag_name_clicked: convertStringToBi(tagSlag),
    tag_type: tagType,
    tag_type_clicked: tagTypeClicked,
    page_name: pageName,
  };
};

export const getCategoryClickBiData = ({
  slug,
  tagTypeClicked,
}: getCategoryClickBiDataParams): categoryClickParams => {
  const slugBi = convertStringToBi(slug);
  return {
    category: slugBi,
    origin: 'categories_carousel',
    tag_name: slugBi,
    tag_type: tagTypeClicked,
  };
};

export const getAppMarketWidgetsNavigationClickBiData = ({
  currentSlide,
  nextSlide,
  tagName,
  tagType,
}: getAppMarketWidgetsNavigationClickBiDataParams): appMarketWidgetsNavigationClickParams => {
  const direction = currentSlide < nextSlide ? 'right' : 'left';
  return {
    tag_name: tagName,
    tag_type: tagType,
    direction,
  };
};

export const getAddAppClickBiData = ({
  appId,
  tagType,
  tagName,
  routerData,
  referrerNameForBI,
  appBadges = [],
  origin,
}: getAddAppClickBiDataParams): addAppClickParams => {
  const { route, prevRoute } = routerData;
  const { referralInfo, searchTerm } = getRouteBiData(
    route,
    prevRoute,
    referrerNameForBI,
  );

  return {
    app_id: appId,
    tag_type: tagType,
    cta_type: isBundleApp(appBadges) ? 'claim_app' : 'add_app',
    tag_name: tagName,
    referral_tag_type: getPathForBi(routerData.prevRoute.path),
    search_term: searchTerm,
    referral_info: referralInfo,
    referral_section_name: routerData.route?.referralSectionName,
    referral_sub_tag_name: routerData?.prevRoute?.subCategories?.join(','),
    badge_name: appBadgesToString(appBadges),
    sale_discount: saleDiscountAmount(appBadges),
    click_location: origin,
  };
};

export const getAppMarketAppInstallationSuccessBiData = ({
  appId,
  instanceId,
  tagType,
  tagName,
  routerData,
  referrerNameForBI,
  appBadges,
  origin,
}: getAppMarketAppInstallationSuccessBiDataParams): appMarketAppInstallationSuccessParams => {
  const { route, prevRoute } = routerData;
  const { referralInfo, searchTerm } = getRouteBiData(
    route,
    prevRoute,
    referrerNameForBI,
  );

  return {
    app_id: appId,
    instance_id: instanceId,
    tag_type: tagType,
    tag_name: tagName,
    search_term: searchTerm,
    referral_info: referralInfo,
    referral_tag_type: getPathForBi(routerData.prevRoute.path),
    referral_tag_name: convertStringToBi(routerData?.prevRoute?.slug ?? ''),
    referral_sub_tag_name: routerData?.prevRoute?.subCategories?.join(','),
    referral_section_index: routerData?.route?.appIndex,
    referral_section_name: routerData.route?.referralSectionName,
    sum_one: 1,
    badge_name: appBadgesToString(appBadges),
    sale_discount: saleDiscountAmount(appBadges),
    click_location: origin,
  };
};

export const getAppMarketAppInstallationFailedClientBiData = ({
  appId,
  tagType,
  tagName,
}: getAppMarketAppInstallationFailedClientBiDataParams): appMarketAppInstallationFailedClientParams => {
  return {
    app_id: appId,
    tag_type: tagType,
    tag_name: tagName,
  };
};

export const getOpenAppClickBiData = ({
  appId,
  ctaType,
  tagType,
  tagName,
  routerData,
  referrerNameForBI,
  origin,
}: getOpenAppClickBiDataParams): openAppClickParams => {
  const { route, prevRoute } = routerData;
  const { referralInfo, searchTerm } = getRouteBiData(
    route,
    prevRoute,
    referrerNameForBI,
  );

  return {
    app_id: appId,
    cta_type: ctaType,
    tag_type: tagType,
    tag_name: tagName,
    search_term: searchTerm,
    referral_info: referralInfo,
    index: routerData?.route?.appIndex,
    click_location: origin,
  };
};

export const getAppMarketPackagePickerToggleCycleClickBiData = ({
  appId,
  cycleClick,
  numberOfPlans,
}: getAppMarketPackagePickerToggleCycleClickBiDataParams): appmarketPackagePickerToggleCycleClickParams => {
  return {
    app_id: appId,
    cycle_click: cycleClick,
    number_of_plans: numberOfPlans,
  };
};

export const getAppMarketAppPageClickBiData = ({
  appId,
  location,
  cta,
  url,
}: getAppMarketAppPageClickBiDataParams): appMarketAppPageClickParams => {
  return {
    app_id: appId,
    location,
    cta,
    url,
  };
};
