import React, { useEffect } from 'react';
import { Box, Card } from '@wix/design-system';
import { ReviewTitle } from './reviews-title';
import { ReviewBody } from './review-body';
import { ReviewReply } from './review-reply';
import { ReviewLike } from './review-like';
import type { Company } from '../../../exported-components/rate-and-reviews-section/types';
import type { ReviewType as Review } from '@wix/app-market-services';
import { useRateAndReviewSectionContext } from '../../../exported-components/rate-and-reviews-section/rate-and-reviews-section-context';

interface ReviewProps {
  index: number;
  review: Review;
  selectedSort: string;
  limit: number;
  company: Company;
}

export function Review({
  review,
  index,
  selectedSort,
  limit,
  company,
}: ReviewProps) {
  const { onLike, onUnlike, onReviewLoad, app } =
    useRateAndReviewSectionContext();

  useEffect(() => {
    onReviewLoad(app.id, review, index);
  }, []);
  return (
    <Box key={index} direction="vertical" gap="18px">
      {index !== 0 && <Card.Divider />}
      <Card key={index}>
        <ReviewTitle
          name={review.userName}
          publishedDate={review.createdAt}
          rate={review.rate}
        />
        <ReviewBody
          id={review.id}
          body={review.description}
          title={review.title}
        />
        {review.replies?.[0] && (
          <ReviewReply reply={review.replies[0] ?? ''} company={company} />
        )}
      </Card>
      <ReviewLike
        index={index}
        review={review}
        onLikeReview={() => onLike(review.id, selectedSort, limit)}
        onUnlikeReview={() =>
          onUnlike(review.id, review.userOpinion?.id ?? '', selectedSort, limit)
        }
      />
    </Box>
  );
}
