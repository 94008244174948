import { RequestService } from '../request-service';
import { Plans } from './plans';
import type { QueryPlans } from './types';
import type {
  QueryPlansRequest,
  QueryPlansResponse,
} from '@wix/ambassador-devcenter-plans-v1-plan/types';
import type RpcModule from '@wix/ambassador-devcenter-plans-v1-plan/rpc';
import type HttpModule from '@wix/ambassador-devcenter-plans-v1-plan/http';

type RpcModuleType = typeof RpcModule;
type HttpModuleType = typeof HttpModule;

export class PlansService extends RequestService<
  RpcModuleType,
  HttpModuleType
> {
  protected async rpcModule(): Promise<RpcModuleType> {
    return import('@wix/ambassador-devcenter-plans-v1-plan/rpc');
  }

  protected async httpModule(): Promise<HttpModuleType> {
    return import('@wix/ambassador-devcenter-plans-v1-plan/http');
  }

  public async query(payload: QueryPlans) {
    if (!payload.appIds.length) {
      throw new Error('appIds empty in queryPlans');
    }
    const response = await this.request<QueryPlansRequest, QueryPlansResponse>({
      methodName: 'queryPlans',
      payload: {
        query: {
          cursorPaging: { limit: 500 },
          filter: {
            $and: [
              ...(payload.appIds ? [{ appId: { $in: payload.appIds } }] : []),
              ...[{ visibleInPages: { $hasSome: 'VisibleInAppPage' } }],
              ...(payload.status ? [{ status: payload.status }] : []),
            ],
          },
        },
      },
    });
    return new Plans(response.plans || []);
  }
}
