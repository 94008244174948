import React from 'react';
import {
  ONE_DAY_IN_MS,
  ONE_HOUR_IN_MS,
  ONE_MINUTE_IN_MS,
  SECOND_IN_MS,
} from '../constants/time-units';

type RemainingTime = { days: number; hours: number; minutes: number };

export function useRemainingTime(targetDate: Date): RemainingTime | null {
  const [remainingTime, setRemainingTime] = React.useState(() =>
    calculateRemainingTime(targetDate),
  );

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      const timeLeft = calculateRemainingTime(targetDate);
      if (timeLeft === null) {
        clearInterval(intervalId);
      }
      setRemainingTime(timeLeft);
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return remainingTime;
}

export function calculateRemainingTime(targetDate: Date): RemainingTime | null {
  const remainingTimeInMs = targetDate.getTime() - Date.now();

  if (remainingTimeInMs <= 0) {
    return null;
  }

  const seconds = Math.floor((remainingTimeInMs / SECOND_IN_MS) % 60);
  let minutes = Math.floor((remainingTimeInMs / ONE_MINUTE_IN_MS) % 60);
  let hours = Math.floor((remainingTimeInMs / ONE_HOUR_IN_MS) % 24);
  let days = Math.floor(remainingTimeInMs / ONE_DAY_IN_MS);

  // if any remaining seconds, round up the minutes
  // because for example we don't want to show 0 days, 0 hours, 0 minutes if there are 59 seconds left
  if (seconds > 0) {
    minutes += 1;
  }

  // if minutes overflow to 60, reset minutes and increment hours by 1
  if (minutes === 60) {
    minutes = 0;
    hours += 1;
  }

  // if hours overflow to 24, reset hours and increment days by 1
  if (hours === 24) {
    hours = 0;
    days += 1;
  }

  return { days, hours, minutes };
}
