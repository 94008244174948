import type { Services } from '@wix/app-market-services';
import type { Subcategory } from '../categories-tags';

export type getSubCategoriesResponse = {
  subCategories: Subcategory[];
};
export async function getSubCategories({
  services,
  appId,
  languageCode,
}: {
  appId: string;
  services: Services;
  languageCode: string;
}): Promise<getSubCategoriesResponse> {
  const tagsResponse = await services.queryTagApp({ appIds: [appId] });
  const subcategories = await services.queryTag({
    tagIds: tagsResponse.tagIds,
    tagType: 'SUBCATEGORY',
    isHidden: false,
    languageCode,
  });
  const subcategoriesIds = subcategories.ids;
  const subcategoriesNames = subcategories.names;
  const subcategoriesSlugs = subcategories.slugs;
  const subcategoriesParentsIds = subcategories.parentIds;
  const categoriesSlugs = await services.queryTag({
    tagIds: subcategoriesParentsIds,
    tagType: 'CATEGORY',
    isHidden: false,
    languageCode,
  });
  const subcategoriesArrayMap = subcategoriesIds.map((id, index) => ({
    id,
    slug: subcategoriesSlugs[index],
    name: subcategoriesNames[index],
    parentSlug: categoriesSlugs.findByIndex(index)?.slug ?? '',
    parentName: categoriesSlugs.findByIndex(index)?.name ?? '',
  }));

  return {
    subCategories: subcategoriesArrayMap,
  };
}
