import React from 'react';
import { Box, InfoIcon, Text, TextButton } from '@wix/design-system';
import {
  appMarketSiteRequirementsTagClick,
  appMarketAppPageHover,
} from '@wix/bi-logger-app-market-data/v2';
import { useTranslation } from '../../../../contexts/translations-context';
import { useEssentials, useRouterContext } from '../../../../contexts';
import { AppPropertiesDataHooks } from './data-hooks';
import { RoutePath } from '../../../../models';

export interface AppRequirementsProps {
  appId: string;
  appDependencies: {
    id: string;
    slug: string;
    name: string;
  }[];
}

export const AppRequirements = ({
  appId,
  appDependencies,
}: AppRequirementsProps) => {
  const { t } = useTranslation();
  const { router } = useRouterContext();
  const { biLogger } = useEssentials();

  const navigateToDependencyAppPage = (appDependency: {
    id: string;
    slug: string;
  }) => {
    router.navigateTo({
      path: RoutePath.APP_PAGE,
      payload: {
        slug: appDependency.slug,
        referral: `app-dependency-${appDependency.id}`,
      },
    });
  };

  return (
    <Box
      dataHook={AppPropertiesDataHooks.siteRequirementsContainer}
      direction="vertical"
      gap="6px"
    >
      <Box gap={1}>
        <Text size="small" weight="bold">
          {t('solution.page.tagsBoxSiteReq')}
        </Text>
        <InfoIcon
          dataHook={AppPropertiesDataHooks.appRequirementsTooltip}
          tooltipProps={{
            onShow: () => {
              biLogger.report(
                appMarketAppPageHover({
                  app_id: appId,
                  location: 'properties',
                  hover_type: 'site_requirements',
                }),
              );
            },
          }}
          size="small"
          content={t('solution.page.tagsBoxSiteReqTooltip')}
        />
      </Box>

      <Box gap="6px">
        <Box>-</Box>
        <Box flexWrap="wrap">
          {appDependencies.map((appDependency, index) => {
            return (
              <Box key={appDependency.id} gap={1}>
                <TextButton
                  dataHook={`${AppPropertiesDataHooks.appDependency}-${index}`}
                  onClick={() => {
                    biLogger.report(
                      appMarketSiteRequirementsTagClick({
                        app_id: appId,
                        required_app_id: appDependency.id,
                      }),
                    );
                    navigateToDependencyAppPage(appDependency);
                  }}
                  size="small"
                >
                  {appDependency.name}
                </TextButton>
                {index < appDependencies.length - 1 && (
                  <span style={{ display: 'inline-block' }}>/&nbsp;</span>
                )}
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
