import type {
  AnyObject,
  MarketWatchConfigs,
  Transaction,
  ComponentMonitor,
} from './types';
import { MarketWatchPlatform } from './types';
import {
  PanoramaPlatform,
  panoramaClientFactory,
  createGlobalConfig,
} from '@wix/panorama-client-web';

type PanoramaClientFactory = typeof panoramaClientFactory;

export const panoramaGlobalConfig = createGlobalConfig();

export class MarketWatch {
  private globalParams: AnyObject = {};
  private componentMonitors: Map<string, ComponentMonitor> = new Map();
  private panoramaFactory: ReturnType<PanoramaClientFactory>;

  constructor(configs: MarketWatchConfigs) {
    const isSSR = typeof window === 'undefined';

    const {
      platform: marketWatchPlatform,
      fullArtifactId,
      artifactVersion,
      sentryDsn,
    } = configs;

    this.panoramaFactory = panoramaClientFactory({
      baseParams: {
        platform:
          this.marketWatchPlatformToPanoramaPlatform(marketWatchPlatform),
        fullArtifactId,
        artifactVersion,
      },
      pluginParams: {
        ...(!isSSR && {
          sentry: window.Sentry,
          sentryDsn,
          sentryMain: marketWatchPlatform === MarketWatchPlatform.Standalone,
        }),
        useBatch: !isSSR,
      },
      ...(isSSR && {
        reporterOptions: {
          fetchFn: fetch,
        },
      }),
    }).withGlobalConfig(panoramaGlobalConfig);
  }

  updateGlobalParams(params: AnyObject): void {
    this.globalParams = {
      ...this.globalParams,
      ...params,
    };
  }

  monitor(componentId: string): ComponentMonitor {
    if (!this.isValidComponentId(componentId)) {
      throw new Error(
        `Invalid componentId: ${componentId}, componentId should be in kebab-case`,
      );
    }

    const componentMonitor = this.componentMonitors.get(componentId);
    if (componentMonitor) {
      return componentMonitor;
    }

    const newComponentMonitor = this.createComponentMonitor(componentId);
    this.componentMonitors.set(componentId, newComponentMonitor);
    return newComponentMonitor;
  }

  private createComponentMonitor(componentId: string): ComponentMonitor {
    const panoramaClient = this.panoramaFactory.client({
      baseParams: {
        componentId,
      },
    });

    let loadStartAlreadyCalled = false;
    let loadFinishAlreadyCalled = false;

    return {
      reportLoadStart: (params?: AnyObject) => {
        if (!loadStartAlreadyCalled) {
          loadStartAlreadyCalled = true;
          panoramaClient.reportLoadStart({ ...this.globalParams, ...params });
        }
      },
      reportLoadFinish: (params?: AnyObject) => {
        if (!loadFinishAlreadyCalled) {
          loadFinishAlreadyCalled = true;
          panoramaClient.reportLoadFinish({ ...this.globalParams, ...params });
        }
      },
      reportError: (error: Error, params?: AnyObject) => {
        panoramaClient.errorMonitor().reportError(error, {
          ...this.globalParams,
          ...params,
        });
      },
      transaction: (name: string, params?: AnyObject): Transaction => {
        const transaction = panoramaClient.transaction(name);
        const transactionParams = { ...this.globalParams, ...params };

        return {
          start: (startParams: AnyObject) => {
            transaction.start({ ...transactionParams, ...startParams });
          },
          finish: (finishParams: AnyObject) => {
            transaction.finish({ ...transactionParams, ...finishParams });
          },
        };
      },
    };
  }

  private isValidComponentId(componentId: string): boolean {
    const lowercaseKebabCaseRegex = /^[a-z]+(-[a-z]+)*$/;
    return lowercaseKebabCaseRegex.test(componentId);
  }

  private marketWatchPlatformToPanoramaPlatform(
    platform: MarketWatchPlatform,
  ): PanoramaPlatform {
    switch (platform) {
      case MarketWatchPlatform.BusinessManager:
        return PanoramaPlatform.BusinessManager;
      case MarketWatchPlatform.Standalone:
        return PanoramaPlatform.Standalone;
      default:
        throw new Error(`Unsupported platform: ${platform}`);
    }
  }
}
