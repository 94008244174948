import {
  SkeletonLine,
  SkeletonRectangle,
  Box,
  SkeletonGroup,
} from '@wix/design-system';
import React from 'react';

export function CollectionHeaderSkeleton() {
  return (
    <SkeletonGroup skin="light">
      <Box dataHook="skeleton-header" marginTop="24px" direction="vertical">
        <Box dataHook="back-button-skeleton" marginLeft="4px">
          <SkeletonLine width="50px" />
        </Box>
        <Box dataHook="skeleton-titles" marginTop="20px" direction="vertical">
          <SkeletonRectangle width="175px" height="18px" />
          <SkeletonRectangle width="638px" height="30px" marginTop="6px" />
          <SkeletonLine width="540px" marginTop="12px" />
        </Box>
      </Box>
    </SkeletonGroup>
  );
}
