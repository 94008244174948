import { ErrorPageHeader } from './error-page-header';
import React, { useEffect } from 'react';
import s from './error-page.scss';
import { CategoriesCarousel } from '../CategoriesCarousel';
import { categoriesCarouselProps } from '../developer-page-container/categoriesCarouselProps';
import { FluidContainer } from '../FluidContainer';
import { composer } from '../../component-decorators/composer';
import { useConfigContext } from '../../config-context';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { ErrorPageType } from '../..';
import { StatusCode } from '../../enums/marketplace-enums';
import { biLogger } from '../../bi';
import { errorPageLoaded } from '@wix/bi-logger-app-market-data/v2';

export interface IErrorPageProps extends Partial<WithTranslationProps> {
  appName?: string;
  errorPageType: ErrorPageType;
  categories: any;
}

export const ErrorPage = composer()
  .withTranslation()
  .compose(({ appName, categories, errorPageType, t }: IErrorPageProps) => {
    const configContext = useConfigContext();
    const isNotFoundError = () => {
      return (
        configContext &&
        configContext.responseCode &&
        errorPageType !== ErrorPageType.INTERNAL_SERVER
      );
    };
    if (isNotFoundError()) {
      configContext.responseCode(StatusCode.NOT_FOUND);
    }

    useEffect(() => {
      biLogger.report(
        errorPageLoaded({
          error_type: errorPageType,
        }),
      );
    }, []);

    return (
      <>
        <FluidContainer className={s.container}>
          <ErrorPageHeader appName={appName} errorPageType={errorPageType} />
        </FluidContainer>
        <div className={s.carouselContainer}>
          <CategoriesCarousel
            className={s.categories}
            {...categoriesCarouselProps(categories, t)}
          />
        </div>
      </>
    );
  });
