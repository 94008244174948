import React from 'react';
import s from './reviews-section-header.module.scss';
import { Text, DropdownBase, TextButton, Box } from '@wix/design-system';
import { ChevronDown } from '@wix/wix-ui-icons-common';
import { WriteReviewButton } from '../../write-review-button';
import { useRateAndReviewSectionContext } from '../../../../exported-components/rate-and-reviews-section/rate-and-reviews-section-context';
import type { ReviewType as Review } from '@wix/app-market-services';
import { useEssentials } from '../../../../contexts/essentials-context';
import { DeleteReviewMessage } from './delete-review-message';

interface ReviewsSectionProps {
  selectedSort: string;
  onChangeSort: (sort: string) => Promise<void>;
  totalReviews: number;
  userReview?: Review;
}

export function ReviewsSectionHeader({
  selectedSort,
  onChangeSort,
  totalReviews,
  userReview,
}: ReviewsSectionProps) {
  const { shouldShowUserActionButton, setReviewModalOpen } =
    useRateAndReviewSectionContext();
  const { t } = useEssentials();

  function getUserAction() {
    if (userReview?.isDeleted) {
      return <DeleteReviewMessage />;
    } else if (shouldShowUserActionButton) {
      if (userReview?.id) {
        return (
          <TextButton onClick={() => setReviewModalOpen(true)}>
            {t('solution.page.reviews.editYourReview')}
          </TextButton>
        );
      } else {
        return <WriteReviewButton size="tiny" />;
      }
    } else {
      return null;
    }
  }

  const options = [
    { id: 'publishedAt', value: t('solution.page.reviewsBtnRecent') },
    { id: 'totalLikes', value: t('solution.page.reviewsBtnHelpful') },
    { id: 'rating', value: t('solution.page.reviewsBtnRating') },
  ];
  return (
    <Box className={s.reviewsHeader} padding="18px 24px" align="space-between">
      {totalReviews > 1 ? (
        <Box>
          <Text>{t('app.page.reviews.sort.by')}</Text>
          <DropdownBase
            placement="bottom"
            options={options}
            dataHook="sort-dropdown"
            selectedId={selectedSort}
            focusOnSelectedOption={true}
            onSelect={(e) => onChangeSort(e.id.toString())}
          >
            {({ toggle }) => (
              <TextButton
                skin="dark"
                onClick={toggle}
                suffixIcon={<ChevronDown />}
                weight="normal"
                style={{ paddingLeft: '12px' }}
                dataHook="sort-button-dropdown"
              >
                {options.find((option) => option.id === selectedSort)?.value}
              </TextButton>
            )}
          </DropdownBase>
        </Box>
      ) : null}
      <Box>{getUserAction()}</Box>
    </Box>
  );
}
