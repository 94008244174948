import * as React from 'react';
import s from './header-not-logged-in-view.scss';
import { Box, Button, Divider, Popover, TextButton } from '@wix/design-system';
import type { TFunction } from 'react-i18next';
import { ChevronDown } from '@wix/wix-ui-icons-common';
import { useConfigContext } from '../../../../config-context';
import { getCurrentLanguageText } from '../header-desktop-user-details/header-desktop-languages-popover/header-desktop-languages-popover-content/languages';
import { composer } from '../../../../component-decorators/composer';
import { HeaderDesktopLanguagesPopoverContent } from '../header-desktop-user-details/header-desktop-languages-popover/header-desktop-languages-popover-content/header-desktop-languages-popover-content';
import type { InjectedExperimentsProps } from '@wix/wix-experiments-react';

interface IHeaderNotLoggedInViewProps
  extends Partial<InjectedExperimentsProps> {
  signinLink: string;
}

export const HeaderNotLoggedInView = composer()
  .withExperiments()
  .compose(({ signinLink, experiments }: IHeaderNotLoggedInViewProps) => {
    const { locale } = useConfigContext();
    const [popoverOpen, setPopoverOpen] = React.useState(false);
    const flipPopoverOpen = () => {
      setPopoverOpen(!popoverOpen);
    };
    return (
      <Box verticalAlign="middle" className={s.notLoggedIn}>
        <>
          <Popover
            placement="bottom"
            animate
            appendTo="scrollParent"
            onClick={flipPopoverOpen}
            onClickOutside={flipPopoverOpen}
            showArrow
            shown={popoverOpen}
            theme="light"
            dataHook="languages-popover"
          >
            <Popover.Element>
              <Box marginRight="24px" marginLeft="29px">
                <TextButton skin="dark" suffixIcon={<ChevronDown />}>
                  {getCurrentLanguageText(locale)}
                </TextButton>
              </Box>
            </Popover.Element>
            <Popover.Content>
              <HeaderDesktopLanguagesPopoverContent isLoggedIn={false} />
            </Popover.Content>
          </Popover>
          <Box minHeight="18px">
            <Divider direction="vertical" />
          </Box>
        </>
        <LoginButton signinLink={signinLink} />
      </Box>
    );
  });

const LoginButton = composer()
  .withTranslation()
  .compose(({ t, signinLink }: { t?: TFunction; signinLink: string }) => (
    <Box marginLeft="29px" marginRight="24px">
      <Button
        className={s.loginButton}
        size="small"
        priority="secondary"
        onClick={() => (window.location.href = signinLink)}
      >
        {t('standalone.header.login')}
      </Button>
    </Box>
  ));
