import type { MarketApp as MarketAppType } from '@wix/ambassador-devcenter-market-apps-v1-market-app/types';
import { MarketApp } from './market-app';

export class MarketApps {
  constructor(private readonly marketApps: MarketAppType[]) {}

  findByAppId(appId: string) {
    const marketApp = this.marketApps.find((app) => app.appId === appId);
    return new MarketApp(marketApp).toJSON();
  }
}
