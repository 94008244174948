import {
  IncompleteSetupReason,
  AppGroup,
  ComponentType,
} from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';
import type {
  Component,
  ManagedApp as ManagedAppType,
} from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';

export class ManagedApp {
  constructor(private readonly managedApp: ManagedAppType | undefined) {}

  isAppPendingConfiguration(): boolean {
    return Boolean(
      this.managedApp?.installationDetails?.incompleteSetupReason ===
        IncompleteSetupReason.PENDING_CONFIGURATION,
    );
  }

  isAppPendingEditor(): boolean {
    return this.managedApp?.installationDetails?.group === AppGroup.PENDING;
  }

  getAppDashboard():
    | {
        url: string;
        isExternal: boolean;
      }
    | undefined {
    const components = this.managedApp?.components || [];

    const backOfficeDashboardComponentData = this.getComponentsWithTypes(
      components,
      [ComponentType.BACK_OFFICE_PAGE],
    )[0]?.compData?.backOfficePage;
    if (backOfficeDashboardComponentData) {
      return {
        url: backOfficeDashboardComponentData.iframeUrl ?? '',
        isExternal: false,
      };
    }

    const dashboardComps = this.getComponentsWithTypes(components, [
      ComponentType.DASHBOARD,
      ComponentType.MULTIPLE_DASHBOARDS,
    ]);
    const componentData = dashboardComps?.[0]?.compData;

    const dashboardComponentData = componentData?.dashboardComponentData
      ? componentData.dashboardComponentData
      : componentData?.multipleDashboardsComponentData
      ? componentData.multipleDashboardsComponentData?.items?.[0]?.dashboardData
      : undefined;

    if (dashboardComponentData) {
      return {
        url: dashboardComponentData.url ?? '',
        isExternal: !dashboardComponentData.embedded,
      };
    }

    return;
  }

  get isAppEligibleForUpgrade(): boolean {
    return (
      (this.managedApp?.premiumInfo?.eligibleForUpgrade &&
        !this.isAppPendingEditor() &&
        !this.isAppPendingConfiguration()) ??
      false
    );
  }

  get installedVersion(): string {
    return this.managedApp?.installationDetails?.installedVersion ?? '';
  }

  get instanceId(): string {
    return this.managedApp?.installationDetails?.instanceId ?? '';
  }

  get isAddAgainAllowed(): boolean {
    const components = this.managedApp?.components || [];
    return (
      (this.hasCustomElementComponents(components) ||
        this.hasPlatformComponents(components) ||
        this.hasWidgetsComponents(components)) &&
      !this.hasBlocksComponents(components) &&
      !this.hasPagesComponents(components) &&
      !this.hasAddOnlyOnceWidgets(components)
    );
  }

  private hasComponentsWithTypes(
    components: Component[],
    types: ComponentType[],
  ): boolean {
    return components.some(
      (comp) => comp.compType && types.includes(comp.compType),
    );
  }
  private getComponentsWithTypes(
    components: Component[],
    types: ComponentType[],
  ): Component[] {
    return components.filter(
      (comp) => comp.compType && types.includes(comp.compType),
    );
  }
  private hasCustomElementComponents(components: Component[]): boolean {
    return this.hasComponentsWithTypes(components, [
      ComponentType.WEB,
      ComponentType.CUSTOM_ELEMENT_WIDGET,
    ]);
  }
  private hasPlatformComponents(components: Component[]): boolean {
    return this.hasComponentsWithTypes(components, [ComponentType.PLATFORM]);
  }
  private hasWidgetsComponents(components: Component[]): boolean {
    return this.hasComponentsWithTypes(components, [
      ComponentType.WIDGET,
      ComponentType.WIDGET_OUT_OF_IFRAME,
    ]);
  }
  private getWidgetsComponents(components: Component[]): Component[] {
    return this.getComponentsWithTypes(components, [
      ComponentType.WIDGET,
      ComponentType.WIDGET_OUT_OF_IFRAME,
    ]);
  }
  private hasBlocksComponents(components: Component[]): boolean {
    return this.hasComponentsWithTypes(components, [
      ComponentType.STUDIO,
      ComponentType.STUDIO_WIDGET,
    ]);
  }
  private hasPagesComponents(components: Component[]): boolean {
    return this.hasComponentsWithTypes(components, [
      ComponentType.PAGE,
      ComponentType.PAGE_OUT_OF_IFRAME,
    ]);
  }
  private hasAddOnlyOnceWidgets(components: Component[]): boolean {
    const widgets = this.getWidgetsComponents(components);
    return widgets.some(
      (widget) =>
        widget.compData?.widgetComponentData?.addOnlyOnce ||
        widget.compData?.widgetOutOfIframeData?.widgetData?.addOnlyOnce,
    );
  }

  toJSON() {
    return this.managedApp;
  }
}
