import React from 'react';
import { Box, Heading } from '@wix/design-system';
import { SearchBox } from '@wix/app-market-components';
import { useEssentials, useRouterContext } from '../../contexts';
import { AppMarketPage } from '../../enums';
import { useTranslation } from '../../contexts/translations-context';
import { useSearchBoxActions } from '../../components/search-box/hooks/search-box-actions';
import { BackButton } from '../../components/back-button';
import { appMarketBackButtonClick } from '@wix/bi-logger-app-market-data/v2';
import { RoutePath } from '../../models/router';
import s from './header.scss';

interface HeaderProps {
  searchTerm: string;
  isAppsFound: boolean;
}

export function Header({ searchTerm, isAppsFound }: HeaderProps) {
  const pageName = AppMarketPage.SEARCH_RESULT;
  const { i18n, experiments, biLogger } = useEssentials();
  const { router } = useRouterContext();
  const { onSelect, onChange, onFocus, onEnterPressed } = useSearchBoxActions(
    pageName,
    pageName,
  );
  const { t } = useTranslation();

  function onBack() {
    biLogger.report(
      appMarketBackButtonClick({
        tag_type: pageName,
        tag_name: searchTerm,
        location: 'search_result_page',
      }),
    );
    router.navigateTo({
      path: RoutePath.BACK,
      payload: undefined,
    });
  }

  return (
    <Box direction="vertical" marginTop="24px" marginBottom="24px">
      <Box>
        <Box flex={2} direction="vertical">
          {router.hasHistory() && (
            <BackButton
              onBack={onBack}
              size="small"
              weight="thin"
              skin="dark"
            />
          )}
          {isAppsFound && (
            <Heading dataHook="search-result-title" size="large">
              {t('search.results.page.title', {
                searchTerm,
              })}
            </Heading>
          )}
        </Box>
        <Box
          className={s.searchBoxContainer}
          flex={1}
          marginTop={isAppsFound ? '16px' : undefined}
        >
          <SearchBox
            dataHook="search-box-search-results"
            givenSearchTerm={searchTerm}
            onChange={onChange}
            onSelect={onSelect}
            onEnterPressed={onEnterPressed}
            onFocus={onFocus}
            i18n={i18n}
            experiments={experiments}
            size="medium"
          />
        </Box>
      </Box>
    </Box>
  );
}
