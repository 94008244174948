import type { ICategoriesCarouselComponentProps } from '../CategoriesCarousel';

const getCategoryIcon = (category) =>
  category &&
  category.assetsMap &&
  category.assetsMap.collectionImage &&
  category.assetsMap.collectionImage.assets &&
  category.assetsMap.collectionImage.assets[0] &&
  category.assetsMap.collectionImage.assets[0].url;

export const categoriesCarouselProps = (
  categories,
  t,
): ICategoriesCarouselComponentProps => ({
  displayName: t('category.exploreByCategory'),
  innerTagsDefinitions:
    categories &&
    categories
      .map((category) => category.category)
      .filter((category) => !category.isHidden)
      .filter((category) => category.apps.length !== 0)
      .map((category) => {
        return {
          name: category.name,
          slug: category.slug,
          description: category.description,
          icon: getCategoryIcon(category),
        };
      }),
});
