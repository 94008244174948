import React, { useState } from 'react';
import s from './review-reply.module.scss';
import { Avatar, Box, Text, TextButton } from '@wix/design-system';
import {
  isBodyTooLong,
  nameToAvatarFormatter,
  reviewBodySlicer,
} from '../review.utils';
import type { Company } from '../../../../exported-components/rate-and-reviews-section/types';
import type { Reply } from '@wix/app-market-services';
import { useEssentials } from '../../../../contexts/essentials-context';
import { useRateAndReviewSectionContext } from '../../../../exported-components/rate-and-reviews-section/rate-and-reviews-section-context';

interface ReviewReplyProps {
  reply: Reply;
  company: Company;
}

export function ReviewReply({ reply, company }: ReviewReplyProps) {
  const { t } = useEssentials();
  const { onReadMoreOrLessClick } = useRateAndReviewSectionContext();

  const [expandedBody, setExpandedBody] = useState<boolean>(false);

  const isLongReview = isBodyTooLong(reply.description);
  function getReviewBodyText() {
    return isLongReview && !expandedBody
      ? reviewBodySlicer(reply.description)
      : reply.description;
  }
  function expandToggle() {
    onReadMoreOrLessClick('company_comment');
    setExpandedBody((prevExpandedBody) => !prevExpandedBody);
  }

  return (
    <Box
      className={s.reviewReply}
      marginLeft="66px"
      backgroundColor="#f4fafe"
      padding="24px 48px 24px 24px"
      borderRadius="8px"
    >
      <Avatar
        name={nameToAvatarFormatter(company.name)}
        imgProps={{ src: company.icon }}
        size="size36"
      />
      <Box marginLeft="18px" direction="vertical">
        <Box marginBottom="12px">
          <Text size="small" weight="normal">
            {t('app.page.reviews.reply.company.name', {
              companyName: company.name,
            })}
          </Text>
        </Box>
        <Text
          dataHook={`review-${reply.id && reply.id}`}
          size="medium"
          weight="thin"
        >
          {getReviewBodyText()}
        </Text>
        {isLongReview && (
          <TextButton
            dataHook={`reply-body-expander-${reply.id}`}
            onClick={expandToggle}
          >
            {expandedBody
              ? t('solution.page.review.readLess')
              : t('solution.page.review.readMore')}
          </TextButton>
        )}
      </Box>
    </Box>
  );
}
