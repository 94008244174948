import React from 'react';

export const WixSaleLogoBadge = ({
  amount,
  size = 'big',
}: {
  amount: string;
  size?: 'small' | 'big';
}) => {
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size === 'big' ? '44' : '39'}
        height={size === 'big' ? '24' : '18'}
        fill="none"
      >
        <ellipse
          cx="9"
          cy={size === 'big' ? '12' : '9'}
          rx="6"
          ry={size === 'big' ? '12' : '9'}
          fill="#101585"
          transform="translate(-3)"
        />
        {size === 'big' && (
          <ellipse
            cx="17"
            cy="12"
            rx="6"
            ry="12"
            fill="#101585"
            transform="translate(-3)"
          />
        )}
        <ellipse
          cx={size === 'big' ? '25' : '18'}
          cy={size === 'big' ? '12' : '9'}
          rx="6"
          ry={size === 'big' ? '12' : '9'}
          fill="#101585"
          transform="translate(-3)"
        />
        <ellipse
          cx={size === 'big' ? '33' : '27'}
          cy={size === 'big' ? '12' : '9'}
          rx="6"
          ry={size === 'big' ? '12' : '9'}
          fill="#101585"
          transform="translate(-3)"
        />
        <ellipse
          cx={size === 'big' ? '41' : '36'}
          cy={size === 'big' ? '12' : '9'}
          rx="6"
          ry={size === 'big' ? '12' : '9'}
          fill="#101585"
          transform="translate(-3)"
        />
        <text
          x="15%"
          y="55%"
          fill="white"
          font-size={size === 'big' ? '12px' : '10px'}
          dominant-baseline="middle"
          fontFamily="sans-serif"
          fontStyle="normal"
          fontWeight="700"
          letterSpacing="0px"
        >
          {`- ${amount}%`}
        </text>
      </svg>
    </>
  );
};
