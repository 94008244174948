import * as React from 'react';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { withMarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { UpgradeSiteModal } from './upgarde-site-modal';
import { ConnectDomainModal } from './connect-domain-modal';
import { ReviewAppModal } from '@wix/app-market-collection-widget/';
import { config } from '../../config';
import type { GUID } from '@wix/bi-logger-app-market-data/v2/types';
import { AdiEditorModal } from './adi-editor-modal';
import { SwitchToEditorModal } from './switch-to-editor-modal';
import type { IConfigContext } from '../../config-context';
import { useConfigContext } from '../../config-context';
import { SupportModal, Origin } from '@wix/manage-installed-apps';
import { InstallationNotSupportedMobile } from './installation-not-supported-mobile';
import type { WebSolution } from '@wix/ambassador-marketplace/types';
import type { ISolutionReview } from '../../types/common/marketplace';
import { BlockAddReviewMobile } from './block-add-review-mobile';

export enum ModalNames {
  NONE = 'none',
  UPGRADE_SITE = 'upgradeSite',
  CONNECT_DOMAIN = 'connectDomain',
  RATE_APP = 'rateApp',
  EDIT_REVIEW = 'editReview',
  ADI_EDITOR = 'adiEditor',
  SWITCH_TO_EDITOR = 'switchToEditor',
  SUPPORT_MODAL = 'supportModal',
  INSTALLATION_NOT_SUPPORTED_MOBILE = 'InstallationNotSupportedMobile',
  BLOCK_ADD_REVIEW_MOBILE = 'BlockAddReviewMobile',
}

export interface IModalProps {
  marketplaceStore?: MarketplaceStore;
  appName?: string;
  appId?: string;
  appIcon?: string;
  msid?: GUID;
  app?: WebSolution;
  closeModal?: any;
  optionCB?: any;
  userReview?: ISolutionReview;
  manageAppsStaticsUrl?: string;
  locale?: string;
  baseUrl?: string;
  origin?: Origin;
  authorizationHeader?: string;
  clickSource?: string;
  pageName?: string;
  subtitle?: string;
  countries?: any;
  footerTwo?: string;
  onSupportClick?: any;
}

interface IConnectedModalsProps extends IModalProps {
  configContext: IConfigContext;
}

export const Modals = (props: IModalProps) => {
  const configContext = useConfigContext();
  return <ConnectedModals {...props} configContext={configContext} />;
};

@withMarketplaceStore
export class ConnectedModals extends React.Component<IConnectedModalsProps> {
  render() {
    const { marketplaceStore, configContext } = this.props;
    const shouldOpenNewReviewModal =
      marketplaceStore.openedModalName === ModalNames.RATE_APP ||
      marketplaceStore.openedModalName === ModalNames.EDIT_REVIEW;

    const showSuccessToast = (message: string) => {
      if (configContext.showUserActionNotification) {
        configContext.showUserActionNotification({
          message,
          biName: 'review-app',
          type: 'SUCCESS',
        });
      }
    };

    return (
      <>
        {marketplaceStore.openedModalName === ModalNames.UPGRADE_SITE && (
          <UpgradeSiteModal {...marketplaceStore.openedModalProps} />
        )}
        {marketplaceStore.openedModalName === ModalNames.CONNECT_DOMAIN && (
          <ConnectDomainModal {...marketplaceStore.openedModalProps} />
        )}
        {shouldOpenNewReviewModal && (
          <ReviewAppModal
            isMobile={configContext.isMobile}
            locale={configContext.locale}
            appDefId={marketplaceStore.openedModalProps.app.baseInfo.id}
            isOpen={true}
            closeFn={marketplaceStore.openedModalProps.closeModal}
            afterCloseFn={marketplaceStore.openedModalProps.optionCB}
            showSuccessToast={showSuccessToast}
            additionalBiData={{
              msid: marketplaceStore.metaSiteId,
              tagType: 'app_page',
              platform: config.originForBI,
            }}
          />
        )}
        {marketplaceStore.openedModalName === ModalNames.ADI_EDITOR && (
          <AdiEditorModal {...marketplaceStore.openedModalProps} />
        )}
        {marketplaceStore.openedModalName === ModalNames.SWITCH_TO_EDITOR && (
          <SwitchToEditorModal {...marketplaceStore.openedModalProps} />
        )}
        {marketplaceStore.openedModalName ===
          ModalNames.INSTALLATION_NOT_SUPPORTED_MOBILE && (
          <InstallationNotSupportedMobile
            {...marketplaceStore.openedModalProps}
          />
        )}
        {marketplaceStore.openedModalName ===
          ModalNames.BLOCK_ADD_REVIEW_MOBILE && (
          <BlockAddReviewMobile {...marketplaceStore.openedModalProps} />
        )}
        {marketplaceStore.openedModalName === ModalNames.SUPPORT_MODAL && (
          <SupportModal
            locale={configContext.locale}
            baseUrl={config.baseApiUrl}
            closeModal={marketplaceStore.openedModalProps.closeModal}
            origin={config.origin === 'editor' ? Origin.EDITOR : Origin.BIZMGR} // todo standalone
            app={marketplaceStore.openedModalProps.app}
            msId={marketplaceStore.metaSiteId}
            authorizationHeader={
              configContext.getAppInstance &&
              configContext.getAppInstance(
                '22bef345-3c5b-4c18-b782-74d4085112ff',
              )
            }
            // authorizationHeader={'1234'}
            manageAppsStaticsUrl={config.manageAppsStaticsUrl}
            clickSource={marketplaceStore.openedModalProps.clickSource}
            pageName={marketplaceStore.openedModalProps.pageName}
            showUserActionNotification={
              configContext.showUserActionNotification
            }
          />
        )}
      </>
    );
  }
}
