import React from 'react';
import style from './app-box.module.scss';

import { NarrowAppBoxSkeleton } from './app-box-layouts/narrow-app-box';
import { RegularAppBoxSkeleton } from './app-box-layouts/regular-app-box';
import { WideAppBoxSkeleton } from './app-box-layouts/wide-app-box';

export function AppBoxSkeleton() {
  return (
    <div className={style.appBoxSkeleton}>
      <NarrowAppBoxSkeleton className={style.narrowLayout} />
      <RegularAppBoxSkeleton className={style.regularLayout} />
      <WideAppBoxSkeleton className={style.wideLayout} />
    </div>
  );
}
