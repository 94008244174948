import React from 'react';
import { SaleBanner } from './sale-banner';
import { Box } from '@wix/design-system';
import { useEssentials, useUserContext } from '../../contexts';

export function withSaleBanner<P extends object>(Page: React.ComponentType<P>) {
  return (props: P) => {
    const { isMobile } = useUserContext();
    const { experiments } = useEssentials();

    const isSaleBannerVisible = experiments.enabled('specs.app-market.Sale');

    if (!isSaleBannerVisible) {
      return <Page {...props} />;
    }

    return (
      <>
        {isMobile && <SaleBanner layout="wide" />}
        <Page {...props} />
        {!isMobile && (
          <Box direction="vertical" position="sticky" bottom={0} zIndex={6}>
            <SaleBanner layout="wide" />
          </Box>
        )}
      </>
    );
  };
}
