import React from 'react';
import { Box, Text, TextButton } from '@wix/design-system';
import { FavoriteFilled } from '@wix/wix-ui-icons-common';
import { useEssentials } from '../../../contexts/essentials-context';

export interface ReviewsSummaryProps {
  averageRating: number;
  totalReviewsCount: number;
  onClick: (totalReviewsCount: number) => void;
}

export function ReviewsSummary({
  totalReviewsCount,
  averageRating,
  onClick,
}: ReviewsSummaryProps) {
  const { t } = useEssentials();

  function getReviewsLabel() {
    if (totalReviewsCount === 1) {
      return t('app.page.reviews.one.review.subtitle');
    }
    return t('app.page.reviews.subtitle', {
      totalReviews: totalReviewsCount,
    });
  }

  return (
    <>
      <Box>
        <FavoriteFilled color={totalReviewsCount ? '#FDB10C' : '#ACAFC4'} />
        {Boolean(totalReviewsCount) && (
          <Text weight="bold">{averageRating?.toFixed(1)}</Text>
        )}
      </Box>

      {totalReviewsCount === 0 ? (
        <Text secondary light>
          {t('solution.page.details.review.empty')}
        </Text>
      ) : (
        <TextButton
          dataHook="reviews-count-button"
          onClick={() => onClick(totalReviewsCount)}
        >
          {getReviewsLabel()}
        </TextButton>
      )}
    </>
  );
}
