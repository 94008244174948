import * as React from 'react';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { withMarketplaceStore } from '../../component-decorators/with-marketplace-store';
import s from './DeveloperPageContainer.scss';
import { WithFetcher } from '../../component-decorators/with-fetcher';
import { composer } from '../../component-decorators/composer';
import { CenteredLoader } from '../centered-loader';
import { useConfigContext } from '../../config-context';
import { FluidContainer } from '../FluidContainer';
import { SolutionGridWrapper } from '../solution-grid-wrapper/solution-grid-wrapper';
import { CategoriesCarousel } from '../CategoriesCarousel';
import { fetchDeveloperPageData } from './fetchDeveloperPageData';
import { categoriesCarouselProps } from './categoriesCarouselProps';
import { DeveloperPageTopSection } from './developer-page-top-section';
import type {
  CategoryToSubCategory,
  Company,
} from '@wix/ambassador-marketplace/types';
import { config } from '../../config';
import { Origin } from '../..';
import { DeveloperPageSeo } from './developer-page-seo';

export interface IDeveloperPageProps extends Partial<WithTranslationProps> {
  marketplaceStore?: MarketplaceStore;
  slug: string;
  companyInfo: Company;
  categories: CategoryToSubCategory;
}

@withMarketplaceStore
class DeveloperPage extends React.Component<IDeveloperPageProps, any> {
  getIconUrl() {
    return this.props?.companyInfo?.assetsMap?.companyIcon?.assets?.[0]?.url;
  }
  render() {
    const { averageRating, webSolutions } = this.props?.companyInfo || {};

    return (
      <>
        <FluidContainer className={s.conatiner}>
          <DeveloperPageTopSection
            icon={this.getIconUrl()}
            name={this.props?.companyInfo.companyBase?.name}
            t={this.props.t}
            appsCount={webSolutions.length}
            averageRating={averageRating}
            marketplaceStore={this.props.marketplaceStore}
          />
          <SolutionGridWrapper
            solutions={webSolutions.map((solution, appIndex) => {
              return { appIndex, ...solution };
            })}
            referralSectionName="developer-page"
          />
        </FluidContainer>
        <div className={s.carouselContainer}>
          <CategoriesCarousel
            className={s.carousel}
            {...categoriesCarouselProps(this.props.categories, this.props.t)}
          />
        </div>
      </>
    );
  }
}

export const DeveloperPageContainer = composer()
  .withTranslation()
  .compose((props) => {
    const { locale, rpcClient } = useConfigContext();
    return (
      <WithFetcher
        loader={<CenteredLoader />}
        initialFetch={fetchDeveloperPageData(props.slug, locale, rpcClient)}
        fetchMemoizationId={`developer-${props.slug}-${locale}`}
      >
        {({ fetchData }) => (
          <>
            {config.origin === Origin.STANDALONE && (
              <DeveloperPageSeo
                developerName={fetchData.company}
                developerSlug={props.slug}
              />
            )}
            <DeveloperPage
              slug={props.slug}
              companyInfo={fetchData.company}
              categories={fetchData.categories}
              t={props.t}
            />
          </>
        )}
      </WithFetcher>
    );
  });
