import * as React from 'react';
import s from './back-button.scss';
import type { TextButtonSize, TextButtonSkin } from '@wix/design-system';
import { Box, Divider, TextButton } from '@wix/design-system';
import type { WithTranslation as WithTranslationProps } from 'react-i18next';
import { appMarketBackButtonClick } from '@wix/bi-logger-app-market-data/v2';
import { config } from '../../config';
import { composer } from '../../component-decorators/composer';
import { biLogger } from '../../bi';
import { useConfigContext } from '../../config-context';
import type { MarketplaceStore } from '../../component-decorators/with-marketplace-store';
import { ChevronLeftSmall, ChevronLeftLarge } from '@wix/wix-ui-icons-common';

export interface BackButtonProps extends Partial<WithTranslationProps> {
  marketplaceStore?: MarketplaceStore;
  appId?: string;
  slug?: string;
  description: string;
  inSolutionPage?: boolean;
  isBackToReferrerMode?: boolean;
  skinColor: TextButtonSkin;
  hideText?: boolean;
  size?: TextButtonSize;
}

export const BackButton = composer()
  .withMarketplaceStore()
  .withTranslation()
  .compose(
    ({
      appId,
      t,
      marketplaceStore,
      skinColor,
      inSolutionPage,
      hideText,
      size,
      isBackToReferrerMode = false,
    }: BackButtonProps) => {
      const { isMobile } = useConfigContext();

      async function sendBiAndGoBack() {
        await biLogger.report(
          appMarketBackButtonClick({
            appId,
            tag_type: marketplaceStore?.route?.path,
            tag_name: marketplaceStore?.route?.referralTag,
            market: config.originForBI,
            msid: marketplaceStore?.metaSiteId,
            location: hideText ? 'sticky-header' : 'page',
          }),
        );

        if (isBackToReferrerMode) {
          config.goBackToReferrer.navigation();
        } else {
          config.goto({
            ...marketplaceStore.prevRoute,
            referral: 'back_button',
          });
        }
      }

      const shouldShowDivider = !isMobile && inSolutionPage;
      const shouldAddMarginBottom = !shouldShowDivider || !isMobile;
      const marginUnderButton = isMobile ? '18px' : '24px';
      const buttonSize = size
        ? size
        : hideText || isMobile
        ? 'medium'
        : 'small';

      function getButtonText() {
        if (hideText) {
          return '';
        }

        if (isBackToReferrerMode) {
          return t('homepage.back.to.referrer', {
            translatedPageName: t(`${config.goBackToReferrer.labelKey}`),
          });
        }

        return t('marketplace.go-back.button');
      }

      return (
        <>
          <TextButton
            as="button"
            skin={skinColor}
            dataHook="back-button"
            onClick={sendBiAndGoBack}
            prefixIcon={isMobile ? <ChevronLeftLarge /> : <ChevronLeftSmall />}
            weight="normal"
            size={buttonSize}
          >
            {getButtonText()}
          </TextButton>
          {shouldShowDivider && (
            <Divider className={s.divider} direction="vertical" />
          )}
          {shouldAddMarginBottom && <Box height={marginUnderButton} />}
        </>
      );
    },
  );
