import type { GUID } from '@wix/bi-logger-app-market-data/v2/types';
import type { IRoute } from '../../types/common/marketplace';
import { getRouteForBi } from '../../common/bi-events';
import type { ApiTag } from '@wix/ambassador-marketplace/types';

export const getTagBiData = (
  tags: ApiTag[],
  tagName: string,
  tagTypeClicked: string,
  widgetType: string,
  msid: GUID,
  route: IRoute,
  prevRoute: IRoute,
): {
  widgetType?: string;
  index?: number;
  market?: string;
  msid?: GUID;
  tagName?: string;
  tagNameClicked?: string;
  tagType?: string;
  tagTypeClicked?: string;
  section?: string;
}[] => {
  if (tags) {
    const { market } = getRouteForBi(route, prevRoute);
    return tags.map((tag: ApiTag, index: number) => {
      return {
        index,
        msid,
        market,
        widgetType,
        tagName: tagName.replace(/-/g, '_'),
        tagNameClicked: tag?.slug.replace(/-/g, '_'),
        tagType: 'homepage',
        tagTypeClicked,
      };
    });
  }
};
