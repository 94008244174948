import React from 'react';
import { useEssentials } from '../../contexts/essentials-context';
import { Box, Text, Tooltip } from '@wix/design-system';
import { WixSaleLogoBadge } from './wix-sale-logo-badge';
import s from '../../sass/badge.module.scss';

interface WixSaleBadgeProps {
  amount: string;
  size?: 'small' | 'big';
}

export function WixSaleBadge({ amount, size = 'big' }: WixSaleBadgeProps) {
  const { t } = useEssentials();

  return (
    <Box className={s.badgeHeight}>
      <Tooltip
        placement="right"
        inline
        content={
          <Box direction="vertical" gap="6px">
            <Text size="small" weight="bold" light>
              {t('wix.november.sale.badge')}
            </Text>
            <Text size="small" weight="thin" light>
              {t('wix.november.sale.badge.tooltip', { amount })}
            </Text>
          </Box>
        }
        size="medium"
      >
        <Box marginTop="1px">
          <WixSaleLogoBadge amount={amount} size={size} />
        </Box>
      </Tooltip>
    </Box>
  );
}
