export function queryKeyBuilder({
  queryName,
  language,
  appsLength = 0,
  queryId,
  isSale,
}: {
  queryName: string;
  language: string;
  appsLength?: number;
  queryId: string;
  isSale: boolean;
}): string {
  return `${queryName}-${language}-${appsLength}-${queryId}-${isSale}`;
}
