import type { AppBadgeType } from '../apps-badges';
import type { BundleApps } from './bundle-apps';

export type {
  BundleWebSolution,
  BundleApps as BundleAppsType,
} from '@wix/ambassador-devcenter-bundle-apps-v1-bundle-apps/types';

export { BundleAppOrigin } from '@wix/ambassador-devcenter-bundle-apps-v1-bundle-apps/types';

export type getBundleApps = () => Promise<BundleApps>;

export enum AppBundleType {
  COUPON = 'COUPON',
  DEFAULT = 'DEFAULT',
}

export type IBundleApp = {
  appId?: string;
  badge?: AppBadgeType;
};
