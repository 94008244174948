import type { MarketListing as MarketListingType } from '@wix/ambassador-devcenter-app-market-listing-v1-market-listing/types';
import type { Benefits, ImagesMedia, VideoMedia } from './types';
import { allCountries } from '../../constants/all-countries';

export class MarketListing {
  constructor(private readonly marketListing: MarketListingType | undefined) {}

  get isValid(): boolean {
    return (
      Boolean(this.appName) &&
      Boolean(this.appIcon) &&
      Boolean(this.description) &&
      Boolean(this.imagesMedia.length)
    );
  }

  get appName(): string {
    return this.marketListing?.basicInfo?.name ?? '';
  }

  get appId(): string {
    return this.marketListing?.appId ?? '';
  }

  get appIcon(): string {
    return this.marketListing?.assetsMap?.appIcon?.assets?.[0]?.url ?? '';
  }

  get appTeaser(): string {
    return this.marketListing?.basicInfo?.teaser ?? '';
  }

  get benefits(): Benefits[] {
    return this.marketListing?.basicInfo?.benefits ?? [];
  }

  get description(): string {
    return this.marketListing?.basicInfo?.longDescription ?? '';
  }

  get demoUrl(): string | undefined {
    return this.marketListing?.basicInfo?.demoUrl ?? undefined;
  }

  get appDependenciesIds(): string[] {
    return (
      this.marketListing?.installationRequirement?.requiredApps ?? []
    ).map((app) => {
      if (app.id) {
        return app.id;
      }

      throw new Error(
        `appId is missing in path: marketListing.installationRequirement.requiredApps`,
      );
    });
  }

  get imagesMedia(): ImagesMedia[] {
    const screenshots =
      (this.marketListing?.assetsMap?.screenshots || {}).assets || [];
    return screenshots
      .filter((asset) => asset.mediaType === 'IMAGE')
      .map((asset) => ({
        url: asset.url || '',
        type: asset.mediaType as 'IMAGE',
      }));
  }

  get videoMedia(): VideoMedia[] {
    const videos =
      (this.marketListing?.assetsMap?.galleryVideos || {}).assets || [];
    return videos.map((asset) => ({
      url: asset.url || '',
      type: asset.mediaType as 'YOUTUBE',
    }));
  }

  get isAppContentTranslatable(): boolean {
    return (
      this.marketListing?.installationRequirement?.allowLanguageCustomization ??
      false
    );
  }

  get supportedLanguages(): string[] {
    return this.marketListing?.installationRequirement?.includeLanguages ?? [];
  }

  get geoAvailability(): {
    isAvailableWorldwide: boolean;
    availableCountries?: string[];
  } {
    const includeCountries =
      this.marketListing?.installationRequirement?.includeCountries ?? [];
    const excludeCountries =
      this.marketListing?.installationRequirement?.excludeCountries ?? [];

    if (includeCountries.length === 0 && excludeCountries.length === 0) {
      return { isAvailableWorldwide: true };
    }

    if (includeCountries.length > 0) {
      return {
        isAvailableWorldwide: false,
        availableCountries: includeCountries,
      };
    }

    return {
      isAvailableWorldwide: false,
      availableCountries: allCountries.filter(
        (country) => !excludeCountries.includes(country),
      ),
    };
  }

  get externalPricingPageUrl(): string | undefined {
    return this.marketListing?.pricingData?.externalPricingPageUrl ?? undefined;
  }

  toJSON() {
    return this.marketListing as MarketListingType;
  }
}
