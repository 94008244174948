import {
  SkeletonLine,
  SkeletonRectangle,
  Box,
  SkeletonGroup,
  Page,
} from '@wix/design-system';
import React from 'react';
import s from '../pages.scss';
import headerStyles from './header.scss';
import { AppsSectionSkeleton } from '../../components/apps-section/apps-section-skeletons';

export function SearchResultsSkeleton() {
  return (
    <Page className={s.appMarketPage}>
      <Page.Content>
        <SkeletonGroup skin="light">
          <Box dataHook="skeleton-header" marginTop="24px" direction="vertical">
            <Box>
              <Box
                dataHook="skeleton-header-text"
                flex={2}
                direction="vertical"
              >
                <Box dataHook="back-button-skeleton" marginLeft="4px">
                  <SkeletonLine width="48px" />
                </Box>
                <Box dataHook="skeleton-titles" direction="vertical">
                  <SkeletonRectangle
                    width="186px"
                    height="24px"
                    marginTop="12px"
                  />
                </Box>
              </Box>
              <Box
                dataHook="skeleton-header-image"
                className={headerStyles.searchBoxContainer}
                flex={1}
                direction="vertical"
                alignSelf="end"
              >
                <SkeletonRectangle height="36px" />
              </Box>
            </Box>
          </Box>
          <AppsSectionSkeleton />
        </SkeletonGroup>
      </Page.Content>
    </Page>
  );
}
