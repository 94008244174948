import type { IRoute } from '..';
import { Path } from '..';
import { config } from '../config';
import { biLogger } from '../bi';
import { AppMarketWidget } from '@wix/app-market-collection-widget';
import type { IBiData } from '../types/common/marketplace';
import {
  AppMarketPage,
  AppMarketEntryPoint,
} from '../types/common/marketplace';
import { appMarketAppPageScrollPage } from '@wix/bi-logger-app-market-data/v2';

export const getPathForBi = (path?: string) => {
  return path === Path.HOME || ''
    ? AppMarketPage.HOMEPAGE
    : path === Path.WEB_SOLUTION
    ? AppMarketPage.APP_PAGE
    : path;
};

export const getWidgetReferral = () => {
  return (
    config?.goBackToReferrer?.referrerNameForBI &&
    Object.values(AppMarketWidget).includes(
      // @ts-expect-error
      config.goBackToReferrer.referrerNameForBI,
    ) &&
    config.goBackToReferrer.referrerNameForBI
  );
};

export const getReferralInfo = (referral, route?: IRoute): string => {
  if (referral) {
    return referral;
  }
  if (route && route.path) {
    return getPathForBi(route.path);
  }
  if (config.goBackToReferrer) {
    return config.goBackToReferrer.referrerNameForBI;
  }
  if (route) {
    return AppMarketPage.HOMEPAGE;
  }
};

export const getTagName = (route: IRoute) => {
  return route?.slug || route?.query || AppMarketPage.HOMEPAGE;
};

export const getRouteForBi = (route: IRoute, prevRoute: IRoute): IBiData => {
  const res: IBiData = {};
  res.market = config.originForBI;
  res.tagName = getTagName(route);
  res.tagType = getPathForBi(route.path);
  res.subCategory = route.subCategories && route.subCategories.join(',');
  res.referralInfo = getReferralInfo(route.referral, prevRoute);
  res.section = route?.section;
  res.searchTerm = route.query ? route.query : prevRoute && prevRoute.query;
  res.widgetReferral = getWidgetReferral();
  res.collimp_id = route?.collimp_id;

  return res;
};

export const sendAppPageImpressionsBi = (
  pageSection: string,
  sectionIndex: number,
  appId: string,
) => {
  biLogger.report(
    appMarketAppPageScrollPage({
      app_id: appId,
      page_part: pageSection,
      page_part_index: sectionIndex,
      tag_name: 'app_page',
    }),
  );
};

export const mapAppMarketEntryPointToBiValue = {
  'my-account-header': AppMarketEntryPoint.DASHBOARD_HEADER_EXPLORE,
  sidebar: AppMarketEntryPoint.DASHBOARD_SIDEBAR,
  search: AppMarketEntryPoint.DASHBOARD_HEADER_SEARCH,
  dashboard_more_than_a_website:
    AppMarketEntryPoint.DASHBOARD_TOOLS_FOR_YOUR_BUSINESS,
};
