import type { DiscountType } from '../apps-plans';
import type { AppBundleType } from '../bundle-apps';

enum EAppBadges {
  BETA = 'BETA',
  WIX_CHOICE = 'WIX_CHOICE',
  PREMIUM_BENEFIT = 'PREMIUM_BENEFIT',
  WIX_SALE = 'WIX_SALE',
  DEV_SALE = 'DEV_SALE',
}

type DiscountData = {
  type: DiscountType;
  amount: string;
};

interface BaseBadgePayload {
  priority: number;
}

interface MarketAppBadgePayload extends BaseBadgePayload {}

interface BundleAppBadgePayload extends BaseBadgePayload {
  appBundleType: AppBundleType;
}

interface SaleBadgePayload extends BaseBadgePayload {
  discountData: DiscountData;
}

interface AppBadgesPayloadMap {
  [EAppBadges.BETA]: MarketAppBadgePayload;
  [EAppBadges.WIX_CHOICE]: MarketAppBadgePayload;
  [EAppBadges.PREMIUM_BENEFIT]: BundleAppBadgePayload;
  [EAppBadges.WIX_SALE]: SaleBadgePayload;
  [EAppBadges.DEV_SALE]: SaleBadgePayload;
}

type SomeBadge<T extends EAppBadges> = {
  badge: T;
  payload: AppBadgesPayloadMap[T];
};

type AppBadgeType =
  | SomeBadge<EAppBadges.BETA>
  | SomeBadge<EAppBadges.WIX_CHOICE>
  | SomeBadge<EAppBadges.PREMIUM_BENEFIT>
  | SomeBadge<EAppBadges.WIX_SALE>
  | SomeBadge<EAppBadges.DEV_SALE>;

export { EAppBadges, AppBadgeType };
