import type { IHttpClient } from '@wix/http-client';
import type {
  GetMarketAppRequest,
  QueryMarketAppRequest,
} from '@wix/ambassador-devcenter-market-apps-v1-market-app/types';
import type {
  ManagedAppsRequest,
  CompleteSetupCountRequest,
} from '@wix/ambassador-devcenter-managedapps-v1-managed-app/types';
import type { GetAppComponentsByAppIdsRequest } from '@wix/ambassador-devcenter-app-components-v1-app-components/types';
import type { QueryAppRequest } from '@wix/ambassador-devcenter-apps-v1-app/types';
import type { QueryTagRequest } from '@wix/ambassador-prime-auto-v1-tag/types';
import type {
  GetAppsByTagRequest,
  QueryTagAppRequest,
} from '@wix/ambassador-devcenter-appmarket-v1-tag-app/types';
import type {
  GetAppsByAppIdsRequest,
  GetDynamicSectionsRequest,
  GetTagSectionsRequest,
} from '@wix/ambassador-appmarket-v1-app-market-client-aggregator/types';
import type { GetWebSolutionsRequest } from '@wix/ambassador-devcenter-marketplace-v1-web-solution/types';
import type { ListAppPlansByAppIdRequest } from '@wix/ambassador-appmarket-v1-app-plans/types';
import type { CompleteInstallationRequest } from '@wix/ambassador-devcenter-pendingapps-v1-pending-installation/types';
import type { QueryAppReviewsSummaryRequest } from '@wix/ambassador-appreviewssummaries-v1-app-reviews-summary/types';
import type {
  AppreviewsCreateReviewRequest,
  GetUserReviewRequest,
  QueryReviewsRequest,
  UpdateReviewRequest,
} from '@wix/ambassador-devcenter-v1-review/types';
import type { PropertiesReadResponse } from '@wix/ambassador-site-properties-service/types';
import type { GetPricingModelRequest } from '@wix/ambassador-devcenter-pricing-v1-pricing-entity/types';
import type { GetSitesPremiumStatusRequest } from '@wix/ambassador-premium-v1-asset/types';
import type {
  SearchRequest,
  AutocompleteRequest,
} from '@wix/ambassador-devcenter-ams-v1-app-market-search/types';
import type { GetFreeTrialEligibilityRequest } from '@wix/ambassador-premium-store-v1-dynamic-offering-service-entity/types';
import type { GetEligibleAppsForFreeTrialRequest } from '@wix/ambassador-appmarket-v1-app-subscriptions/types';

/**
 * Http services implementation, based on httpClient.
 * @class HttpServices
 */
export class HttpServices {
  constructor(private readonly httpClient: IHttpClient) {}

  async getManagedApps(payload: ManagedAppsRequest) {
    const managedApps = (
      await import('@wix/ambassador-devcenter-managedapps-v1-managed-app/http')
    ).managedApps;
    return this.httpClient.request(managedApps(payload));
  }

  async completeSetupCount(payload: CompleteSetupCountRequest) {
    const completeSetupCount = (
      await import('@wix/ambassador-devcenter-managedapps-v1-managed-app/http')
    ).completeSetupCount;
    return this.httpClient.request(completeSetupCount(payload));
  }

  async getMarketApp(payload: GetMarketAppRequest) {
    const getMarketApp = (
      await import('@wix/ambassador-devcenter-market-apps-v1-market-app/http')
    ).getMarketApp;
    return this.httpClient.request(getMarketApp(payload));
  }

  async queryMarketApp(payload: QueryMarketAppRequest) {
    const queryMarketApp = (
      await import('@wix/ambassador-devcenter-market-apps-v1-market-app/http')
    ).queryMarketApp;
    return this.httpClient.request(queryMarketApp(payload));
  }

  async queryApp(payload: QueryAppRequest) {
    const queryApp = (
      await import('@wix/ambassador-devcenter-apps-v1-app/http')
    ).queryApp;
    return this.httpClient.request(queryApp(payload));
  }

  async queryTag(payload: QueryTagRequest) {
    const queryTag = (await import('@wix/ambassador-prime-auto-v1-tag/http'))
      .queryTag;
    return this.httpClient.request(queryTag(payload));
  }

  async queryTagApp(payload: QueryTagAppRequest) {
    const queryTagApp = (
      await import('@wix/ambassador-devcenter-appmarket-v1-tag-app/http')
    ).queryTagApp;
    return this.httpClient.request(queryTagApp(payload));
  }
  async getAppsByTag(payload: GetAppsByTagRequest) {
    const getAppsByTag = (
      await import('@wix/ambassador-devcenter-appmarket-v1-tag-app/http')
    ).getAppsByTag;
    return this.httpClient.request(getAppsByTag(payload));
  }

  async getAppsByAppIds(payload: GetAppsByAppIdsRequest) {
    const getAppsByAppIds = (
      await import(
        '@wix/ambassador-appmarket-v1-app-market-client-aggregator/http'
      )
    ).getAppsByAppIds;
    return this.httpClient.request(getAppsByAppIds(payload));
  }

  async getWebSolutionsBase(payload: GetWebSolutionsRequest) {
    const getWebSolutions = (
      await import('@wix/ambassador-devcenter-marketplace-v1-web-solution/http')
    ).getWebSolutionsBase;
    return this.httpClient.request(getWebSolutions(payload));
  }

  async getDynamicSections(payload: GetDynamicSectionsRequest) {
    const getDynamicSections = (
      await import(
        '@wix/ambassador-appmarket-v1-app-market-client-aggregator/http'
      )
    ).getDynamicSections;
    return this.httpClient.request(getDynamicSections(payload));
  }

  async queryAppPlans(payload: ListAppPlansByAppIdRequest) {
    const queryAppPlans = (
      await import('@wix/ambassador-appmarket-v1-app-plans/http')
    ).listAppPlansByAppId;
    return this.httpClient.request(queryAppPlans(payload));
  }

  async completePendingInstallation(payload: CompleteInstallationRequest) {
    const completeInstallation = (
      await import(
        '@wix/ambassador-devcenter-pendingapps-v1-pending-installation/http'
      )
    ).completeInstallation;
    return this.httpClient.request(completeInstallation(payload));
  }

  async queryAppReviewsSummary(payload: QueryAppReviewsSummaryRequest) {
    const queryAppReviewsSummary = (
      await import(
        '@wix/ambassador-appreviewssummaries-v1-app-reviews-summary/http'
      )
    ).queryAppReviewsSummary;
    return this.httpClient.request(queryAppReviewsSummary(payload));
  }

  async getUserReview(payload: GetUserReviewRequest) {
    const getUserReview = (
      await import('@wix/ambassador-devcenter-v1-review/http')
    ).getUserReview;
    return this.httpClient.request(getUserReview(payload));
  }

  async queryReviews(payload: QueryReviewsRequest) {
    const queryReviews = (
      await import('@wix/ambassador-devcenter-v1-review/http')
    ).queryReviews;
    return this.httpClient.request(queryReviews(payload));
  }

  async updateReview(payload: UpdateReviewRequest) {
    const updateReview = (
      await import('@wix/ambassador-devcenter-v1-review/http')
    ).updateReview;
    return this.httpClient.request(updateReview(payload));
  }

  async createReview(payload: AppreviewsCreateReviewRequest) {
    const createReview = (
      await import('@wix/ambassador-devcenter-v1-review/http')
    ).appReviewsNamespaceCreateReview;
    return this.httpClient.request(createReview(payload));
  }

  async getTagSections(payload: GetTagSectionsRequest) {
    const getTagSections = (
      await import(
        '@wix/ambassador-appmarket-v1-app-market-client-aggregator/http'
      )
    ).getTagSections;
    return this.httpClient.request(getTagSections(payload));
  }

  async getSiteProperties() {
    const res = await this.httpClient.get<PropertiesReadResponse>(
      '/_api/site-properties-service/properties',
    );
    return res.data;
  }

  async getPricingModel(payload: GetPricingModelRequest) {
    const getPricingModel = (
      await import('@wix/ambassador-devcenter-pricing-v1-pricing-entity/http')
    ).getPricingModel;
    return this.httpClient.request(getPricingModel(payload));
  }

  async getSitesPremiumStatus(payload: GetSitesPremiumStatusRequest) {
    const getSitesPremiumStatus = (
      await import('@wix/ambassador-premium-v1-asset/http')
    ).getSitesPremiumStatus;
    return this.httpClient.request(getSitesPremiumStatus(payload));
  }

  async searchApps(payload: SearchRequest) {
    const searchApps = (
      await import('@wix/ambassador-devcenter-ams-v1-app-market-search/http')
    ).search;
    return this.httpClient.request(searchApps(payload));
  }

  async getAppComponentsByAppIds(payload: GetAppComponentsByAppIdsRequest) {
    const getAppComponentsByAppIds = (
      await import(
        '@wix/ambassador-devcenter-app-components-v1-app-components/http'
      )
    ).getAppComponentsByAppIds;
    return this.httpClient.request(getAppComponentsByAppIds(payload));
  }

  async getAutoCompleteSuggestions(payload: AutocompleteRequest) {
    const autocompleteSuggestions = (
      await import('@wix/ambassador-devcenter-ams-v1-app-market-search/http')
    ).autocomplete;
    return this.httpClient.request(autocompleteSuggestions(payload));
  }

  async getAutoCompleteProxySuggestions(payload: {
    searchTerm: string;
    languageCode: string;
  }): Promise<string[]> {
    const { searchTerm, languageCode } = payload;
    return this.httpClient
      .get<{ autocomplete: string[] }>(
        `/_serverless/app-market-search/autocomplete?term=${searchTerm}&lang=${languageCode}`,
      )
      .then(({ data }) => data.autocomplete ?? []);
  }

  async getFreeTrialEligibility(payload: GetFreeTrialEligibilityRequest) {
    const getFreeTrialEligibility = (
      await import(
        '@wix/ambassador-premium-store-v1-dynamic-offering-service-entity/http'
      )
    ).getFreeTrialEligibility;
    return this.httpClient.request(getFreeTrialEligibility(payload));
  }

  async getEligibleAppsForFreeTrial(
    payload: GetEligibleAppsForFreeTrialRequest,
  ) {
    const getEligibleAppsForFreeTrial = (
      await import('@wix/ambassador-appmarket-v1-app-subscriptions/http')
    ).getEligibleAppsForFreeTrial;
    return this.httpClient.request(getEligibleAppsForFreeTrial(payload));
  }
}
