import type { TagApps } from './tag-apps';
import type { Paging } from '@wix/ambassador-devcenter-appmarket-v1-tag-app/types';
import type { AppDataType } from '../app-market-client-aggregator';
import type { QueryAppsFilter, SupportedAppStatuses } from '../../types';

export const APPS_LIMIT = 48;

export enum SortType {
  COUNTRY_CODE = 'COUNTRY_CODE',
  APP_MARKET_SCORE = 'APP_MARKET_SCORE',
  DEALER = 'DEALER',
}

export type queryTagApp = (payload: {
  appIds?: string[];
  tagIds?: string[] | { $in: string[] };
}) => Promise<TagApps>;

export type getAppsByTag = (payload: {
  tagId: string;
  sortTypes?: SortType[];
  paging?: Paging;
  filter?: QueryAppsFilter;
}) => Promise<TagApps>;

export type getAppsDataByTagId = (payload: {
  tagId: string;
  sortTypes?: SortType[];
  limit?: number;
  offset?: number;
  status: SupportedAppStatuses;
  filter?: QueryAppsFilter;
}) => Promise<{
  apps: AppDataType[];
  hasNext: boolean;
  total: number;
}>;
