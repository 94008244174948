import { BundleApp } from './bundle-app';
import type { BundleWebSolution } from './types';

export class BundleApps {
  constructor(private readonly bundleApps: BundleWebSolution[]) {}

  getApps() {
    return this.bundleApps || [];
  }

  findAppById(appId: string) {
    const bundleApp = this.bundleApps?.find((app) => app.appId === appId);
    return new BundleApp(bundleApp).toJSON();
  }
}
