import React, { useEffect } from 'react';
import { Box, Page } from '@wix/design-system';
import { HomePageHeader } from './home-page-header';
import s from '../pages.scss';
import { SaleBanner } from '../../components/sale-banner';
import { useJunkYard, useEssentials } from '../../contexts';
import { appMarketPageView } from '@wix/bi-logger-app-market-data/v2';
import { getPageViewBiData } from '../../bi-services/events-data-parser';
import { AppMarketPage } from '../../enums';
import { HomePageSections } from './home-page-sections';

export function HomePage() {
  const page = AppMarketPage.HOMEPAGE;
  const { routerData } = useJunkYard();
  const { biLogger, experiments } = useEssentials();

  useEffect(() => {
    const urlParams = location.pathname.split('/')[3];
    biLogger.report(
      appMarketPageView(
        getPageViewBiData({
          routerData,
          urlParams,
          pageType: page,
          pageName: page,
        }),
      ),
    );
  }, []);

  return (
    <Page
      className={`${s.appMarketPage} ${s.appMarketPageBackgroundColor}`}
      dataHook="homepage"
    >
      <Page.Content>
        <HomePageHeader />
        {experiments.enabled('specs.app-market.Sale') ? (
          <Box direction="vertical" marginBottom="30px">
            <SaleBanner layout="narrow" />
          </Box>
        ) : null}
        <HomePageSections />
      </Page.Content>
    </Page>
  );
}
