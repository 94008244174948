import * as React from 'react';
import type { AvatarProps } from '@wix/design-system';
import { Avatar, Box } from '@wix/design-system';
import { composer } from '../../../component-decorators/composer';
import type { InjectedUserDetailsProps } from '../../../component-decorators/with-user-details';
import type { MarketplaceStore } from '../../../component-decorators/with-marketplace-store';

interface IHeaderUserImageProps extends Partial<InjectedUserDetailsProps> {
  marketplaceStore?: MarketplaceStore;
  marginRight: string;
  marginLeft: string;
  size: AvatarProps['size'];
}

export const HeaderUserImage = composer()
  .withMarketplaceStore()
  .compose(
    ({
      marketplaceStore,
      marginRight = '12px',
      marginLeft = '12px',
      size = 'size30',
    }: IHeaderUserImageProps) => {
      return (
        <Box
          marginRight={marginRight}
          marginLeft={marginLeft}
          borderRadius="50%"
        >
          <Avatar
            shape="circle"
            dataHook="profile-image"
            size={size}
            imgProps={{
              src: marketplaceStore.userImage,
              alt: 'profile-image',
            }}
          />
        </Box>
      );
    },
  );
