import {
  SkeletonLine,
  SkeletonRectangle,
  Box,
  SkeletonGroup,
} from '@wix/design-system';
import React from 'react';

export function SubCategoryHeaderSkeleton() {
  return (
    <SkeletonGroup skin="light">
      <Box dataHook="skeleton-header" marginTop="30px">
        <Box marginTop="4px" marginLeft="4px">
          <SkeletonLine width="350px" />
        </Box>
      </Box>
      <Box dataHook="skeleton-title" marginTop="18px">
        <SkeletonRectangle width="300px" height="36px" />
      </Box>
    </SkeletonGroup>
  );
}
