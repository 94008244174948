import React from 'react';
import { Box, InfoIcon, Text } from '@wix/design-system';
import { appMarketAppPageHover } from '@wix/bi-logger-app-market-data/v2';
import { useTranslation } from '../../../../contexts/translations-context';
import { useEssentials, useSiteContext } from '../../../../contexts';
import { AppPropertiesDataHooks } from './data-hooks';

export interface AppLanguagesProps {
  appId: string;
  languages: string[];
  isAppContentTranslatable: boolean;
}

export const AppLanguages = ({
  appId,
  languages,
  isAppContentTranslatable,
}: AppLanguagesProps) => {
  const { languageCode } = useSiteContext();
  const { t } = useTranslation();
  const { biLogger } = useEssentials();

  const translateLanguage = (language: string) =>
    t(`marketplace.language.${language.toUpperCase()}`);

  const nonEnglishLanguages = languages.filter((language) => language !== 'en');
  const translatedLanguages = [
    translateLanguage('en'),
    ...nonEnglishLanguages
      .map(translateLanguage)
      .sort((a, b) => a.localeCompare(b, languageCode)),
  ];

  return (
    <Box direction="vertical">
      <Box gap={1}>
        <Text size="small" weight="bold">
          {t('solution.page.tagsBoxSupportedLangTitle')}
        </Text>
        <InfoIcon
          dataHook={AppPropertiesDataHooks.appLanguagesTooltip}
          tooltipProps={{
            onShow: () => {
              biLogger.report(
                appMarketAppPageHover({
                  app_id: appId,
                  location: 'properties',
                  hover_type: 'app_languages',
                }),
              );
            },
          }}
          size="small"
          content={t('solution.page.tagsBoxSupportedLangTooltip')}
        />
      </Box>

      <Box direction="vertical" gap="6px">
        <Box flexWrap="wrap">
          {translatedLanguages.map((translatedLanguage, index) => {
            return (
              <Box key={translatedLanguage}>
                <Text
                  dataHook={`${AppPropertiesDataHooks.appLanguage}-${index}`}
                  size="small"
                  weight="thin"
                >
                  {translatedLanguage}
                </Text>
                {index < translatedLanguages.length - 1 && (
                  <span style={{ display: 'inline-block' }}>,&nbsp;</span>
                )}
              </Box>
            );
          })}
        </Box>

        {isAppContentTranslatable && (
          <Text
            dataHook={AppPropertiesDataHooks.appContentTranslatableMessage}
            size="small"
            weight="thin"
            secondary
          >
            {t('solution.page.tagsBoxSupportedLangCustomize')}
          </Text>
        )}
      </Box>
    </Box>
  );
};
