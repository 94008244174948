import React, { useState } from 'react';
import { Box, Text } from '@wix/design-system';
import type { Plan, PricingPlansSectionOptions } from './pricing-plans.types';
import type { CurrencySettings, TaxDetails } from '@wix/app-market-services';
import {
  PricingToggle,
  PricingHeader,
  PlanBox,
  getPricingDisclaimerText,
  parsePricePlansData,
  shouldShowPricingDisclaimer,
} from '../../components/pricing-plans';
import s from '../../components/pricing-plans/styles/pricing-plans.module.scss';
import {
  useEssentials,
  withEssentialsContext,
} from '../../contexts/essentials-context';
import type { ExternalComponentProps } from '../types';
import { pricingPlansSectionDataHooks } from './data-hooks';

export interface PricingPlansSectionProps extends ExternalComponentProps {
  externalPricingPageUrl?: string;
  isExternalPricing?: boolean;
  trialDays?: number;
  tax: TaxDetails;
  currencySettings: CurrencySettings;
  companyName: string;
  appName: string;
  isMobile: boolean;
  plans: Plan[];
  onToggleClick?(toggleValue: string): void;
  onLinkClick?(): void;
  options?: PricingPlansSectionOptions;
}

function PricingPlansSectionComponent({
  externalPricingPageUrl,
  isExternalPricing,
  trialDays,
  tax,
  currencySettings,
  companyName,
  appName,
  isMobile,
  plans,
  experiments,
  onToggleClick,
  onLinkClick,
  options = {},
}: PricingPlansSectionProps) {
  const { t } = useEssentials();
  const yearlyTitle = t('solution.page.togglePricing.Yearly');
  const monthlyTitle = t('solution.page.togglePricing.Monthly');

  const [pricingToggle, setPricingToggle] = useState(yearlyTitle);

  const isYearlyPriceAvailable = plans?.some(
    (plan) => !!plan.yearlyPrice?.price,
  );
  const hasMeteredBillingPlan = plans?.some(
    (plan) => !!plan.meterdBilling?.outline,
  );
  const showDisclaimer = shouldShowPricingDisclaimer({ plans });
  const { showExternalPricingLink = true, showNarrowPlansView = false } =
    options;

  const hasActiveSale = plans?.some((plan) => Boolean(plan.discount));

  if (plans.length === 0) {
    return <></>;
  }

  return (
    <Box
      direction="vertical"
      dataHook={pricingPlansSectionDataHooks.sectionContainer}
    >
      <PricingHeader
        externalPricingPageUrl={
          showExternalPricingLink ? externalPricingPageUrl : undefined
        }
        trialDays={trialDays}
        onLinkClick={onLinkClick}
      />
      {isYearlyPriceAvailable && (
        <PricingToggle
          toggleValues={[yearlyTitle, monthlyTitle]}
          selectedValue={pricingToggle}
          onClick={(value) => {
            setPricingToggle(value);
            onToggleClick?.(value);
          }}
        />
      )}
      <div
        className={
          showNarrowPlansView
            ? s.narrowPlansView
            : s[`plansLayout${plans.length}`]
        }
        id="pricing-section-plans-container"
      >
        {plans.map((plan, index: number) => {
          const { priceTitle, priceSubTitle, priceData } = parsePricePlansData({
            plan,
            toggleValue: isYearlyPriceAvailable ? pricingToggle : null,
            yearlyTitle,
            symbol: currencySettings.symbol,
            t,
          });
          return (
            <PlanBox
              description={plan.description}
              planTitle={t('solution.page.planType', { planType: plan.name })}
              priceTitle={priceTitle}
              priceSubTitle={priceSubTitle}
              priceData={priceData}
              isMobile={isMobile}
              index={index}
              key={`plan-box-${index}`}
              activeSale={plan.discount}
              isExternalPricing={Boolean(isExternalPricing)}
            />
          );
        })}
      </div>
      {showDisclaimer && (
        <Box direction="vertical">
          <Text
            size="tiny"
            secondary
            weight="thin"
            skin="standard"
            className={s.pricingPlanCurrency}
            dataHook={pricingPlansSectionDataHooks.pricingPlansDisclaimer}
          >
            {getPricingDisclaimerText({
              tax: tax?.displayTaxType,
              currency: currencySettings.code,
              companyName,
              hasMeteredBillingPlan,
              isExternalPricing: !!isExternalPricing,
              showPriceWithTax: tax?.showPriceWithTax,
              t,
            })}
          </Text>
          {isExternalPricing &&
            hasActiveSale &&
            experiments?.enabled('specs.app-market.Sale') && (
              <Box marginLeft="8px">
                <Text
                  size="tiny"
                  secondary
                  weight="thin"
                  skin="standard"
                  className={s.externalDisclaimerForSale}
                >
                  {t('app.page.external.app.on.sale', {
                    appName,
                    interpolation: { escapeValue: false },
                  })}
                </Text>
              </Box>
            )}
        </Box>
      )}
    </Box>
  );
}

export const PricingPlansSection = withEssentialsContext(
  PricingPlansSectionComponent,
);
